import React, { useEffect, useState, useRef } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";

const CabisenseUpdateFault = (props) => {
    const [loader, setLoader] = useState(false);
    const [Notes, setNotes] = useState("");
    const [NotesError, setNotesError] = useState("");
    const DivRef = useRef(null);

    useEffect(() => {
        if (props.UpdateFaultId && props.CountryID) {
            setLoader(true);

            axios
                .get(
                    "get_cabisense_fault_notes?ClientCountryId=" + props.CountryID + "&TicketId=" + props.UpdateFaultId
                )
                .then((response) => {
                    if (response.data.data.FaultDescription !== null) {
                        setNotes(response.data.data.FaultDescription);
                    }
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }

    }, [props.UpdateFaultId, props.CountryID])

    const HandleSubmit = () => {
        setNotesError("")
        var flag = true;
        if (Notes === "") {
            setNotesError("Notes is required.")
            flag = false;
        }
        if (flag) {
            setLoader(true);

            const payload = {
                'TicketId': props.UpdateFaultId,
                'ClientCountryId': props.CountryID,
                'Description': Notes
            }
            axios
                .post(
                    "update_fault", payload
                )
                .then((response) => {
                    setLoader(false);
                    props.setUpdateFaultId("")
                    props.setClickUpdateFaultId("")
                    toast.success(response.data.status);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setUpdateFaultId("")
            props.setClickUpdateFaultId("")
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                style={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    top: -60,
                    left: 0,
                    display: "flex",
                    background: "rgba(0, 0, 0, 0.5)",
                    zIndex: "999999"
                }}
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "60%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}

                >

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-11 text-center mb-3">
                                <h5>Fault Id : {props.ClickUpdateFaultId}</h5>
                            </div>

                            <div className="col-md-1 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setUpdateFaultId("")
                                        props.setClickUpdateFaultId("")
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-4">
                                <textarea placeholder="Enter notes here..." id="notes" className="form-control" value={Notes} name="w3review" rows="4" cols="50" onChange={(e) => {
                                    setNotes(e.target.value);
                                    setNotesError("")
                                }}>
                                </textarea>
                                <span className="text-danger">{NotesError}</span>
                            </div>
                            <div className='col-md-12 mb-3 text-right'>
                                <button title="Back" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={(e) => {
                                    HandleSubmit();
                                }}>
                                    Update Fault
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CabisenseUpdateFault