import React, { useRef } from 'react'


const ActivatedMessage = (props) => {
    const DivRef= useRef(null);
   
    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setMessage("")
        }
    }

    return (
        <>
            <div
                style={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    top: -60,
                    left: 0,
                    display: "flex",
                    // background: "rgba(0, 0, 0, 0.5)",
                    zIndex: "999999"
                }}
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "25%",
                        height: "fit-content",
                        margin: "auto",
                        // textAlign: "center",
                        boxShadow: "0px 0px 7px 0px #5c5c5c",
                    }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 mb-3" >
                            </div>

                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setMessage("")
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-3 text-center">
                                <div className='row mt-3'>
                                    <p>{props.Message === 1 ?
                                    "This account is activated..."
                                    : "This account is not activated, if you want to activated account go to edit user option..." }</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivatedMessage