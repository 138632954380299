import React from 'react'
import { useSelector } from 'react-redux'

const TimeZone = (props) => {
    const getTimeZoneRedux = useSelector((state) => state.TimeZone.value);

    const changeTimeZone = (getTimeStamp) => {
        const obj = { dateStyle: "medium", timeStyle: "medium" }

        if (localStorage.getItem("TimeZone") !== null) {
            obj['timeZone'] = JSON.parse(localStorage.getItem("TimeZone")).value
                || JSON.parse(getTimeZoneRedux.timezone).value
        }
        return new Date(getTimeStamp * 1000).toLocaleString("en-US", obj)
    }
    // 2022-08-17 09:07:16 AM
    return (
        <>{changeTimeZone(props.timestamp)}</>
    )
}

export default TimeZone