// https://yuzhva.github.io/react-leaflet-markercluster/?path=/docs/react-leaflet-markercluster--basic-example
// https://www.npmjs.com/package/leaflet.markercluster

// https://react-leaflet.js.org/docs/start-installation/

import React, { useMemo, useState, useEffect, useRef } from 'react'
// import New from './New';
import L from "leaflet";
import okMarker from './icons/ok-marker.svg';
import koMarker from './icons/ko-marker.svg';
import noMarker from './icons/no-marker.svg';
import okkoMarker from './icons/okko-marker.svg';
import warnMarker from './icons/warn-marker.svg';
import defaultMarker from './icons/default-marker.svg';

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "./react-leaflet-markercluster";
import OpenTickets from '../OpenTickets';

export const MAP_CENTER_COORDINATES = [51.0, 19.0];
export const MAP_ZOOM = 6;
// export const MAP_ZOOM = 4;
export const MAP_MAX_ZOOM = 17;
// export const MAP_MAX_ZOOM = 19;

const MarkerColor = { 1: okMarker, 3: koMarker, 0: noMarker, 4: okkoMarker, 2: warnMarker }

const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
        className: "marker-cluster-custom",
        iconSize: L.point(40, 40, true),
    });
};

const Map = (props) => {

    const map = useRef();

    const [Stations, setStations] = useState([])
    const [Markers, setMarkers] = useState([]);

    const [BaseStationId, setBaseStationId] = useState("")
    const Identify  = "DashboardMap";


    const StationData = (SitewiseBaseStationData, SelectedBaseStation, SelectedState, SearchBaseStation, BitComSelectedState) => {
        const Temp = []
        const MarkerTemp = [];

        if (SelectedBaseStation !== '' && SearchBaseStation === '') {
            if (SitewiseBaseStationData[SelectedBaseStation].SiteStatusValue !== "-") {
                for (var j = 0; j < SitewiseBaseStationData[SelectedBaseStation].Basestations.length; j++) {
                    const StateValue = BitComSelectedState === "BitState" ? SitewiseBaseStationData[SelectedBaseStation].Basestations[j].StateValue : SitewiseBaseStationData[SelectedBaseStation].Basestations[j].CommStateValue

                    if (SelectedState === '') {
                        Temp.push(SitewiseBaseStationData[SelectedBaseStation].Basestations[j])
                        MarkerTemp.push([SitewiseBaseStationData[SelectedBaseStation].Basestations[j].Latitude, SitewiseBaseStationData[SelectedBaseStation].Basestations[j].Longitude])
                    } else if (SelectedState === StateValue) {
                        Temp.push(SitewiseBaseStationData[SelectedBaseStation].Basestations[j])
                        MarkerTemp.push([SitewiseBaseStationData[SelectedBaseStation].Basestations[j].Latitude, SitewiseBaseStationData[SelectedBaseStation].Basestations[j].Longitude])
                    }
                }
            }
        } else if (SelectedBaseStation === '' && SearchBaseStation === '' && SelectedState !== "") {
            for (var k = 0; k < SitewiseBaseStationData.length; k++) {
                if (SitewiseBaseStationData[k].Basestations.length > 0) {
                    for (var l = 0; l < SitewiseBaseStationData[k].Basestations.length; l++) {
                        const StateValue = BitComSelectedState === "BitState" ? SitewiseBaseStationData[k].Basestations[l].StateValue : SitewiseBaseStationData[k].Basestations[l].CommStateValue
                        if (SelectedState === StateValue) {
                            Temp.push(SitewiseBaseStationData[k].Basestations[l])
                            MarkerTemp.push([SitewiseBaseStationData[k].Basestations[l].Latitude, SitewiseBaseStationData[k].Basestations[l].Longitude])
                        }
                        // arr.push(SitewiseBaseStationData[i].Basestations[l])
                    }
                }
            }
        } else {
            for (var i = 0; i < SitewiseBaseStationData.length; i++) {
                if (SitewiseBaseStationData[i].SiteStatusValue !== "-") {
                    for (j = 0; j < SitewiseBaseStationData[i].Basestations.length; j++) {
                        if (SearchBaseStation === '') {
                            Temp.push(SitewiseBaseStationData[i].Basestations[j])
                            MarkerTemp.push([SitewiseBaseStationData[i].Basestations[j].Latitude, SitewiseBaseStationData[i].Basestations[j].Longitude])
                        }
                        else if ((SitewiseBaseStationData[i].Basestations[j].BaseStationId.toString()).includes(SearchBaseStation) || SitewiseBaseStationData[i].Basestations[j].Name.includes(SearchBaseStation) || SitewiseBaseStationData[i].Basestations[j].SiteHostName.includes(SearchBaseStation)) {
                            Temp.push(SitewiseBaseStationData[i].Basestations[j])
                            MarkerTemp.push([SitewiseBaseStationData[i].Basestations[j].Latitude, SitewiseBaseStationData[i].Basestations[j].Longitude])
                        }
                    }
                }
            }
        }
        setStations(Temp)
        setMarkers(MarkerTemp)
    }

    useMemo(() => StationData(props.SitewiseBaseStationData, props.SelectedBaseStation, props.SelectedState, props.SearchBaseStation, props.BitComSelectedState), [props.SitewiseBaseStationData, props.SelectedBaseStation, props.SelectedState, props.SearchBaseStation, props.BitComSelectedState])

    useEffect(() => {
        if (Markers.length > 0 && map.current) {
            var bounds = new L.LatLngBounds(Markers);
            map.current.fitBounds(bounds)
        }
    }, [Markers])

    return (
        <>
           {BaseStationId !== "" ? <OpenTickets ClientCountryId={props.ClientCountryId} setBaseStationId={setBaseStationId} BaseStationId={BaseStationId} Identify={Identify} /> : null}
            {
                Stations.length > 0 || true ?
                    <MapContainer
                        ref={map}
                        className="markercluster-map"
                        center={[-28.032592, 23.752441]}
                        // center={Stations.length ? [Stations[0].Latitude, Stations[0].Longitude] : [-19.906061, 24.256644]}
                        zoom={MAP_ZOOM}
                        maxZoom={MAP_MAX_ZOOM}
                        style={{ height: "95%", width: "100%" }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />

                        {/* Pass Leaflet.markercluster option directly to MarkerClusterGroup as prop */}
                        <MarkerClusterGroup
                            showCoverageOnHover={true}
                            spiderfyDistanceMultiplier={2}
                            iconCreateFunction={createClusterCustomIcon}
                        >
                            {
                                Stations.map((item, index) => {
                                    var StateValue = props.BitComSelectedState === "BitState"  ? item.StateValue  : item.CommStateValue;
                                    return (
                                        <Marker key={index}
                                            icon={L.icon({
                                                // iconUrl: MarkerColor[item.State] ? MarkerColor[item.State] : defaultMarker,
                                                iconUrl: props.BitComSelectedState === "BitState" ?
                                                    MarkerColor[item.State] ? MarkerColor[item.State] : defaultMarker : MarkerColor[item.CommunicationState] ? MarkerColor[item.CommunicationState] : defaultMarker,
                                                iconSize: [40, 40],
                                                iconAnchor: [20, 40],
                                            })}
                                            position={[item.Latitude, item.Longitude]}
                                        >
                                            <Popup>
                                                <div className="marker-popup">
                                                    <table>
                                                        <tbody>

                                                            <tr>
                                                                <td style={{ minWidth: '100px' }}><b>Basestation Id:</b></td>
                                                                <td>{item.BaseStationId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Site Name:</b></td>
                                                                <td>{item.SiteName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Site Host:</b></td>
                                                                <td className='site_name'>{item.SiteHostName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Bit Status:</b></td>
                                                                <td>{item.StateValue}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Comm Status:</th>
                                                                <td>{item.CommStateValue}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" style={{ textAlign: 'center' }}>
                                                                    <button
                                                                        className="btn btn-primary" title="Show Test" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}
                                                                        onClick={() => props.getBasestationsTestData(item.BaseStationId, item.ClientCountryId, item.Name)}>
                                                                        {/* <i className="bx bx-info-circle" style={{ margin: 0 }}
                                                                        ></i> */}
                                                                        Tests
                                                                    </button>
                                                                    {(props.TicketsModuleAccess && StateValue === "KO") ?  <button
                                                                        className="btn btn-primary m-2" title="Show Tickets" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}
                                                                        onClick={(e) => { setBaseStationId(item.BaseStationId); }}
                                                                    >
                                                                        {/* <i className="bx bx-info-circle" style={{ margin: 0 }}
                                                                        ></i> */}
                                                                       View Tickets
                                                                    </button> : null }
                                                                   
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Popup>
                                        </Marker>
                                    )
                                })
                            }
                            {/* <Marker position={[50.4501, 30.5234]} />*/}
                        </MarkerClusterGroup>
                    </MapContainer> : null
            }
        </>
    )
}

export default Map