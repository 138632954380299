import React, { useRef} from 'react'
import TimeZone from "../../TimeZone";


const MerakiDetailsPopUp = (props) => {
    const DivRef = useRef(null);

    function SecondsToDhms(seconds) {
        seconds = Number(seconds)
        var d = Math.floor(seconds / (3600 * 24))
        var h = Math.floor((seconds % (3600 * 24)) / 3600)
        var m = Math.floor((seconds % 3600) / 60)
        var s = Math.floor(seconds % 60)
        var dDisplay = d > 0 ? d + (parseInt(d) === 1 ? " day, " : " days, ") : ""
        var hDisplay = h > 0 ? h + (parseInt(h) === 1 ? " hour, " : " hours, ") : ""
        var mDisplay = m > 0 ? m + (parseInt(m) === 1 ? " min" : " mins") : ""
        var sDisplay = s > 0 ? s + (parseInt(s) === 1 ? " sec" : " sec") : ""
        if (dDisplay || hDisplay || mDisplay || sDisplay) {
            if (seconds > 60) {
                return dDisplay + hDisplay + mDisplay
            } else {
                return dDisplay + hDisplay + mDisplay + sDisplay
            }
        } else {
            return 0
        }
    }
    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setPage1DetailsFlag("")
        }
    }

    return (
        <>
            <div
                style={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    top: -60,
                    left: 0,
                    display: "flex",
                     background: "rgba(0, 0, 0, 0.5)",
                    zIndex: "999999"
                    // background: 'rgba(0, 0, 0, 0.5)'
                }}
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "70%",
                        height: "60vh",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}

                >
                    <div className="card-header mt-2">
                        <div className='row'>
                            <div className='col-sm-4 '>
                            </div>
                            <div className="col-sm-4 mb-3">
                                <h5 className='m-0'>{props.Page1DetailsFlag + " "}Meraki Ticket</h5>
                            </div>
                            <div className='col-sm-4 text-right'>
                                <button
                                    type="button"
                                    className="btn btn-primary mx-3"
                                    title="Export"
                                    onClick={props.handleExportTicket}
                                    style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}
                                >
                                    Export &nbsp;
                                </button>

                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setPage1DetailsFlag("")
                                    }}
                                >x</button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{ overflow: "auto" }}>
                        <div className="row">

                            <div className="col-md-12 mb-3  horizontal-scroll">
                                <table
                                    className="table mb-0 text-center table-striped ticket-table table-hover"
                                    style={{ verticalAlign: "middle" }}
                                >
                                    <thead className="sticky-top">
                                        <tr>
                                            <th>#</th>
                                            <th style={{ textAlign: "left" }}>Ticket Id</th>
                                            <th style={{ textAlign: "left" }}>Fresh Service Ticket Id</th>
                                            <th>Fault Reference Number</th>
                                            <th style={{ textAlign: "left" }}>BaseStation Id</th>
                                            <th>Lessor Id</th>
                                            <th>Site Name</th>
                                            <th>Maintenance</th>
                                            <th>Incident Code</th>
                                            <th>Ticket Status</th>
                                            <th>Generated Time</th>
                                            <th>Open State</th>
                                            <th>TTD Time</th>
                                            <th>Incident Dispatch State</th>
                                            <th>Deferred State</th>
                                            <th>Ageing (SLA)</th>
                                            <th>Ageing (Actual)</th>
                                            <th>TTR Time </th>
                                            <th>Resolved Time</th>
                                            <th>Resolved State</th>
                                            {/* <th>Closed State</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.Page1DetailsData.data.length > 0 ? props.Page1DetailsData.data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.TicketId}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.FreshServiceTicketId}
                                                    </td>
                                                    <td>{item.FaultId ? item.FaultId : "-"}</td>
                                                    <td style={{ textAlign: "center" }}>{item.BasestationId}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.BasestationLessorId}
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        {item.SiteName}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>{item.SiteMaintenanceName}</td>
                                                    <td style={{ textAlign: "left" }}>
                                                        {item.IncidentCode}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.TicketStatus}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>{(item.CreatedTimestamp > 0) ? <TimeZone timestamp={item.CreatedTimestamp} /> : null}</td>

                                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.OpenDifference)}</td>
                                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.TtdDifference)}</td>
                                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.IncidentDispatchedDifference)}</td>
                                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.TotalDifferedTime)}</td>
                                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.AgingSlaDifference)}</td>

                                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.AgingActualDifference)}</td>

                                                    <td style={{ textAlign: "center" }}>
                                                        {SecondsToDhms(item.TtrDifference)}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>{(item.ResolvedTimestamp > 0) ? <TimeZone timestamp={item.ResolvedTimestamp} /> : null}</td>
                                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.ResolvedDifference)}</td>
                                                    {/* <td> {item.ClosedTimestamp > 0 ? "Yes" : "No"}</td> */}
                                                </tr>
                                            );
                                        }) :
                                            <tr>

                                                <td colSpan={20}>
                                                    No record found
                                                </td>
                                            </tr>
                                        }

                                        <tr className="custom-tfooter">
                                            <td style={{ textAlign: "left", fontWeight: "bold" }} colSpan={11}>Total Average</td>

                                            <td style={{ textAlign: "center", fontWeight: "bold" }} >{SecondsToDhms(props.Page1DetailsData.TotalOpenDiff)}</td>

                                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.Page1DetailsData.TotalTtdDiff)}</td>

                                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.Page1DetailsData.TotalIncidentDispatchedDiff)}</td>

                                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.Page1DetailsData.TotalDiferredDiff)}</td>

                                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.Page1DetailsData.TotalAgingSlaDiff)}</td>

                                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.Page1DetailsData.TotalAgingActualDiff)}</td>

                                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.Page1DetailsData.TotalTtrDiff)}</td>
                                            <td></td>

                                            <td style={{ textAlign: "center", fontWeight: "bold" }}> {SecondsToDhms(props.Page1DetailsData.TotalResolvedDiff)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-md-12 mb-3'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MerakiDetailsPopUp