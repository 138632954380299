import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import Alert from "../../Components/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import ReactCountryFlag from "react-country-flag"
import DefaultLogo from "../../Images/foxilogic-disable.png"
import ClientApiKeyPopUp from "./ClientApiKeyPopUp";

const ShowPassword = (props) => {
  console.log('props::: ', props);
  const [Show, setShow] = useState(false);
  return (
    <>
      {/* {Show ? props.password : props.password.replace(/./g, "*")} */}
      {Show ? props.password : String(props.password).replace(/./g, "*")}

      <i
        className={Show ? "bx bx-hide" : 'bx bx-show'}
        title={Show ? "Hide Password" : 'Show Password'}
        onClick={() => setShow(!Show)}
        style={{
          float: 'right',
          marginRight: '25px',
          cursor: 'pointer'
        }}
      ></i>
    </>
  );
};

const ClientList = () => {
  const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  const AllCountry = countryList().getData();

  const navigate = useNavigate();
  const location = useLocation();
  const [ModuleData, setModuleData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [ServiceCall, setServiceCall] = useState(true);
  const [DeleteUserId, setDeleteUserId] = useState("");
  const [success, setSuccess] = useState("");
  const [ClientID, setClientID] = useState("");
  const [ClientName, setClientName] = useState("");
  //----------
  const [SmtpClientID, setSmtpClientID] = useState("");
  const [SmtpClientName, setSmtpClientName] = useState("");
  const [SMTPUsername, setSMTPUsername] = useState("");
  const [SMTPPassword, setSMTPPassword] = useState("");
  const [SMTPPort, setSMTPPort] = useState("");
  const [SMTPHost, setSMTPHost] = useState("");

  const [MuduleCountryData, setMuduleCountryData] = useState([]);
  const [CountryName, setCountryName] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [SigfoxUsername, setSigfoxUsername] = useState("");
  const [SigfoxPassword, setSigfoxPassword] = useState("");
  const [CountryNameError, setCountryNameError] = useState("");
  const [SigfoxUsernameError, setSigfoxUsernameError] = useState("");
  const [SigfoxPasswordError, setSigfoxPasswordError] = useState("");
  //-------
  const [SMTPUsernameError, setSMTPUsernameError] = useState("");
  const [SMTPPasswordError, setSMTPPasswordError] = useState("");
  const [SMTPPortError, setSMTPPortError] = useState("");
  const [SMTPHostError, setSMTPHostError] = useState("");
  const [search, setSearch] = useState("")
  const [ClientData, setClientData] = useState({});

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      navigate("/clients", {
        state: null,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (ServiceCall) {
      setLoader(true);
      axios
        .get("clients?Search=" + search)
        .then((response) => {
          setModuleData(response.data.data);
          setServiceCall(false);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [ServiceCall, search]);

  const AlertResponse = (response) => {
    if (response === true) {
      setLoader(true);
      axios
        .delete("client/" + DeleteUserId)
        .then((response) => {
          setServiceCall(true);
          setDeleteUserId("");
          setSuccess("Client deleted successfully");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setDeleteUserId("");
    }
  };

  useEffect(() => {
    if (ClientID) {
      setLoader(true);
      axios
        .get("client_countries?ClientId=" + ClientID)
        .then((response) => {
          setMuduleCountryData(response.data.data);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [ClientID]);


  useEffect(() => {
    if (SmtpClientID) {
      setSMTPUsername("")
      setSMTPPassword("")
      setSMTPPort("")
      setSMTPHost("")
      axios
        .get("client/" + SmtpClientID)
        .then((response) => {
          if (response.data.data['SMTPUsername'] !== null) {
            setSMTPUsername(response.data.data['SMTPUsername'])
          }
          if (response.data.data['SMTPPassword'] !== null) {
            setSMTPPassword(response.data.data['SMTPPassword'])
          }
          if (response.data.data['SMTPPort'] !== null) {
            setSMTPPort(response.data.data['SMTPPort'])
          }
          if (response.data.data['SMTPHost'] !== null) {
            setSMTPHost(response.data.data['SMTPHost'])
          }

        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [SmtpClientID]);

  const handleValidation = () => {
    setCountryNameError("");
    setSigfoxUsernameError("");
    setSigfoxPasswordError("");
    var flag = true;
    if (CountryName === "") {
      setCountryNameError("Country name is required");
      flag = false;
    }

    if (SigfoxUsername === "") {
      setSigfoxUsernameError("Sigfox username is required");
      flag = false;
    }
    if (SigfoxPassword === "") {
      setSigfoxPasswordError("Sigfox password is required");
      flag = false;
    }
    return flag;
  };

  const formSubmit = (e) => {
    const flag = handleValidation();
    e.preventDefault();
    if (flag) {
      setLoader(true);
      axios
        .post("add_client_country", {
          ClientId: ClientID,
          CountryName: CountryName,
          CountryCode: CountryCode,
          SigfoxUsername: SigfoxUsername,
          SigfoxPassword: SigfoxPassword,
        })
        .then((response) => {
          setMuduleCountryData(response.data.data);
          setSuccess(response.data.status.message);
          setCountryName("");
          setSigfoxUsername("");
          setSigfoxPassword("");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSubmit = () => {
    setServiceCall(true)
  }

  const handleClearSearch = () => {
    setSearch("")
    setServiceCall(true)
  }

  const smtpHandleValidation = () => {
    setSMTPUsernameError("");
    setSMTPPasswordError("");
    setSMTPPortError("");
    setSMTPHostError("");

    var flag = true;
    if (SMTPUsername === "") {
      setSMTPUsernameError("SMTP Username is required");
      flag = false;
    }
    if (SMTPPassword === "") {
      setSMTPPasswordError("SMTP Password is required");
      flag = false;
    }
    if (SMTPPort === "") {
      setSMTPPortError("SMTP Port is required");
      flag = false;
    }
    if (SMTPHost === "") {
      setSMTPHostError("SMTP Host is required");
      flag = false;
    }
    return flag;
  };

  const smtpFormSubmit = (e) => {
    const flag = smtpHandleValidation();
    e.preventDefault();
    if (flag) {
      setLoader(true);
      axios
        .put("configure_client_smtp/" + SmtpClientID, {
          SMTPUsername: SMTPUsername,
          SMTPPassword: SMTPPassword,
          SMTPPort: SMTPPort,
          SMTPHost: SMTPHost,
        })
        .then((response) => {
          setSuccess(response.data.status.message);
          setSmtpClientID("");
          setSmtpClientName("");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const countryOnChange = (e) => {
    setCountryName(e.target.value);
    for (var i = 0; i < AllCountry.length; i++) {
      if (AllCountry[i].label === e.target.value) {
        setCountryCode(AllCountry[i].value);
      }
    }
  }

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      {
        JSON.stringify(ClientData) !== "{}" ? <ClientApiKeyPopUp ClientData={ClientData} setClientData={setClientData} setLoader={setLoader} setServiceCall={setServiceCall} /> : null
      }
      {DeleteUserId ? (
        <Alert
          message="Are you sure, you want to delete this client?"
          AlertResponse={AlertResponse}
        />
      ) : (
        ""
      )}
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0">Client List</h6>
        </div>
        {
          UserGroup.Id === 1 || UserGroup.Id === 2 ? <div className="col-md-4 text-right">
            <Link to="/add_clients" className="btn btn-primary">
              <i className="bx bx-plus me-1 font-22"></i> Add Client
            </Link>
          </div> : null
        }
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control mb-3"
            id="search"
            name="search"
            placeholder="Search Here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <button
            type="submit"
            className="btn btn-primary mb-3"
            title="Search"
            onClick={handleSubmit}
          >
            <i className="bx bx-search search-icon"></i>
          </button>
          &nbsp;
          {search ?
            <button
              type="button"
              className="btn btn-primary mb-3"
              title="Clear"
              onClick={handleClearSearch}
            >
              <i className="bx bx-eraser search-icon"></i>
            </button>
            : null}
        </div>
      </div>

      {/* Add Client countries */}
      {ClientID ? (
        <div className="col-xl-10 mx-auto">
          <div className="card">
            <div className="card-body horizontal-scroll">
              <div className="card-title">
                <div className="row">
                  <div className="col-md-8">
                    <h5 className="mb-0 text-uppercase text-primary">
                      <i className="bx bx-user-circle me-1 font-22"></i>
                      {ClientName}
                    </h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setClientID("");
                        setClientName("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <form onSubmit={formSubmit}>
                <div className="row">
                  <div className="col-md-3">
                    <select
                      className="form-select"
                      onChange={(e) => { countryOnChange(e) }}
                      value={CountryName}
                    >
                      <option value="">Select Country</option>
                      {AllCountry.map((item) => {
                        return (
                          <option key={item.label} value={item.label} name={item.value} >
                            <ReactCountryFlag
                              className="add_country_flag mx-5"
                              countryCode={item.value}
                              aria-label={item.label}
                            />   &nbsp;&nbsp;{item.label}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">{CountryNameError}</span>
                  </div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      value={SigfoxUsername}
                      onChange={(e) => setSigfoxUsername(e.target.value)}
                      placeholder="Sigfox username"
                    />
                    <span className="text-danger">{SigfoxUsernameError}</span>
                  </div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="password"
                      value={SigfoxPassword}
                      onChange={(e) => setSigfoxPassword(e.target.value)}
                      placeholder="Sigfox password"
                    />
                    <span className="text-danger">{SigfoxPasswordError}</span>
                  </div>
                  <div className="col-md-3">
                    <button type="submit" className="btn btn-primary px-5">
                      Save
                    </button>
                  </div>
                </div>
              </form>
              <div style={{ maxHeight: '62vh' }} className=" horizontal-scroll">
                <table className="table mb-0 table-striped ticket-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Country</th>
                      <th>Username</th>
                      <th>Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {MuduleCountryData.map((item, index) => {
                      return (
                        <tr key={item.Id}>
                          <td>{index + 1}</td>
                          <td>
                            {item.CountryCode ? <ReactCountryFlag
                              className="country_flag_in_listing"
                              countryCode={item.CountryCode}
                              aria-label={item.label}
                            />
                              :
                              <i
                                className="bx bx-flag"
                                title="Manage country"
                                style={{ fontSize: "20px", cursor: "pointer", marginRight: "10px" }}
                              ></i>
                            }
                            {item.CountryName}</td>
                          <td style={{ verticalAlign: "middle" }}>{item.SigfoxUsername}</td>
                          <td style={{ verticalAlign: "middle" }}><ShowPassword password={item.SigfoxPassword} /></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (null)}

      {/* Add SMTP Configure */}

      {SmtpClientID ? (
        <div className="col-xl-10 mx-auto">
          <div className="card">
            <div className="card-body horizontal-scroll">
              <div className="card-title">
                <div className="row">
                  <div className="col-md-8">
                    <h5 className="mb-0 text-uppercase text-primary">
                      <i className="bx bx-user-circle me-1 font-22"></i>
                      SMTP Configure ({SmtpClientName})
                    </h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setSmtpClientID("");
                        setSmtpClientName("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <form onSubmit={smtpFormSubmit}>
                <div className="row">
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      value={SMTPUsername}
                      onChange={(e) => setSMTPUsername(e.target.value)}
                      placeholder="SMTP Username"
                    />
                    <span className="text-danger">{SMTPUsernameError}</span>
                  </div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      value={SMTPPassword}
                      onChange={(e) => setSMTPPassword(e.target.value)}
                      placeholder="SMTP Password"
                    />
                    <span className="text-danger">{SMTPPasswordError}</span>
                  </div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      value={SMTPPort}
                      onChange={(e) => setSMTPPort(e.target.value)}
                      placeholder="SMTP Port"
                    />
                    <span className="text-danger">{SMTPPortError}</span>
                  </div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      value={SMTPHost}
                      onChange={(e) => setSMTPHost(e.target.value)}
                      placeholder="SMTP Host"
                    />
                    <span className="text-danger">{SMTPHostError}</span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-3">
                    <button type="submit" className="btn btn-primary px-5">
                      Save
                    </button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      ) : (null)}

      {ClientID || SmtpClientID ? (null) : (
        <div className="col-md-12">
          <div className="card">
            <div className="card-body ">
              <div style={{ maxHeight: '72vh' }} className=" horizontal-scroll">
                <table className="table mb-0 table-striped ticket-table ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ width: "100px" }}>Logo</th>
                      <th>Client name</th>
                      <th>Client Code</th>
                      <th>Client countries</th>
                      <th>Client Api Key</th>
                      <th>SMTP Configure</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ModuleData.length > 0 ?
                      ModuleData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                src={item.ClientLogo ? item.ClientLogo : DefaultLogo}
                                className="user-img"
                                alt="Client Logo"
                              />
                            </td>
                            <td style={{ verticalAlign: "middle" }}>{item.Name}</td>
                            <td style={{ verticalAlign: "middle" }}>{item.ClientCode ? item.ClientCode : "-"}</td>
                            <td>
                              <i
                                onClick={() => {
                                  setClientID(item.Id);
                                  setClientName(item.Name);
                                }}
                                className="bx bx-flag"
                                title="Manage country"
                                style={{ fontSize: "20px", cursor: "pointer" }}
                              ></i>
                            </td>
                            <td>
                              <i
                                onClick={() => {
                                  setClientData(item)
                                }}
                                className="bx bx-show"
                                title="Client Api Key"
                                style={{ fontSize: "20px", cursor: "pointer", color: "blue" }}
                              ></i>
                            </td>

                            <td><button className="btn btn-primary px-2" onClick={() => { setSmtpClientID(item.Id); setSmtpClientName(item.Name); }}>View</button></td>

                            <td className="action-buttons">
                              <i
                                className="bx bx-trash text-danger"
                                title="Delete"
                                onClick={() => { setDeleteUserId(item.Id); window.scrollTo(0, 0) }}
                              ></i>
                              <Link to={"/add_clients/" + item.Id}>
                                <i className="bx bx-pencil" title="Edit"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                      :
                      <tr>
                        <td
                          colSpan={9}
                          style={{ textAlign: "center" }}>
                          <b>No Record Found</b>
                        </td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientList;
