import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";

const AddDynamicSLA = () => {
    const navigate = useNavigate();
    const { dynamicID } = useParams();

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [NameError, setNameError] = useState("")
    const [DynamicSLADetails, setDynamicSLADetails] = useState({
        Name: "",
        Active: 0
    })

    const { Name, Active } = DynamicSLADetails;

    const handleChange = (e) => {
        setDynamicSLADetails({ ...DynamicSLADetails, [e.target.name]: e.target.value })
    }

    const handleActive = (value) => {
        const ActiveValue = value === 0 ? 1 : 0;
        setDynamicSLADetails({ ...DynamicSLADetails, "Active": ActiveValue })
    }

    useEffect(() => {
        if (dynamicID) {
            setLoader(true)
            axios.get("get_master_dynamic_sla/" + dynamicID)
                .then((response) => {
                    setDynamicSLADetails({
                        Name: response.data.data.Name,
                        Active: response.data.data.Active,
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }, [dynamicID])

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);

    const validate = () => {
        var flag = true;
        setNameError("");

        if (Name === "") {
            flag = false;
            setNameError("Name field is required")
        }
        return flag;
    }

    const submitDynamic = (e) => {
        e.preventDefault();
        const flag = validate();
        if (flag) {
            if (!dynamicID) {
                setLoader(true)
                axios.post("add_master_dynamic_sla", DynamicSLADetails)
                    .then((response) => {
                        navigate("/manage_dynamic_sla", {
                            state: response.data.status.message
                        })
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                    })
            } else {
                setLoader(true)
                axios.put("update_master_dynamic_sla/" + dynamicID, DynamicSLADetails)
                    .then((response) => {
                        navigate("/manage_dynamic_sla", {
                            state: response.data.status.message
                        })
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                    })
            }
        }
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-xl-10 mx-auto">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="card-title">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="mb-0  text-primary">
                                            <i className="bx bxs-user me-1 font-22 text-primary"></i>
                                            {dynamicID ? "Edit Dynamic SLA" : "Add Dynamic SLA"}
                                        </h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={submitDynamic}>
                                <div className="col-md-6">
                                    <label className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="Name"
                                        onChange={handleChange}
                                        value={Name}
                                    />
                                    <span className="text-danger">{NameError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label">Active</label>
                                    <br />
                                    <Switch
                                        name="Active"
                                        className="login-switch"
                                        onColor={"#136dfa"}
                                        height={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onChange={(e) => handleActive(Active)}
                                        checked={Active === 0 ? false : true}
                                    />
                                    <span className="text-danger"></span>
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary px-5">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddDynamicSLA