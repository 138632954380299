import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddEdit = () => {
    const navigate = useNavigate();
    const { Id } = useParams();
    const [ChannelPartnerList, setChannelPartnerList] = useState([])
    const [ChannelPartner, setChannelPartner] = useState("")
    const [ChannelPartnerError, setChannelPartnerError] = useState("")
    const [NoOfDevices, setNoOfDevices] = useState("")
    const [NoOfDevicesError, setNoOfDevicesError] = useState("")
    const [EmailTxt, setEmailTxt] = useState([]);
    const [Email, setEmail] = useState([]);
    const [EmailError, setEmailError] = useState("");

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        axios
            .get("masters")
            .then((response) => {
                setChannelPartnerList(response.data.data.ChannelPartners);
                // setSeverityAll(response.data.data.Severities);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    }, [])

    const HandleEmail = () => {
        if (EmailTxt === "") {
            setEmailError("Please enter email")
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(EmailTxt)) {
                setEmailError("Please enter valid email address")
            } else {
                var TempEmail = [...Email];
                TempEmail.push(EmailTxt);
                setEmail(TempEmail);
                setEmailTxt("");
            }
        }

    }

    const HandleRemoveEmail = (index) => {
        var TempEmail = [...Email];
        TempEmail.splice(index, 1);
        setEmail(TempEmail);
    }

    useEffect(() => {
        if (Id) {
            setLoader(true)
            axios.get("get_impacted_device_config?Id=" + Id)
                .then((response) => {
                    setEmail(response.data.data[0].Email)
                    setChannelPartner(response.data.data[0].ChannelPartnerId)
                    setNoOfDevices(response.data.data[0].NoOfImpactedDevice)

                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }, [Id])

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);


    const validate = () => {
        var flag = true;
        setChannelPartnerError("");
        setNoOfDevicesError("");
        setEmailError("");

        if (ChannelPartner === "") {
            flag = false;
            setChannelPartnerError("Please select channel partner")
        }

        if (NoOfDevices === "") {
            setNoOfDevicesError("Number of impacted devices field is required");
            flag = false;
        } else if (parseInt(NoOfDevices) === 0) {
            setNoOfDevicesError("Please enter greater value than 0");
            flag = false;
        }

        if (Email.length === 0) {
            setEmailError("Email field is required");
            flag = false;
        }

        return flag;
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        const flag = validate();
        if (flag) {
            const Payload = {
                "ChannelPartnerId": ChannelPartner,
                "NoOfImpactedDevice": NoOfDevices,
                "Email": Email.toString()
            }

            if (!Id) {
                setLoader(true)
                axios.post("add_impacted_device_config", Payload)
                    .then((response) => {
                        navigate("/impacted_devices_notification_config", {
                            state: response.data.status.message
                        })
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                    })
            } else {
                setLoader(true)
                axios.put("update_impacted_device_config/" + Id, Payload)
                    .then((response) => {
                        navigate("/impacted_devices_notification_config", {
                            state: response.data.status.message
                        })
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                    })
            }
        }
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-xl-10 mx-auto">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="card-title">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="mb-0  text-primary">
                                            <i className="bx bxs-user me-1 font-22 text-primary"></i>
                                            {Id ? "Edit Impacted Devices Notification" : "Add Impacted Devices Notification"}
                                        </h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={HandleSubmit}>
                                <div className="col-md-6">
                                    <label className="form-label">Channel Partner</label>

                                    <select
                                        className="form-select"
                                        onChange={(e) => {
                                            setChannelPartner(e.target.value)
                                        }}
                                        value={ChannelPartner}
                                    >
                                        <option value="">Select Channel Partner</option>
                                        {
                                            ChannelPartnerList.map((item, index) => (
                                                <option key={index} value={item.Id}> {item.Name} </option>
                                            ))
                                        }
                                    </select>

                                    <span className="text-danger">{ChannelPartnerError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label">No. of Impacted Devices</label>
                                    <br />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="Name"
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                            setNoOfDevices(value)
                                        }}
                                        value={NoOfDevices}
                                    />
                                    <span className="text-danger">{NoOfDevicesError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label ">Emails</label>
                                    <div className="row">
                                        <div className="col-sm-11">
                                            <input
                                                type="text"
                                                className="form-control mb-2 col-6"
                                                name="threshold"
                                                placeholder="Please Enter Emails"
                                                value={EmailTxt}
                                                onChange={(e) => {
                                                    setEmailTxt(e.target.value)
                                                    setEmailError("")
                                                }}
                                            />
                                        </div>

                                        <div className="col-sm-1" onClick={() => {
                                            HandleEmail();

                                        }}>
                                            <i className=" bx bx-plus cursor-pointer" style={{
                                                fontSize: "25px",
                                                color: "blue",
                                            }}></i>
                                        </div>
                                    </div>

                                    <span className="text-danger">{EmailError}</span>
                                    <div>
                                        <span> Added Emails : </span>

                                        {
                                            Email.length > 0 ?
                                                Email.map((item, i) => {
                                                    return (

                                                        <span style={{ marginRight: "12px" }} key={i}>{item}<i
                                                            className="bx bx-x-circle text-danger mx-1"
                                                            title="Remove Email" style={{ color: "red", fontSize: "15px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                HandleRemoveEmail(i);
                                                            }}
                                                        ></i>
                                                            {(i !== (Email.length - 1)) ? "," : ""}
                                                        </span>
                                                    );
                                                })
                                                : null
                                        }
                                    </div>

                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary px-5">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEdit