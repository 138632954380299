import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";


const AddClientSeverity = () => {
  const { id } = useParams();

  var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  var ClientId = JSON.parse(localStorage.getItem("ClientId"));

  const [Client, setClient] = useState(ClientId !== 0 ? ClientId : "");
  const [Clients, setClients] = useState([]);
  const [loader, setLoader] = useState(false);
  const [SeverityAll, setSeverityAll] = useState([]);
  const [WaitingPeriod, setWaitingPeriod] = useState("");
  const [Severity, setSeverity] = useState("");

  const [ClientError, setClientError] = useState("");
  const [WaitingPeriodError, setWaitingPeriodError] = useState("");
  const [SeverityError, setSeverityError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (UserGroup.Id === 1 || UserGroup.Id === 2) {
      setLoader(true);
      axios
        .get("clients")
        .then((response) => {
          setClients(response.data.data);
          // setClient(response.data.data.Id);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [UserGroup.Id]);

  useEffect(() => {
    setLoader(true);
    axios
      .get("masters")
      .then((response) => {
        setSeverityAll(response.data.data.Severities);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const handleValidation = () => {
    setClientError("");
    setWaitingPeriodError("");
    setSeverityError("");
    var flag = true;
    if (Client === "") {
      setClientError("Client is required");
      flag = false;
    }

    if (WaitingPeriod === "") {
      setWaitingPeriodError("Waiting period is required");
      flag = false;
    }

    if (Severity === "") {
      setSeverityError("Severity is required");
      flag = false;
    }
    return flag;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    const flag = handleValidation();

    if (flag) {
      const payload = {
        ClientId: Client,
        SeverityId: Severity,
        WaitingPeriod: WaitingPeriod,
      };

      if (id) {
        setLoader(true);
        axios
          .put("client_severity/" + id, payload)
          .then((response) => {
            navigate("/client_severity", { 
              state: {
                response:response.data.status.message,
                data:Client
              },
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      } else {
        setLoader(true);
        axios
          // .post("client_severity", payload)
          .put("client_severity", payload)
          .then((response) => {
            navigate("/client_severity", {
              state: response.data.status.message,
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setLoader(true);
      axios
        .get("client_severity/" + id)
        .then((response) => {
          setClient(response.data.data.ClientId);
          setWaitingPeriod(response.data.data.WaitingPeriod);
          setSeverity(response.data.data.SeverityId);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [id]);

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-xl-10 mx-auto">
          <div className="card">
            <div className="card-body p-5">
              <div className="card-title">
                <div className="row">
                  <div className="col-md-8">
                    <h5 className="mb-0  text-primary">
                      {/* <i className="bx bxs-user me-1 font-22 text-primary"></i> */}
                      <i className="bx bx-time me-1 font-22"></i>
                      {id ?"Edit Severity":"Add Severity"} 
                    </h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        navigate("/client_severity", { 
                          state: {
                            response:"",
                            data:Client
                          },
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <form className="row g-3" onSubmit={formSubmit}>
                {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                  <>
                    <div className="col-md-6">
                      <label className="form-label">Client</label>
                      <select
                      disabled={ Client? true : false}
                        className="form-select"
                        onChange={(e) => setClient(e.target.value)}
                        value={Client}
                      >
                        <option value="">Select Client</option>
                        {Clients.map((item) => {
                          return (
                            <option key={item.Id} value={item.Id}>
                              {item.Name}
                            </option>
                          );
                        })}
                      </select>
                      <span className="text-danger">{ClientError}</span>
                    </div>
                    <div className="col-md-6"></div>
                  </>
                ) : null}

                <div className="col-md-6">
                  <label className="form-label">Severity</label>
                  <select
                    disabled={id ? true : false}
                    className="form-select"
                    onChange={(e) => setSeverity(e.target.value)}
                    value={Severity}
                  >
                    <option value="">Select Severity</option>
                    {SeverityAll.map((item) => {
                      return (
                        <option key={item.Id} value={item.Id}>
                          {item.Severity}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger">{SeverityError}</span>
                </div>

                <div className="col-md-6">
                  <label className="form-label">Waiting Period</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setWaitingPeriod(e.target.value);
                      }
                    }}
                    value={WaitingPeriod}
                  />
                  <span className="text-danger">{WaitingPeriodError}</span>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary px-5">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClientSeverity;
