import React, { useState } from "react";
import { Link } from "react-router-dom";

const ModuleComponent = (props) => {
  const [ShowChild, setShowChild] = useState(false);

  const LevelWiseClassName = {0:"grandparent", 1:"parent", 2:"child"}

  return (
    <>
      <Link to={"/" + props.item.ModuleLink} className={props.item.ActiveClassName ? LevelWiseClassName[props.item.Level] + " active" :LevelWiseClassName[props.item.Level]} style={{ marginLeft: props.item.Level === 0 ? "0px" : "" }}>
      <div className="main">

        <div className="parent-icon">
          <i className={props.item.ModuleClass}></i>
        </div>
        <div className={"menu-title" }>{props.item.ModuleName}</div>
        </div>
        {props.item.SubModules !== undefined && props.item.SubModules.length > 0 ? 
        <div className={props.MiniBarStyle.width === "70px" ?"parent-icon d-none": "parent-icon" } style={{ float: "right" }}>
          <i
            className={
              ShowChild ? "bx bx-minus" : "bx bx-plus"
            }
            onClick={(e) => {
              e.preventDefault();
              setShowChild(!ShowChild)
            }}
          ></i>
        </div> : null}
        

      </Link>
      {ShowChild ?

        props.item.SubModules.map((item) => {
          return (
            <ModuleComponent
              key={item.ModuleID}
              item={item}
              Modules={props.item.SubModules}
              MiniBarStyle={props.MiniBarStyle}
            />
          );
        })
        : null}
    </>
  );
};

export default ModuleComponent;
