import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSunburst from "highcharts/modules/sunburst";
import CustomEvents from "highcharts-custom-events";
import { TICKET_STATUS_OPEN, TICKET_STATUS_INCIDENT_DISPATCHED, TICKET_STATUS_DEFERRED, TICKET_STATUS_RESOLVED, TICKET_STATUS_CLOSED } from "../../Custom";

HighchartsSunburst(Highcharts);
CustomEvents(Highcharts);

const MerakiTicketCharts = (props) => {
    const refChart = useRef();

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: '',
        },
        subtitle: {
            text:
                '',
        },
        xAxis: {
            categories: [''],
            crosshair: true,

        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            valueSuffix: ''
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true
                }
            }
        },

        series: [
            {
                name: 'Total',
                data: [props.item.TotalMerakiTickets],
                color: '#3c3cff'
            },
            {
                name: TICKET_STATUS_RESOLVED,
                data: [props.item.ResolvedMerakiTickets],
                color: '#df8c29'
            },
            {
                name: TICKET_STATUS_OPEN,
                data: [props.item.OpenMerakiTickets],
                color: 'red'
            }, {
                name: TICKET_STATUS_CLOSED,
                data: [props.item.ClosedMerakiTickets],
                color: '#00b500'
            }, {
                name: TICKET_STATUS_INCIDENT_DISPATCHED,
                data: [props.item.IncidentDispatchedMerakiTickets],
                color: '#b7b700'
            },
            {
                name: TICKET_STATUS_DEFERRED,
                data: [props.item.DeferredMerakiTickets],
                color: '#b037a0'
            }
            // ,
            // {
            //     name: 'Power Confirmation',
            //     data: [props.item.PowerConfirmationMerakiTickets],
            //     color: '#37b0a8'
            // }
        ],
        credits: {
            enabled: false
        },
        legend: {
            enabled: true,
            symbolRadius: 0,
        },
        exporting: {
            enabled: false,
            buttons: {
                contextButton: {
                    className: "highcharts-contextbutton",
                    enabled: true,
                    symbol: 'download',
                    symbolStroke: "#ffffff",
                    theme: {
                        fill: '#1c2427',
                    },
                    titleKey: ""
                }
            }
        }
    }

    return (
        <div>
            <HighchartsReact ref={refChart} highcharts={Highcharts} options={options} />
        </div>
    );
}

export default MerakiTicketCharts