import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "../axios";
import BGImage from "../Images/login3.jpg";
import Logo from "../Images/foxilogic.png";
import { ToastContainer, toast } from "react-toastify";

// https://www.npmjs.com/package/react-switch

const EmailVarification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [Email, setEmail] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [Username, setUsername] = useState("");
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (location.state) {
            if (location.state.Username) {
                setUsername(location.state.Username);
            }
        }
    }, [location])

    useEffect(() => {
        if (Username !== "") {
            axios.get("get_email?Username=" + Username)
                .then((response) => {
                    setEmail(response.data.data[0].Email)
                })
                .catch((error) => {
                })
        }

    }, [Username])

    const handleSubmit = (e) => {
        e.preventDefault();
        setEmailError("");
        var flag = true;

        if (Email === "") {
            setEmailError("Please enter email")
            flag = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(Email)) {
                setEmailError("Please enter valid email address")
                flag = false;
            }
        }

        if (flag) {
            setLoader(true);
            axios
                .post("send_email_varification", {
                    Email: Email,
                })
                .then((response) => {
                    toast.success(response.data.status.message);
                    setLoader(false);

                    navigate("/opt_varification", {
                        state: {
                            Email: Email,
                            Identity:"SignupOtp"
                        }
                    });
                    
                })
                .catch((response) => {
                    setLoader(false);

                });
        }
    }

    return (
        <>
            <ToastContainer theme="colored" />
            <div
                className="bg-lock-screen"
                style={{ height: "100vh", backgroundImage: "url(" + BGImage + ")" }}
            >
                <div className="wrapper">
                    <div className="authentication-lock-screen d-flex align-items-center justify-content-center">
                        <div
                            className="card"
                            style={{
                                backgroundColor: "rgb(0,0,0,0.6)",
                                borderRadius: "1.25rem",
                            }}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className="card-body p-md-5 text-center">
                                    <div className="">
                                        <img src={Logo} className="" width="120" alt="" />
                                    </div>
                                    <h5 className="my-2 text-white">
                                        Email
                                    </h5>
                                    <div className="mb-3 mt-3">
                                        <p className="text-white m-0" style={{ textAlign: "center" }}>
                                            {/* Otp */}
                                        </p>
                                        <input
                                            style={{
                                                backgroundColor: "transparent",
                                                color: "#ffffff",
                                                borderRadius: "1.25rem",
                                            }}
                                            type="text"
                                            className="form-control"
                                            name="otp"
                                            value={Email}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                        />
                                        <p className="text-danger mt-2">{EmailError}</p>
                                    </div>
                                    <div className="mb-3 mt-3">

                                        <div className="d-grid">
                                            {!loader ? (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    style={{ borderRadius: "1.25rem" }}
                                                >
                                                    Send Email
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    style={{ borderRadius: "1.25rem" }}
                                                >
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    Send Email
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailVarification;
