import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";

const AddEdit = () => {

    const { id } = useParams();

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const [IncidentCodeList, setIncidentCodeList] = useState([]);
    const [IncidentCode, setIncidentCode] = useState("");
    const [IncidentCodeError, setIncidentCodeError] = useState("");

    const [NoOfBasestation, setNoOfBasestation] = useState("");
    const [NoOfBasestationError, setNoOfBasestationError] = useState("");

    const [Time, setTime] = useState("");
    const [TimeError, setTimeError] = useState("");

    const [EmailTxt, setEmailTxt] = useState([]);

    const [Email, setEmail] = useState([]);
    const [EmailError, setEmailError] = useState("");
    const [ClientID, setClientID] = useState("");
    const BsDownConfigSelectedClientId = localStorage.getItem("BsDownConfigSelectedClientId") ? localStorage.getItem("BsDownConfigSelectedClientId") : "";


    useEffect(() => {
        if (BsDownConfigSelectedClientId) {
            setClientID(BsDownConfigSelectedClientId);
        }
    }, [BsDownConfigSelectedClientId]);


    useEffect(() => {
        if (ClientID) {
            setLoader(true);
            axios.get("masters?ClientCountryId=&ClientId=" + ClientID)
                .then((response) => {
                    setIncidentCodeList(response.data.data.TestTypes);
                    setLoader(false);

                })
                .catch((error) => {
                    setLoader(false);

                })

        }

    }, [ClientID])


    const handleValidation = () => {
        setIncidentCodeError("");
        setNoOfBasestationError("")
        setTimeError("")
        setEmailError("")
        var flag = true;

        if (IncidentCode === "") {
            setIncidentCodeError("Incident code field is required");
            flag = false;
        }
        if (NoOfBasestation === "") {
            setNoOfBasestationError("Threshold(number of basestation) field is required");
            flag = false;
        } else if (parseInt(NoOfBasestation) === 0) {
            setNoOfBasestationError("Please enter greater value than 0");
            flag = false;
        }

        if (Time === "") {
            setTimeError("Time field is required");
            flag = false;
        } else if (parseInt(Time) === 0) {
            setTimeError("Please enter greater value than 0");
            flag = false;
        }

        if (Email.length === 0) {
            setEmailError("Email field is required");
            flag = false;
        }

        return flag;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag && ClientID) {
            const payload = {
                "TestTypeId": IncidentCode,
                "NoOfBasestation": NoOfBasestation,
                "Time": Time,
                "Email": Email.toString(),
                "ClientId": ClientID
            };

            if (!id) {
                axios
                    .post("add_basestation_down", payload)
                    .then((response) => {
                        navigate("/basestation_down_config", {
                            state: {
                                message: response.data.status.message,
                                clientid: ClientID
                            }
                        });
                        setLoader(false);
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            } else {
                axios
                    .put("update_basestation_down/" + id, payload)
                    .then((response) => {

                        navigate("/basestation_down_config", {
                            state: {
                                message: response.data.status.message,
                                clientid: ClientID
                            }
                        });

                        setLoader(false);
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            }
        }
    };

    useEffect(() => {
        if (id && IncidentCodeList.length > 0 && ClientID) {
            setLoader(true);
            axios
                .get("get_basestation_down?Id=" + id + "&ClientId=" + ClientID)
                .then((response) => {

                    setIncidentCode(response.data.data[0].TestTypeId);
                    setNoOfBasestation(response.data.data[0].NoOfBasestation)
                    setEmail(response.data.data[0].Email)
                    setTime(response.data.data[0].Time)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [id, IncidentCodeList, ClientID])

    const HandleEmail = () => {
        if (EmailTxt === "") {
            setEmailError("Please enter email")
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(EmailTxt)) {
                setEmailError("Please enter valid email address")
            } else {
                var TempEmail = [...Email];
                TempEmail.push(EmailTxt);
                setEmail(TempEmail);
                setEmailTxt("");
            }
        }

    }

    const HandleRemoveEmail = (index) => {
        var TempEmail = [...Email];
        TempEmail.splice(index, 1);
        setEmail(TempEmail);
    }

    return (
        <>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-xl-10 mx-auto">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="card-title">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="mb-0  text-primary">
                                            {id ? "Edit Base Station Down Configuration" : "Add Base Station Down Configuration"}

                                        </h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                navigate("/basestation_down_config", {
                                                    state: {
                                                        message: "",
                                                        clientid: ClientID
                                                    }
                                                });
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={onSubmit}>
                                <div className="col-md-6">
                                    <label className="form-label">Incident Code</label>
                                    <select
                                        className="form-select"
                                        onChange={(e) => {
                                            setIncidentCode(e.target.value)
                                        }}
                                        value={IncidentCode}
                                    >
                                        <option value="">Select Incident Code</option>
                                        {
                                            IncidentCodeList.map((item, index) => (
                                                <option key={index} value={item.Id}> {item.IncidentCode} </option>
                                            ))
                                        }
                                    </select>

                                    <span className="text-danger">{IncidentCodeError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label">Threshold (No. Of Base Station)</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        name="threshold"
                                        placeholder="Please Enter Threshold (No. Of Base Station)"
                                        value={NoOfBasestation}

                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                            setNoOfBasestation(value)
                                        }}
                                    />
                                    <span className="text-danger">{NoOfBasestationError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label">Time (Minutes)</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        name="threshold"
                                        placeholder="Please Enter Time"
                                        value={Time}

                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                            setTime(value)
                                        }}
                                    />
                                    <span className="text-danger">{TimeError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label ">Emails</label>
                                    <div className="row">
                                        <div className="col-sm-11">
                                            <input
                                                type="text"
                                                className="form-control mb-2 col-6"
                                                name="threshold"
                                                placeholder="Please Enter Emails"
                                                value={EmailTxt}
                                                onChange={(e) => {
                                                    setEmailTxt(e.target.value)
                                                    setEmailError("")
                                                }}
                                            />
                                        </div>

                                        <div className="col-sm-1" onClick={() => {
                                            HandleEmail();

                                        }}>
                                            <i className=" bx bx-plus cursor-pointer" style={{
                                                fontSize: "25px",
                                                color: "blue",
                                            }}></i>
                                        </div>
                                    </div>

                                    <span className="text-danger">{EmailError}</span>
                                    <div>
                                        <span> Added Emails : </span>

                                        {
                                            Email.length > 0 ?
                                                Email.map((item, i) => {
                                                    return (

                                                        <span style={{ marginRight: "12px" }}>{item}<i
                                                            className="bx bx-x-circle text-danger mx-1"
                                                            title="Remove Email" style={{ color: "red", fontSize: "15px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                HandleRemoveEmail(i);
                                                            }}
                                                        ></i>
                                                            {(i !== (Email.length - 1)) ? "," : ""}
                                                        </span>
                                                    );
                                                })
                                                : null
                                        }
                                    </div>

                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary px-5">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddEdit;
