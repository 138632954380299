import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div className="custom-select-option">
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                &nbsp;
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


const AddCategories = () => {
    const navigate = useNavigate();
    const { category_id } = useParams();

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");

    const [Category, setCategory] = useState("")
    const [CategoryError, setCategoryError] = useState("")

    const [IncidentCode, setIncidentCode] = useState([])

    const [IncidentCodeList, setIncidentCodeList] = useState([])

    const [IncidentCodeError, setIncidentCodeError] = useState("")


    useEffect(() => {
        var url = + category_id ? "get_incident_code?Id=" + category_id : "get_incident_code";
        axios.get(url)
            .then((response) => {
                var Temp = response.data.data;
                var Arr = [];
                for (var i = 0; i < Temp.length; i++) {
                    var Obj = Temp[i];
                    Obj['value'] = Temp[i].Id;
                    Obj['label'] = Temp[i].IncidentCode;
                    Arr.push(Obj);
                }
                setIncidentCodeList(Arr);

            })
            .catch((error) => {
            })
    }, [category_id])

    useEffect(() => {
        if (category_id) {
            setLoader(true)
            axios.get("get_category/" + category_id)
                .then((response) => {
                    setCategory(response.data.data[0].CategoryName)
                    var IncidentCodeIds = response.data.data[0].IncidentCodeIds;
                    var Arr = []
                    for (var i = 0; i < IncidentCodeIds.length; i++) {
                        for (var j = 0; j < IncidentCodeList.length; j++) {
                            if (IncidentCodeIds[i] === IncidentCodeList[j].Id) {
                                var Obj = IncidentCodeList[j];
                                Arr.push(Obj);
                            }
                        }
                    }

                    setIncidentCode(Arr)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }, [category_id, IncidentCodeList])

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);

    const validate = () => {
        var flag = true;
        setCategoryError("");
        setIncidentCodeError("");

        if (Category === "") {
            flag = false;
            setCategoryError("Category field is required")
        }
        if (IncidentCode.length === 0) {
            flag = false;
            setIncidentCodeError("Please select incident code")
        }
        return flag;
    }

    const submitDynamic = (e) => {
        e.preventDefault();
        const flag = validate();
        var Arr = []
        for (var i = 0; i < IncidentCode.length; i++) {
            Arr.push(IncidentCode[i].Id)
        }

        if (flag) {
            if (!category_id) {

                setLoader(true)
                axios.post("add_categories", {
                    "CategoryName": Category,
                    "IncidentCodeIds": Arr
                })
                    .then((response) => {
                        navigate("/manage_incident_code_categories", {
                            state: response.data.status.message
                        })
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                    })

            } else {
                setLoader(true)
                axios.put("update_categories/" + category_id, {
                    "CategoryName": Category,
                    "IncidentCodeIds": Arr
                })
                    .then((response) => {
                        navigate("/manage_incident_code_categories", {
                            state: response.data.status.message
                        })
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                    })
            }
        }
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-xl-10 mx-auto">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="card-title">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="mb-0  text-primary">
                                            <i className="bx bxs-user me-1 font-22 text-primary"></i>
                                            {category_id ? "Edit Category" : "Add Category"}
                                        </h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={submitDynamic}>
                                <div className="col-md-6">
                                    <label className="form-label">Category</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="Name"
                                        onChange={(e) => {
                                            setCategory(e.target.value)
                                        }}
                                        autoComplete="off"
                                        value={Category}
                                    />
                                    <span className="text-danger">{CategoryError}</span>
                                </div>
                                <div className="col-md-6" style={{ marginTop: "44px" }}>
                                    <ReactSelect
                                        options={IncidentCodeList}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        placeholder='Select Incident Code'
                                        // defaultMenuIsOpen
                                        components={{
                                            Option
                                        }}
                                        onChange={(selected) => {
                                            setIncidentCode(selected);
                                        }}
                                        allowSelectAll={true}
                                        value={IncidentCode ? IncidentCode : null}
                                    />
                                    <span className="text-danger">{IncidentCodeError}</span>
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary px-5">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCategories