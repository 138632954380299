import React, { useRef} from 'react'
// import TimeZone from '../../TimeZone'
import { useSelector } from 'react-redux'
// import { Link } from "react-router-dom";

const EmailBodyView = (props) => {
    //console.warn(props);
    const getTimeZoneRedux = useSelector((state) => state.TimeZone.value);
    const DivRef = useRef(null);

    const convert_time_to_timeStamp = (ebody) => {
        //console.warn(ebody);
        const i = ebody.indexOf("##")
        if (i > -1) {
            var timestamp = ebody.slice(i, i + 12)
            var timestampcopy = timestamp
            timestamp = timestamp.replace('##', '')
            timestamp = changeTimeZone(timestamp)
            ebody = ebody.replace(timestampcopy, timestamp)
        }
        return ebody.indexOf("##") > -1 ? convert_time_to_timeStamp(ebody) : ebody
    }

    const changeTimeZone = (getTimeStamp) => {
        const obj = { dateStyle: "medium", timeStyle: "medium" }

        if (localStorage.getItem("TimeZone") !== null) {
            obj['timeZone'] = JSON.parse(localStorage.getItem("TimeZone")).value
                || JSON.parse(getTimeZoneRedux.timezone).value
        }
        return new Date(getTimeStamp * 1000).toLocaleString("en-US", obj)
    }

    const HandleClose = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setTicketSelected("")
        }
    }


    return (
        <div
            style={{
                position: "absolute",
                height: "100vh",
                width: "100%",
                top: -60,
                left: 0,
                display: "flex",
                zIndex: 999999,
                background: 'rgba(0, 0, 0, 0.5)'
            }}
            onClick={(e) => {
                HandleClose(e)
            }}
        >
            <div
                className="card"
                style={{
                    maxWidth: "50%",
                    height: "fit-content",
                    margin: "auto",
                    textAlign: "center",
                    boxShadow: "0px 0px 5px 0px #5c5c5c",
                }}
                ref={DivRef}
            >
                <div className="card-body horizontal-scroll">
                    <div className="row">
                        <div className="col-md-6 text-left"></div>
                        <div className="col-md-6 text-right">
                            <button className="btn btn-secondary" onClick={() => props.setTicketSelected("")}>x</button>
                        </div>

                        <div className="col-md-12 text-left">
                            <p style={{ overflowY: 'scroll', maxHeight: '70vh', marginTop: '5px' }} dangerouslySetInnerHTML={{ __html: convert_time_to_timeStamp(props.TicketSelected.EmailBody) }}></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailBodyView