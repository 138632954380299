import React, { useEffect, useState, useRef } from 'react'
import TimeZone from '../TimeZone'
import { useNavigate } from 'react-router-dom';
import axios from "../axios";
import Loader from "../Components/Loader";
import { OPENGROUPTICKETSTATUSARR } from "../Custom";

const OpenTickets = (props) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [OpenTicketData, setOpenTicketData] = useState([])
    const DivRef = useRef(null);

    useEffect(() => {
        if (props.ClientCountryId) {
            setOpenTicketData([])
            var Url = "";
            if (props.SiteId !== "" && props.Identify === "Site") {
                Url = "&SiteId=" + props.SiteId;
            } else if (props.BaseStationId !== "" && props.Identify === "DashboardMap") {
                Url = "&BasestationId=" + props.BaseStationId;
            } else if (props.SiteName !== "" && props.Identify === "OutageMngtSite") {
                Url = "&SiteName=" + props.SiteName;
            } else {
                Url = "";
            }

            setLoader(true);
            axios
                .get("get_tickets?ClientCountryId=" + props.ClientCountryId + "&Status=" + OPENGROUPTICKETSTATUSARR.toString() + Url)
                .then((response) => {
                    setOpenTicketData(response.data.data.Tickets)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }

    }, [props.SiteId, props.BaseStationId, props.SiteName, props.ClientCountryId, props.Identify])

    const HandleClose = () => {
        if (props.Identify === "Site") {
            props.setSiteId("");
            props.setSiteName("")
        }
        if (props.Identify === "OutageMngtSite") {
            props.setSiteName("")
        } else {
            props.setBaseStationId("")
        }
    }

    const ClosePopup = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            HandleClose()
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}

            <div
                style={{
                    position: "absolute",
                    zIndex: 999999,
                    height: "100vh",
                    width: "100%",
                    top: -60,
                    left: 0,
                    display: "flex",
                    background: 'rgba(0, 0, 0, 0.5)'
                }}
                onClick={(e) => {
                    ClosePopup(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "90%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                        maxHeight: '85vh'
                    }}
                    ref={DivRef}
                >
                    <div className="card-body horizontal-scroll mt-4">
                        <i className="bx bx-x" title="Close" style={{ position: 'absolute', right: '20px', top: '5px', cursor: 'pointer', fontSize: '23px', fontWeight: 'bold' }} onClick={(e) => {
                            HandleClose
                                ()
                        }}></i>

                        <button title="Site Tickets" className="btn btn-primary text-right" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '7px 20px', position: 'absolute', right: '12px', top: '45px', cursor: 'pointer' }}
                            onClick={(e) => {
                                navigate(`/tickets-list/${props.ClientCountryId}`, {
                                    state: props.Identify === "Site" ? { "siteId": props.SiteId } : props.Identify === "OutageMngtSite" ? { "OutageMngtSite": props.SiteName } :
                                        { "BaseStationId": props.BaseStationId }

                                })
                            }}
                        >
                            Tickets
                        </button>
                        <div className="row  ">
                            <div className="col-md-12 text-right mt-1">
                                <div className='row'>
                                    <div className='col-md-7 text-right'>
                                        <h6>{props.Identify === "Site" ? "Site : " + props.SiteName : props.Identify === "OutageMngtSite" ? "Site : " + props.SiteName : "BaseStation : " + props.BaseStationId}</h6>
                                    </div>
                                    <div className='col-md-5 text-right'>

                                    </div>
                                </div>

                            </div>

                            <div className="col-md-12 text-center mt-5" style={{ overflow: "auto", height: "640px" }}>

                                <table className="table mb-0 table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70px" }}>#</th>

                                            <th>Ticket Category</th>
                                            <th>Dynamic SLA</th>
                                            <th>Ticket Id</th>
                                            <th>Fresh Service Ticket Id</th>
                                            <th>Basestation Id</th>
                                            <th>Site Lessor Id</th>
                                            <th>Incident Code</th>
                                            <th>Ticket Status</th>
                                            <th>Site Name</th>
                                            <th>Site Status</th>
                                            <th>Site Host</th>
                                            <th>Occurred Time</th>
                                            <th>Generated Time</th>
                                            <th>Resolved Time</th>
                                            <th>View Tickete</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            OpenTicketData.length !== 0 ? OpenTicketData.map((item, i) => {
                                                return (
                                                    <tr key={i} className={(item.Status === 'KO' ? 'ko-bg' : '')}>
                                                        <td> {i + 1}</td>
                                                        <td>{item.TicketCategory}</td>
                                                        <td>{item.DynamicSla}</td>
                                                        <td>{item.TicketId}</td>
                                                        <td>{item.FreshServiceTicketId}</td>
                                                        <td>{item.BasestationId}</td>
                                                        <td>{item.BasestationLessorId}</td>
                                                        <td>{item.IncidentCode}</td>
                                                        <td>{item.TicketStatus}</td>
                                                        <td>{item.SiteName}</td>
                                                        <td>{item.SiteStatus}</td>
                                                        <td>{item.SiteHost}</td>
                                                        <td>{(item.TicketOccuredTime > 0) ? <TimeZone timestamp={item.TicketOccuredTime} /> : null}</td>
                                                        <td>{(item.CreatedTimestamp > 0) ? <TimeZone timestamp={item.CreatedTimestamp} /> : null}</td>
                                                        <td>{(item.ResolvedTimestamp > 0) ? <TimeZone timestamp={item.ResolvedTimestamp} /> : null}</td>
                                                        <td>
                                                            <button title="Search Ticket" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={(e) => { navigate(`/tickets-list/${props.ClientCountryId}`, { state: { "tickete_id": item.TicketId } }) }}>
                                                                {/* <i className="bx bx-info-circle" style={{ margin: 0 }}></i> */}
                                                                View
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <>
                                                <tr>
                                                    <td colSpan="16">No Data</td>
                                                </tr>
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OpenTickets