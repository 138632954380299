import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddEdit = () => {
    const navigate = useNavigate();
    const { GroupID } = useParams();

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");

    const [ChannelPartnerList, setChannelPartnerList] = useState([])
    const [ChannelPartner, setChannelPartner] = useState("")
    const [ChannelPartnerError, setChannelPartnerError] = useState("")

    const [GroupName, setGroupName] = useState("")
    const [GroupNameError, setGroupNameError] = useState("")

    useEffect(() => {
        setLoader(true)
        axios.get("masters")
            .then((response) => {
                setChannelPartnerList(response.data.data.ChannelPartners)
                // setDynamicSLADetails({
                //     Name: response.data.data.Name,
                //     Active: response.data.data.Active,
                // })
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
            })
    }, [])

    useEffect(() => {
        if (GroupID) {
            setLoader(true)
            axios.get("get_channel_partner_group?Id=" + GroupID)
                .then((response) => {
                    setGroupName(response.data.data[0].GroupName)
                    setChannelPartner(response.data.data[0].ChannelPartnerId)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }, [GroupID])

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);

    const validate = () => {
        var flag = true;
        setGroupNameError("");
        setChannelPartnerError("");

        if (GroupName === "") {
            flag = false;
            setGroupNameError("Group name field is required")
        }

        if (ChannelPartner === "") {
            flag = false;
            setChannelPartnerError("Please select channel partner ")
        }

        return flag;
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        const flag = validate();
        if (flag) {
            const Payload = {
                "GroupName": GroupName,
                "ChannelPartnerId": ChannelPartner
            }
            if (!GroupID) {

                setLoader(true)
                axios.post("add_channel_partner_group", Payload)
                    .then((response) => {
                        navigate("/channel_partner_group_config", {
                            state: response.data.status.message
                        })
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                    })
            } else {
                setLoader(true)
                axios.put("update_channel_partner_group/" + GroupID, Payload)
                    .then((response) => {
                        navigate("/channel_partner_group_config", {
                            state: response.data.status.message
                        })
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                    })
            }
        }
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-xl-10 mx-auto">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="card-title">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="mb-0  text-primary">
                                            <i className="bx bxs-user me-1 font-22 text-primary"></i>
                                            {GroupID ? "Edit Channel Partner Group" : "Add Channel Partner Group"}
                                        </h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={HandleSubmit}>
                                <div className="col-md-6">
                                    <label className="form-label">Channel Partner</label>
                                    <select
                                        className="form-select"
                                        onChange={(e) => {
                                            setChannelPartner(e.target.value)
                                            // setSearched(true)
                                        }}
                                        value={ChannelPartner}
                                    >
                                        <option value="">Select Channel Partner</option>
                                        {
                                            ChannelPartnerList.map((item, index) => (
                                                <option key={index} value={item.Id}> {item.Name} </option>
                                            ))
                                        }
                                    </select>

                                    <span className="text-danger">{ChannelPartnerError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label">Group Name</label>
                                    <br />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="Name"
                                        onChange={(e) => {
                                            setGroupName(e.target.value)
                                        }}
                                        value={GroupName}
                                    />
                                    <span className="text-danger">{GroupNameError}</span>
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary px-5">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEdit