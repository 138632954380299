import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { token } from "../features/TokenSlice";
import { useDispatch } from "react-redux";
import axios from "../axios";
// import UserIcon from '../Images/user.png'
import BGImage from "../Images/login3.jpg";
import { Link } from "react-router-dom";
import Logo from "../Images/foxilogic.png";
import { ToastContainer, toast } from "react-toastify";

// https://www.npmjs.com/package/react-switch
import Switch from "react-switch";

const Login = () => {
  var UserName = localStorage.getItem("Username");
  var UserPassword = localStorage.getItem("Password");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState({
    Username: UserName ? UserName : "",
    Password: UserPassword ? UserPassword : "",
  });
  const [usernameError, setUsernameError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [RememberMe, setRememberMe] = useState(false);
  const [LoginLoader, setLoginLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      navigate("/login", {
        state: null,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);


  const handleValidation = () => {
    setUsernameError("");
    setPasswordError("");
    var flag = true;
    if (users.Username === "") {
      setUsernameError("Please enter username");
      flag = false;
    }
    if (users.Password === "") {
      setPasswordError("Please enter password");
      flag = false;
    }

    return flag;
  };

  const recursiveModules = (Module) => {
    if (Module['SubModules'] && Module['SubModules'].length > 0) {
        return recursiveModules( Module['SubModules'][0]);
    } else {
        return Module["ModuleLink"];
    }
}

  const handleSubmit = (e) => {
    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      setLoginLoader(true);
      axios
        .post("login", {
          Username: users.Username,
          Password: users.Password,
        })
        .then((response) => {
          localStorage.setItem(
            "Token",
            JSON.stringify({ token: response.data.data.Token })
          );

          localStorage.setItem("Name", response.data.data.Name);
          localStorage.setItem("Id", response.data.data.Id);
          localStorage.setItem("RoleId", response.data.data.RoleId);
          localStorage.setItem(
            "UserGroup",
            JSON.stringify(response.data.data.UserGroup)
          );
          localStorage.setItem("ClientId", response.data.data.ClientId);
          localStorage.setItem("ClientCountryId", response.data.data.ClientCountryId);

          if (typeof response.data.data.ProfileImage !== "undefined") {
            localStorage.setItem("ProfileImage", response.data.data.ProfileImage);
          } else {
            localStorage.setItem("ProfileImage", "");
          }

          if (RememberMe === true) {
            localStorage.setItem("Username", users.Username);
            localStorage.setItem("Password", users.Password);
          }

          localStorage.setItem("ChannelPartnerId", JSON.stringify(response.data.data.ChannelPartnerId));
          localStorage.setItem("ChannelPartnerName", JSON.stringify(response.data.data.ChannelPartnerName));
          dispatch(token({ token: response.data.data.Token }));

          // This loop for only superadmin can access dashoboard 2
          var TempModules = response.data.data.Modules
          var DashboardAccess = false;
          var ModulesArr = [];

          for (var j = 0; j < TempModules.length; j++) {
            var ModulesObj = {};
            if (TempModules[j].ModuleLink === "home2") {
              if (parseInt(response.data.data.UserGroup.Id) === 1) {
                ModulesObj = TempModules[j];
                ModulesArr.push(ModulesObj);
              }
            } else {
              ModulesObj = TempModules[j];
              ModulesArr.push(ModulesObj);
            }

            if (TempModules[j].ModuleLink === "home") {
              DashboardAccess = true;
            }
          }

          // Restrict Parent modules which have no childs
          var TempModulesArr = []
          for (let index = 0; index < ModulesArr.length; index++) {
            if(!(parseInt(ModulesArr[index].ParentIdentity) ===1 && ModulesArr[index].SubModules && ModulesArr[index].SubModules.length === 0) ){
              TempModulesArr.push(ModulesArr[index])
            }
          }

          localStorage.setItem("Modules", JSON.stringify(TempModulesArr));
          const GetFirstModuleLink = recursiveModules(TempModules[0]);
          localStorage.setItem("FirstModuleLink", GetFirstModuleLink);

          // Navigation Part
          if (TempModulesArr.length === 0) {
            navigate(
              '/',
              {
                state: "no_access"
              }
            )
          }

          if (response.data.data.UserGroup.Id === 5 || response.data.data.UserGroup.Id === 6) {
            navigate(`/tickets-list/${response.data.data.ClientCountryId}`);
          } else if (response.data.data.UserGroup.Id === 7) {
            navigate(`/outage_management`);
          } else if (DashboardAccess) {
            navigate("/home");
          } else {
            navigate("/" + GetFirstModuleLink);
          }
          setLoginLoader(false);
        })
        .catch((error) => {
          if (parseInt(error.response.status) === 403) {
            navigate("/email_varification", {
              state: {
                Username: users.Username
              }
            })
          }
          setLoginLoader(false);
        });
    }
  };

  return (
    <>
      <ToastContainer theme="colored" />
      <div
        className="bg-lock-screen"
        style={{ height: "100vh", backgroundImage: "url(" + BGImage + ")" }}
      >
        <div className="wrapper">
          <div className="authentication-lock-screen d-flex align-items-center justify-content-center">
            <div
              className="card"
              style={{
                backgroundColor: "rgb(0,0,0,0.6)",
                borderRadius: "1.25rem",
              }}
            >
              <div className="card-body p-md-5 text-center">
                <div className="">
                  <img src={Logo} className="" width="120" alt="" />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 mt-3">
                    <p className="text-white m-0" style={{ textAlign: "left" }}>
                      Username
                    </p>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        color: "#ffffff",
                        borderRadius: "1.25rem",
                      }}
                      type="text"
                      className="form-control"
                      name="username"
                      onChange={(e) =>
                        setUsers({ ...users, Username: e.target.value })
                      }
                      value={users.Username}
                    />
                    <p className="text-danger">{usernameError}</p>
                  </div>

                  <div className="mb-3 mt-3">
                    <p className="text-white m-0" style={{ textAlign: "left" }}>
                      Password
                    </p>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        color: "#ffffff",
                        borderRadius: "1.25rem",
                      }}
                      className="form-control"
                      type="password"
                      name="password"
                      onChange={(e) =>
                        setUsers({ ...users, Password: e.target.value })
                      }
                      value={users.Password}
                    />
                    <p className="text-danger">{passwordError}</p>
                  </div>
                  <div className="mb-3 mt-3">
                    <Switch
                      className="login-switch"
                      onColor={"#136dfa"}
                      height={25}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onChange={() => setRememberMe(!RememberMe)}
                      checked={RememberMe}
                    />
                    <p
                      className="text-white m-0"
                      style={{ textAlign: "left", paddingLeft: " 70px" }}
                    >
                      Remember me
                    </p>
                  </div>
                  <div className="d-grid">
                    {!LoginLoader ? (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ borderRadius: "1.25rem" }}
                      >
                        Login
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ borderRadius: "1.25rem" }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Login
                      </button>
                    )}
                  </div>
                  <p
                    style={{
                      marginTop: "12px",
                      color: "#ffffff",
                      border: "1px solid #000",
                    }}
                    className="mt-2 mb-0"
                  >
                    Forgot your password? <Link to="/forget_password">Reset here</Link>

                  </p>
                  <p
                    style={{
                      marginTop: "1px",
                      color: "#ffffff",
                      border: "1px solid #000",
                    }}
                  >
                    Don’t have an account?
                    <Link to="/sign_up">Sign up</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
