import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";


const AddSeverity = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [Severity, setSeverity] = useState("");
  const [WaitingPeriod, setWaitingPeriod] = useState("");
  const [SeverityError, setSeverityError] = useState("");
  const [WaitingPeriodError, setWaitingPeriodError] = useState("");

  const handleValidation = () => {
    setWaitingPeriodError("");
    setSeverityError("");
    var flag = true;

    if (Severity === "") {
      setSeverityError("Severity is required");
      flag = false;
    }
    var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    if (!pattern.test(WaitingPeriod)) {
      setWaitingPeriodError("Please enter number only");
      flag = false;
    }
    if (WaitingPeriod === "") {
      setWaitingPeriodError("Waiting period is required");
      flag = false;
    }
   
    return flag;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      const payload = {
        Severity: Severity,
        DefaultWaitingPeriod: WaitingPeriod,
      };
      if (id) {
        setLoader(true);
        axios
          .put("severity/" + id, payload)
          .then((response) => {
            navigate("/manage_severity", {
              state: response.data.status.message,
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      } else {
        axios
          .post("severity", payload)
          .then((response) => {
            navigate("/manage_severity", {
              state: response.data.status.message,
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      }
    }
  };
  useEffect(() => {
    if (id) {
      setLoader(true);
      axios
        .get("severity/" + id)
        .then((response) => {
          setWaitingPeriod(response.data.data.DefaultWaitingPeriod);
          setSeverity(response.data.data.Severity);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [id]);

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-xl-10 mx-auto">
          <div className="card">
            <div className="card-body p-5">
              <div className="card-title">
                <div className="row">
                  <div className="col-md-8">
                    <h5 className="mb-0  text-primary">
                      {/* <i className="bx bxs-user me-1 font-22 text-primary"></i> */}
                      <i className="bx bx-time me-1 font-22"></i>
                      {id ? "Edit Severity" : "Add Severity"}
                    </h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <form className="row g-3" onSubmit={onSubmit}>
                <div className="col-md-6">
                  <label className="form-label">Severity</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setSeverity(e.target.value)}
                    value={Severity}
                  />
                  <span className="text-danger">{SeverityError}</span>
                </div>

                <div className="col-md-6">
                  <label className="form-label">Waiting Period</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setWaitingPeriod(e.target.value);
                    }}
                    value={WaitingPeriod}
                  />
                  <span className="text-danger">{WaitingPeriodError}</span>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary px-5">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSeverity;
