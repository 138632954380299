import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div className="custom-select-option">
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                &nbsp;
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const AddMultiBit = () => {

    const { id } = useParams();

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [Name, setName] = useState("");
    const [NameError, setNameError] = useState("");

    const [TestType, setTestType] = useState([]);
    const [TestTypeError, setTestTypeError] = useState("");

    const [TestTypeList, setTestTypeList] = useState([]);

    useEffect(() => {
        setLoader(true);
        axios
            .get("get_all_test_types")
            .then((response) => {
                var TempData = response.data.data;
                var arr = [];
                for (var i = 0; i < TempData.length; i++) {
                    var obj = {}
                    obj["label"] = TempData[i].IncidentCode;
                    obj["value"] = TempData[i].TestType;
                    arr.push(obj)
                }
                setTestTypeList(arr)

                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });

    }, [])


    const handleValidation = () => {
        setNameError("");
        setTestTypeError("")
        var flag = true;

        if (Name === "") {
            setNameError("Name is required");
            flag = false;
        }
        if (TestType.length === 0) {
            setTestTypeError("Please select any one option")
            flag = false;
        }

        return flag;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {

            var Str = "";
            for (var i = 0; i < TestType.length; i++) {
                Str += TestType[i].value
                if (i !== TestType.length - 1) {
                    Str += ",";
                }
            }

            const payload = {
                IncidentCode: Name,
                TestType: Str
            };

            if (!id) {
                axios
                    .post("add_multi_bit_config", payload)
                    .then((response) => {
                        navigate("/multi_bit_incident_code_config", {
                            state: response.data.status.message
                        });
                        setLoader(false);
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            } else {
                axios
                    .put("update_multi_bit_config/" + id, payload)
                    .then((response) => {
                        navigate("/multi_bit_incident_code_config", {
                            state: response.data.status.message
                        });
                        setLoader(false);
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            }
        }
    };

    useEffect(() => {
        if (id && TestTypeList.length > 0) {
            setLoader(true);
            axios
                .get("get_multi_bit_config/" + id)
                .then((response) => {
                    setName(response.data.data[0].IncidentCode);
                    var Arr = response.data.data[0].MultiBitTestTypes.split(",");
                    // response.data.data[0].MultiBitTestTypes
                    var NewArr = []
                    for (var i = 0; i < Arr.length; i++) {
                        for (var j = 0; j < TestTypeList.length; j++) {
                            if (Arr[i] === TestTypeList[j].value) {
                                var Obj = TestTypeList[j];
                                NewArr.push(Obj);
                            }
                        }
                    }
                    setTestType(NewArr)

                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [id, TestTypeList])

    return (
        <>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-xl-10 mx-auto">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="card-title">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="mb-0  text-primary">
                                            {id ? "Edit Multi Bit Incident Code" : "Add Multi Bit Incident Code"}

                                        </h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={onSubmit}>
                                <div className="col-md-6">
                                    <label className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        onChange={(e) => setName(e.target.value)}
                                        value={Name}
                                    />
                                    <span className="text-danger">{NameError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label">Incident code</label>
                                    {/* <ReactSelect
                                        options={TestTypeList}
                                        isMulti
                                        placeholder='Select Ticket Status'
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        // defaultMenuIsOpen
                                        components={{
                                            Option
                                        }}
                                        onChange={(selected) => {
                                            setTestType(selected)
                                            // setReportType("");
                                            // setSearched(true)
                                        }}
                                        allowSelectAll={true}
                                        value={TestType ? TestType : null}
                                    /> */}

                                    <ReactSelect
                                        options={TestTypeList}
                                        isMulti
                                        placeholder='Select Incident Code'
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        // defaultMenuIsOpen
                                        components={{
                                            Option
                                        }}
                                        onChange={(selected) => {
                                            setTestType(selected)
                                            // setReportType("");
                                            // setSearched(true)
                                        }}
                                        allowSelectAll={true}
                                        value={TestType ? TestType : null}
                                    />

                                    <span className="text-danger">{TestTypeError}</span>
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary px-5">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddMultiBit;
