import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "react-switch";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";

const AddRole = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [RoleName, setRoleName] = useState("");
  const [RoleNameError, setRoleNameError] = useState("");
  const [Active, setActive] = useState(false);
  const navigate = useNavigate();

  const handleValidation = () => {
    setRoleNameError("");
    var flag = true;

    if (RoleName === "") {
      setRoleNameError("Rolename is required");
      flag = false;
    }

    return flag;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      const payload = {
        RoleName: RoleName,
        Active: Active ? 1 : 0,
      };
      if (id) {
        setLoader(true);
        axios
          .put("role/" + id, payload)
          .then((response) => {
            navigate("/role_management", {
              state: response.data.status.message,
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      } else {
        setLoader(true);
        axios
          .post("role", payload)
          .then((response) => {
            setLoader(false);
            navigate("/role_management", { state: response.data.status.message, });
          })
          .catch(() => {
            setLoader(false);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setLoader(true);
      axios
        .get("role/" + id)
        .then((response) => {
          setRoleName(response.data.data.RoleName);
          setActive(response.data.data.Active === 1 ? true : false);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [id]);

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-xl-10 mx-auto">
          <div className="card">
            <div className="card-body p-5">
              <div className="card-title">
                <div className="row">
                  <div className="col-md-8">
                    <h5 className="mb-0  text-primary">
                      <i className="bx bx-user-plus me-1 font-22"></i>
                      {id ? "Edit Role" : "Add Role"}
                    </h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <form className="row g-3" onSubmit={formSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputFirstName" className="form-label">
                    Rolename
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setRoleName(e.target.value)}
                    value={RoleName}
                  />
                  <span className="text-danger">{RoleNameError}</span>
                </div>
                <div className="mb-3 mt-3">
                  <Switch
                    className="login-switch"
                    onColor={"#136dfa"}
                    height={25}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={() => setActive(!Active)}
                    checked={Active}
                  />
                  <p
                    className="text-white m-0"
                    style={{ textAlign: "left", paddingLeft: " 70px" }}
                  >
                    Active
                  </p>
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary px-5">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRole;
