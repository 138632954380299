import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import axios from "../../axios";
import Loader from '../../Components/Loader';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import Switch from "react-switch";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const List = () => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    var ClientId = JSON.parse(localStorage.getItem("ClientId"));
    const [Clients, setClients] = useState([]);
    const [ClientID, setClientID] = useState(ClientId || "");
    const location = useLocation();
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();
    const [ModuleData, setModuleData] = useState([])
    const [loader, setLoader] = useState("")
    const [DeleteUserId, setDeleteUserId] = useState("");
    const [ServiceCall, setServiceCall] = useState(true);
    const [error, setError] = useState("");

    const OnchangeClient = (selected) => {
        setClientID(selected.Id)
        setServiceCall(true)
    }

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2) {
            setLoader(true);
            axios
                .get("clients")
                .then((response) => {
                    var TempData = response.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClients(arr)

                    // setClients(response.data.data);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);


    const getClientValue = () => {
        if (ClientID) {
            for (var i = 0; i < Clients.length; i++) {
                if (Clients[i].Id === parseInt(ClientID)) {
                    return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
                }
            }
        }
    }

    useEffect(() => {
        if (ClientID) {
            localStorage.setItem("BsDownConfigSelectedClientId", ClientID);
        }

    }, [ClientID])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess("");
        }
        return () => { };
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);

    useEffect(() => {
        if (location.state) {

            if (location.state.message !== "") {
                setSuccess(location.state.message);

            }

            if (location.state.clientid) {
                setClientID(location.state.clientid);
            }

            navigate("/basestation_down_config", {
                state: null,
            });
        }
    }, [location, navigate]);

    const AlertResponse = (response) => {
        if (response === true) {
            setLoader(true);
            axios
                .get("delete_basestation_down?ClientId=" + ClientID + "&DeleteId=" + DeleteUserId)
                .then((response) => {
                    setServiceCall(true);
                    setDeleteUserId("");
                    setSuccess("Record deleted successfully");
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        } else {
            setDeleteUserId("");
        }
    };

    useEffect(() => {
        if (ServiceCall && ClientID) {
            setLoader(true);
            axios
                .get("get_all_basestation_down?ClientId=" + ClientID)
                .then((response) => {
                    setModuleData(response.data.data);
                    setLoader(false);
                    setServiceCall(false)
                })
                .catch(() => {
                    setLoader(false);
                });
        }

    }, [ServiceCall, ClientID])

    const ChangeActiveStatus = (Id, Active) => {
        const Payload = {
            "Id": Id,
            "Active": parseInt(Active) === 0 ? 1 : 0,
            "ClientId": ClientID
        }
        axios.post("change_active_basestation_down", Payload)
            .then((response) => {
                setServiceCall(true)

            })
            .catch((error) => {
            })
    }

    return (
        <>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}

            {DeleteUserId ?
                <Alert
                    message="Are you sure, you want to delete this record?"
                    AlertResponse={AlertResponse}
                />
                :
                ""
            }

            {/* {loader ? <Loader height={LoaderHeight} /> : null} */}
            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0">Base Station Down Configuration</h6>
                </div>

            </div>
            <hr />
            <div className="row">
                <div className="col-md-6">
                    {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                        <>
                            {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                        </>
                    ) : null}
                </div>
                <div className="col-md-6">
                    {ClientID ?
                        <Link to="/add_basestation_down" ><button
                            type="submit"
                            className="btn btn-primary px-5"
                            style={{ float: "right", marginLeft: "10px" }}
                        >
                            Add
                        </button></Link>
                        : null}
                </div>
            </div>

            {
                ClientID ?
                    <div className="card" >
                        <div className="card-body horizontal-scroll">

                            <div className="row">
                                <div className="col-md-12">

                                    <table className="table mb-0 table-striped table-striped ticket-table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Incident Code</th>
                                                <th>Threshold (No. Of Base Station)</th>
                                                <th>Time (Minutes)</th>
                                                <th>Emails</th>
                                                <th>Active</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ModuleData.length > 0 ?
                                                ModuleData.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.IncidentCode}</td>
                                                            <td>{item.NoOfBasestation}</td>
                                                            <td>{item.Time}</td>
                                                            <td>{item.Email}</td>
                                                            <td>
                                                                <Switch
                                                                    className="login-switch"
                                                                    onColor={"#136dfa"}
                                                                    height={25}
                                                                    checkedIcon={false}
                                                                    uncheckedIcon={false}
                                                                    checked={parseInt(item.Active) === 1 ? true : false}
                                                                    onChange={() => ChangeActiveStatus(item.Id, item.Active)}
                                                                />
                                                            </td>
                                                            <td className="action-buttons">
                                                                <i
                                                                    className="bx bx-trash text-danger"
                                                                    title="Delete"
                                                                    onClick={() => setDeleteUserId(item.Id)}
                                                                ></i>
                                                                <Link to={"/add_basestation_down/" + item.Id}>
                                                                    <i className="bx bx-pencil" title="Edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                :
                                                <tr>
                                                    <td
                                                        colSpan={7}
                                                        style={{ textAlign: "center" }}>
                                                        <b>No Alerts Found</b>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default List