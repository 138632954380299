import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSunburst from "highcharts/modules/sunburst";
import CustomEvents from "highcharts-custom-events";
HighchartsSunburst(Highcharts);
CustomEvents(Highcharts);

const BitSunburstChart = (props) => {
    const refChart = useRef()
    const BitTotal = parseInt(props.item.OKStateCount) + parseInt(props.item.KOStateCount) + parseInt(props.item.NOStateCount) + parseInt(props.item.WARNStateCount)

    const data = [{
        id: '0.0',
        parent: '',
        name: 'Basstations'
    },

    /* Bit State  */
    {
        id: "2.1",
        parent: '1.1',
        name: 'BIT State',
        color: '#3c3cff',
        value: 100,
        ticket_count: BitTotal
    },

    {
        id: '3.1',
        parent: '2.1',
        name: 'OK',
        value: (props.item.OKStateCount / BitTotal * 100).toFixed(2),
        color: "#00b500",
        ticket_count: props.item.OKStateCount
    }, {
        id: '3.6',
        parent: '2.1',
        name: 'KO',
        value: (props.item.KOStateCount / BitTotal * 100).toFixed(2),
        color: "#fd6666",
        ticket_count: props.item.KOStateCount
    },
    {
        id: '3.7',
        parent: '2.1',
        name: 'NO',
        value: (props.item.NOStateCount / BitTotal * 100).toFixed(2),
        color: "red",
        ticket_count: props.item.NOStateCount
    }, {
        id: '3.9',
        parent: '2.1',
        name: 'WARN',
        value: (props.item.WARNStateCount / BitTotal * 100).toFixed(2),
        color: "#df8c29",
        ticket_count: props.item.WARNStateCount
    }
    ]

    let RootNade = ''
    let options = {
        chart: {
            backgroundColor: 'rgba(0,0,0,0)',
            height: "100%",
            className: 'highchart-class'
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        series: [
            {
                type: "sunburst",
                data: data,
                name:"Basestations",
                allowDrillToNode: true,
                allowTraversingTree: false,  // Breadcrump disable 
                cursor: "pointer",
                point: {
                    events: {
                        click: function (e) {
                            var series = refChart.current.chart.series[0]
                            if (RootNade === e.point.id) {
                                RootNade = e.point.parent
                                series.setRootNode(e.point.node.parentNode.id, true, { trigger: 'click' })
                                if (e.point.id === "2.1" && props.Selected === "country") {
                                    if (e.point.name === "BIT State") {
                                        props.setClientCountryId(props.ClientCountryId);
                                        props.setCountryName(props.CountryName);
                                        props.setCountryCode(props.item.CountryCode)
                                        props.setBitComSelectedState("BitState");
                                    } else {
                                        props.setClientCountryId(props.ClientCountryId);
                                        props.setCountryName(props.CountryName);
                                        props.setCountryCode(props.item.CountryCode)
                                        props.setBitComSelectedState("ComState");
                                    }
                                }else if(e.point.id === "2.1" && props.Selected === "client"){
                                    props.setClientId(props.item.ClientId);
                                    props.setClientCountryId();
                                    props.setClientLogo(props.item.ClientLogo)
                                    props.setClientName(props.item.ClientName);
                                }
                            } else {
                                RootNade = e.point.id
                                series.setRootNode(e.point.id, true, { trigger: 'click' })
                                if (e.point.id === "2.1" && props.Selected === "country") {
                                    if (e.point.name === "BIT State") {
                                        props.setClientCountryId(props.ClientCountryId);
                                        props.setCountryName(props.CountryName);
                                        props.setCountryCode(props.item.CountryCode)
                                        props.setBitComSelectedState("BitState");
                                    } else {
                                        props.setClientCountryId(props.ClientCountryId);
                                        props.setCountryName(props.CountryName);
                                        props.setCountryCode(props.item.CountryCode)
                                        props.setBitComSelectedState("ComState");
                                    }
                                }else if(e.point.id === "2.1" && props.Selected === "client"){
                                    props.setClientId(props.item.ClientId);
                                    props.setClientCountryId();
                                    props.setClientLogo(props.item.ClientLogo)
                                    props.setClientName(props.item.ClientName);
                                }
                            }
                        }
                    }
                },
                dataLabels: {
                    format: '{point.ticket_count}',
                    filter: { property: "innerArcLength", operator: ">", value: 16 },
                    style: { textShadow: false, textOutline: null, color: "contrast", fontSize:"14px" }
                },

                levels: [
                    {
                        level: 1,
                        levelIsConstant: false,
                        dataLabels: {
                            filter: { property: "outerArcLength", operator: ">", value: 64 },
                        },
                        colorByPoint: true,
                    },

                ],
            },
        ],
        tooltip: {
            formatter: function () {
                if ('point' in this) {
                    return this.point.name + " (Basestation Count:" + this.point.ticket_count + ") ";
                }
            },
            enabled: true,
            shared: true,
            useHTML: true,
            borderColor: '#ffffff',
            backgroundColor: '#ffffff',
            borderRadius: 8
        },

        exporting: {
            enabled: false,
            buttons: {
                contextButton: {
                    className: "highcharts-contextbutton",
                    enabled: true,
                    symbol: 'download',
                    symbolStroke: "#ffffff",
                    theme: {
                        fill: '#1c2427',
                    },
                    titleKey: ""
                }
            }
        }
    };
    return (
        <div>
            <HighchartsReact ref={refChart} highcharts={Highcharts} options={options} />
        </div>
    );
};

export default BitSunburstChart;
