import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "../axios";
// import UserIcon from '../Images/user.png'
import BGImage from "../Images/login3.jpg";
import Logo from "../Images/foxilogic.png";
import { ToastContainer, toast } from "react-toastify";

// https://www.npmjs.com/package/react-switch

const OtpVarification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Otp, setOtp] = useState("");
  const [OtpError, setOtpError] = useState("");
  const [loader, setLoader] = useState(false)
  const [Identity, setIdentity] = useState(false)


  useEffect(() => {
    if (location.state) {
      if (location.state.Email) {
        setEmail(location.state.Email);
      }
      if (location.state.Identity) {
        setIdentity(location.state.Identity)
      }
    }
  }, [location])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Email !== "") {
      setOtpError("");
      var flag = true;

      if (Otp === "") {
        setOtpError("Otp field is required");
        flag = false
      }

      if (flag) {
        


        setLoader(true);
        axios
          .post("otp_varification", {
            Email: Email,
            Otp: Otp,
            Identity: Identity
          })
          .then((response) => {

            toast.success(response.data.status.message);
            setLoader(false)
            if (Identity === "SignupOtp") {
              navigate("/login");
            } else {
              navigate("/reset_password", {
                state: {
                  Otp: Otp,
                  Email: Email
                }
              });
            }

          })
          .catch((response) => {
            setLoader(false)
          });

      }

    } else {
      toast.error("Please complete first page of  sign up information")
    }
  }

  return (
    <>
      <ToastContainer theme="colored" />
      <div
        className="bg-lock-screen"
        style={{ height: "100vh", backgroundImage: "url(" + BGImage + ")" }}
      >
        <div className="wrapper">
          <div className="authentication-lock-screen d-flex align-items-center justify-content-center">
            <div
              className="card"
              style={{
                backgroundColor: "rgb(0,0,0,0.6)",
                borderRadius: "1.25rem",
              }}
            >
              <form onSubmit={handleSubmit}>
                <div className="card-body p-md-5 text-center">
                  <div className="">
                    <img src={Logo} className="" width="120" alt="" />
                  </div>
                  <h5 className="my-2 text-white">
                    OTP Confirmation
                  </h5>
                  <div className="mb-3 mt-3">
                    <p className="text-white m-0" style={{ textAlign: "center" }}>
                      {/* Otp */}
                    </p>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        color: "#ffffff",
                        borderRadius: "1.25rem",
                      }}
                      type="text"
                      className="form-control"
                      name="otp"
                      value={Otp}
                      onChange={(e) => {
                        setOtp(e.target.value)
                      }}
                    />
                    <p className="text-danger mt-2">{OtpError}</p>
                  </div>
                  <div className="mb-3 mt-3">
                    <div className="d-grid">
                      {!loader ? (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{ borderRadius: "1.25rem" }}
                        >
                          Varify Otp
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{ borderRadius: "1.25rem" }}
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Varify Otp
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVarification;
