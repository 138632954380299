import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../Components/Alert";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const IncidentCodeManagement = () => {
  var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  var ClientId = JSON.parse(localStorage.getItem("ClientId"));

  const [loader, setLoader] = useState(false);
  const [Clients, setClients] = useState([]);
  const [Client, setClient] = useState(ClientId || "-1");
  const [ModuleData, setModuleData] = useState([]);
  const [Severity, setSeverity] = useState([]);
  const [SeverityError, setSeverityError] = useState("");
  const [LoaderHeight, setLoaderHeight] = useState("calc(100% + 120px)");
  const [ApplyAllAlert, setApplyAllAlert] = useState("");
  const [success, setSuccess] = useState("");


  const getClientValue = () => {
    if (Client) {
      for (var i = 0; i < Clients.length; i++) {
        if (Clients[i].Id === parseInt(Client)) {
          return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
        }
      }
    }
  }

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (UserGroup.Id === 1 || UserGroup.Id === 2) {
      setLoader(true);
      axios
        .get("clients")
        .then((response) => {

          var TempData = response.data.data;
          var arr = [];
          for (var i = 0; i < TempData.length; i++) {
            if (i === 0 && (UserGroup.Id === 1 || UserGroup.Id === 2)) {
              var default_obj = {};
              default_obj["Id"] = -1;
              default_obj["Name"] = "Default Setting";
              default_obj["label"] = "Default Setting";
              default_obj["value"] = -1;
              default_obj["image"] = DefaultLogo;
              arr.push(default_obj)
            }

            var obj = TempData[i]
            obj["label"] = TempData[i].Name;
            obj["value"] = TempData[i].Id;
            obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
            arr.push(obj)
          }
          setClients(arr)
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [UserGroup.Id]);

  useEffect(() => {
    // if(Client === 3 || Client === 4 ){
    if (Client) {
      setLoader(true);
      axios
        .get("get_incident_code_config?ClientId=" + Client)
        .then((response) => {
          // setActive(response.data.data.Active === 1 ? true : false)
          setModuleData(response.data.data);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setModuleData([]);
    }
    // }
  }, [Client]);

  const handleActive = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].DefaultActive = value === 0 ? 1 : 0;
    setModuleData(ModuleDataTemp);
  };

  const handleRemoteFix = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].DefaultRemoteFix = value === 0 ? 1 : 0;
    if (value === 1) {
      ModuleDataTemp[index].DefaultRemoteRestart = 0;
      ModuleDataTemp[index].DefaultSetConfig = 0;
      ModuleDataTemp[index].DefaultBasestationPower = 0;
      ModuleDataTemp[index].DefaultMerakiPower = 0;
      ModuleDataTemp[index].DefaultBreakerBypassRelay = 0;
    }
    setModuleData(ModuleDataTemp);
  };

  const handleRemoteRestart = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].DefaultRemoteRestart = value === 0 ? 1 : 0;
    setModuleData(ModuleDataTemp);
  };

  const handleSetConfig = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].DefaultSetConfig = value === 0 ? 1 : 0;
    if (value === 0) {
      ModuleDataTemp[index].DefaultRemoteRestart = 1;
    }
    setModuleData(ModuleDataTemp);
  };

  const handleBasestationPower = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].DefaultBasestationPower = value === 0 ? 1 : 0;
    setModuleData(ModuleDataTemp);
  };

  const handleMerakiPower = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].DefaultMerakiPower = value === 0 ? 1 : 0;
    // if (value === 0) {
    //   ModuleDataTemp[index].DefaultRemoteRestart = 1;
    // }
    setModuleData(ModuleDataTemp);
  };

  const handleBreakerBypassRelay = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].DefaultBreakerBypassRelay = value === 0 ? 1 : 0;
    // if (value === 0) {
    //   ModuleDataTemp[index].DefaultRemoteRestart = 1;
    // }
    setModuleData(ModuleDataTemp);
  };

  useEffect(() => {
    if (Client) {
      setLoader(true);
      var Url =
        Client.toString() === "-1" ? "masters" : "client_severities?ClientId=" + Client;
      axios
        .get(Url)
        .then((response) => {
          if (Client.toString() === "-1") {
            setSeverity(response.data.data.Severities);
          } else {
            setSeverity(response.data.data);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [Client]);

  const handleSeverity = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].SeverityId = value;
    setModuleData(ModuleDataTemp);
  };

  const handleValidation = () => {
    var flag = true;
    for (var i = 0; i < ModuleData.length; i++) {
      if (parseInt(ModuleData[i].ClientSeverityId) === 0) {
        flag = false;
      }
    }

    if (!flag) {
      setSeverityError("Please select severity code for all incident code");
    }

    return flag;
  };

  const saveData = (type) => {
    setSeverityError("");
    const flag = handleValidation();
    if (flag) {
      var data = [];
      for (var i = 0; i < ModuleData.length; i++) {
        data.push({
          TestTypeId: ModuleData[i].TestTypeId,
          ClientSeverityId: ModuleData[i].SeverityId,
          Active: ModuleData[i].DefaultActive,
          RemoteFix: ModuleData[i].DefaultRemoteFix,
          RemoteRestart: ModuleData[i].DefaultRemoteRestart,
          SetConfig: ModuleData[i].DefaultSetConfig,
          BasestationPower: ModuleData[i].DefaultBasestationPower,
          MerakiPower: ModuleData[i].DefaultMerakiPower,
          BreakerBypassRelay: ModuleData[i].DefaultBreakerBypassRelay,
        });
      }
      const payload = {
        ClientId: Client,
        IncidentCodeConfig: data,
        SubmitType: type
      };
      setLoaderHeight("calc(100% + 120px)");
      setLoader(true);
      axios
        .put("update_incident_code_config", payload)
        .then((response) => {
          setModuleData(response.data.data);
          setSuccess(response.data.status.message);
          setLoader(false);
          setLoaderHeight("100vh");
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };
  const AlertResponse = (Result) => {
    if (Result) {
      saveData("SaveToAll")
    }
    setApplyAllAlert('')
  }

  const OnchangeClient = (selected) => {
    setClient(selected.Id)
  }

  return (
    <>
      {ApplyAllAlert ? <Alert message={ApplyAllAlert} AlertResponse={AlertResponse} /> : null}
      <ToastContainer theme="colored" />

      {loader ? <Loader height={LoaderHeight} /> : null}
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0">Sigfox Incident Code </h6>
        </div>
        {/* <div className="col-md-4 text-right">
          <Link to="/add_incident_code" className="btn btn-primary">
            <i className="bx bx-pencil" title="Edit"></i> IncidentCode
          </Link>
        </div> */}
      </div>
      <hr />

      <div className="card">
        <div className="card-body horizontal-scroll">
          <div className="row">
            <div className="col-md-6">
              {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                <>
                  {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                </>
              ) : null}
            </div>

            {Client ? (
              <div className="col-md-6">
                <button
                  type="submit"
                  className="btn btn-primary px-5"
                  style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                  onClick={() => saveData("Save")}
                >
                  Save
                </button>

                {
                  ((UserGroup.Id === 1 || UserGroup.Id === 2) && Client.toString() === "-1") ? <button
                    type="submit"
                    className="btn btn-primary px-5"
                    style={{ marginTop: "20px", float: "right" }}
                    onClick={() => setApplyAllAlert('Are you sure you want to apply all?')}
                  >
                    Apply to all
                  </button> : null
                }

              </div>
            ) : null}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div style={{ maxHeight: '67vh' }} className="horizontal-scroll">
                <table
                  className="table mb-0 table-striped text-center"
                  style={{ verticalAlign: "middle" }}
                >
                  <thead style={{ zIndex: 9 }}>
                    <tr>
                      <th>#</th>
                      <th style={{ textAlign: "left" }}>Incident Code</th>
                      <th>Severity</th>
                      <th>Waiting Period</th>
                      <th>Active</th>
                      <th>Remote Fix</th>
                      <th>Remote Restart</th>
                      <th>Set Config</th>
                      <th>Reset Basestation Power</th>
                      <th>Reset Meraki Power</th>
                      <th>Breaker By Pass Relay</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ModuleData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ textAlign: "left" }}>
                            {item.IncidentCode}
                          </td>

                          <td>
                            <select
                              className="form-select"
                              value={item.SeverityId}
                              onChange={(e) =>
                                handleSeverity(index, e.target.value)
                              }
                              disabled={item.IsMultiBit ? true : false}
                            >
                              {Severity.map((data) => {
                                return (
                                  <option key={data.Id} value={data.Id}>
                                    {Client.toString() !== "-1"
                                      ? data.severity
                                        ? data.severity.Severity
                                        : ""
                                      : data.Severity}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                          <td>
                            {Severity.map((data) => {
                              return parseInt(item.SeverityId) === data.Id
                                ? Client.toString() === "-1"
                                  ? data.DefaultWaitingPeriod
                                  : data.WaitingPeriod
                                : null;
                            })}
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <Switch
                                disabled={UserGroup.Id !== 1 ? true : false}
                                className="login-switch"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(e) =>
                                  handleActive(index, item.DefaultActive)
                                }
                                checked={parseInt(item.DefaultActive) === 0 ? false : true}
                              />
                            </div>
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <Switch
                                disabled={UserGroup.Id !== 1 ? true : false}
                                className="login-switch"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(e) =>
                                  handleRemoteFix(index, item.DefaultRemoteFix)
                                }
                                checked={
                                  parseInt(item.DefaultRemoteFix) === 0 ? false : true
                                }
                              />
                            </div>
                          </td>
                          <td
                            style={
                              !item.DefaultRemoteFix || item.DefaultSetConfig
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <Switch
                                //  disabled ={Client?true:false}
                                disabled={!item.DefaultRemoteFix || item.DefaultSetConfig || UserGroup.Id !== 1 ? true : false}
                                className="login-switch"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(e) =>
                                  handleRemoteRestart(
                                    index,
                                    item.DefaultRemoteRestart
                                  )
                                }
                                checked={
                                  parseInt(item.DefaultRemoteRestart) === 0 ? false : true
                                }
                              />
                            </div>
                          </td>
                          <td
                            style={
                              !item.DefaultRemoteFix
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <Switch
                                disabled={!item.DefaultRemoteFix || UserGroup.Id !== 1 ? true : false}
                                className="login-switch"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(e) =>
                                  handleSetConfig(index, item.DefaultSetConfig)
                                }
                                checked={
                                  parseInt(item.DefaultSetConfig) === 0 ? false : true
                                }
                              />
                            </div>
                          </td>
                          <td
                            style={
                              !item.DefaultRemoteFix
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <Switch
                                disabled={!item.DefaultRemoteFix || UserGroup.Id !== 1 ? true : false}
                                className="login-switch"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(e) =>
                                  handleBasestationPower(index, item.DefaultBasestationPower)
                                }
                                checked={
                                  parseInt(item.DefaultBasestationPower) === 0 ? false : true
                                }
                              />
                            </div>
                          </td>
                          <td
                            style={
                              !item.DefaultRemoteFix
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <Switch
                                disabled={!item.DefaultRemoteFix || UserGroup.Id !== 1 ? true : false}
                                className="login-switch"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(e) =>
                                  handleMerakiPower(index, item.DefaultMerakiPower)
                                }
                                checked={
                                  parseInt(item.DefaultMerakiPower) === 0 ? false : true
                                }
                              />
                            </div>
                          </td>
                          <td
                            style={
                              !item.DefaultRemoteFix
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <Switch
                                disabled={!item.DefaultRemoteFix || UserGroup.Id !== 1 ? true : false}
                                className="login-switch"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(e) =>
                                  handleBreakerBypassRelay(index, item.DefaultBreakerBypassRelay)
                                }
                                checked={
                                  parseInt(item.DefaultBreakerBypassRelay) === 0 ? false : true
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <span className="text-danger">{SeverityError}</span>
            </div>
          </div>

          {Client ? (
            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-primary px-5"
                  style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                  onClick={() => saveData("Save")}
                >
                  Save
                </button>

                {
                  ((UserGroup.Id === 1 || UserGroup.Id === 2) && Client === "-1") ?
                    <button
                      type="submit"
                      className="btn btn-primary px-5"
                      style={{ marginTop: "20px", float: "right" }}
                      onClick={() => saveData("SaveToAll")}
                    >
                      Apply to all
                    </button> : null
                }

              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default IncidentCodeManagement;
