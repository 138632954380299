import React, { useState, useEffect } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import EmailBodyView from "./EmailBodyView";
import Pagination from "../../Components/Pagination";
import TimeZone from "../../TimeZone";
import UserToAssignTickets from "./UserToAssignTickets";
import Switch from "react-switch";
import Alert from "../../Components/Alert";
import FaultDetails from "./FaultDetails";
import UpdateFault from "./UpdateFault";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import ManualTicketGenaration from "./ManualTicketGenaration";
import AddDiferDetails from "./AddDiferDetails";
import DiferDetails from "./DiferDetails";
import { GENERATEDBYLISTOFTICKET, TICKET_STATUS_OPEN, OPENGROUPTICKETSTATUS, TICKET_STATUS_INCIDENT_DISPATCHED, SIGFOX_DEFERRED_CONDITION, SIGFOX_UNDEFERRED_CONDITION, TICKET_STATUS_POWER_CONFIRMATION, TICKET_STATUS_CLOSED } from "../../Custom";
import Log from "./Log";


const Option = (props) => {
  return (
    <div className="custom-select-option">
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        &nbsp;
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function TicketsList(props) {
  const { CountryID } = useParams();
  const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  const navigate = useNavigate();
  const location = useLocation();
  var url = new URL(window.location.href);
  const page = url.searchParams.get('PageNo') || 1
  const limit = url.searchParams.get('Limit') || 500
  const search = url.searchParams.get('Search') || ''
  const maintenance = url.searchParams.get('Maintenance') || "";
  const [NoOfRecords, setNoOfRecords] = useState(0);
  const [ModuleData, setModuleData] = useState([]);
  const [ServiceCall, setServiceCall] = useState(location.state ? false : true);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [TicketSelected, setTicketSelected] = useState("");
  const [searchInput, setSearchInput] = useState(search)
  const [searched, setSearched] = useState(false)
  const [TicketStatusList, setTicketStatusList] = useState([])

  var assign_value = false;
  if (url.searchParams.get('ShowAssignedTickets') === "1") {
    assign_value = true;
  }

  var StatusArr = [];
  if (url.searchParams.get('Status')) {
    var Status = url.searchParams.get('Status').split("-");
    for (var k = 0; k < Status.length; k++) {
      for (var l = 0; l < TicketStatusList.length; l++) {
        if (Status[k] === TicketStatusList[l].value) {
          StatusArr.push(TicketStatusList[l]);
        }
      }
    }
  }

  const [AssignTickets, setAssignTickets] = useState(assign_value)
  const [TicketStatus, setTicketStatus] = useState(StatusArr)
  const [IncidentCodeList, setIncidentCodeList] = useState([])

  var UrlIncidentCode = []
  if (url.searchParams.get('IncidentCode')) {
    var Codes = url.searchParams.get('IncidentCode').split("-");
    for (var j = 0; j < Codes.length; j++) {
      for (var i = 0; i < IncidentCodeList.length; i++) {
        if (Codes[j] === IncidentCodeList[i].Id) {
          var obj = IncidentCodeList[i]
          UrlIncidentCode.push(obj);
        }
      }
    }
  }

  const [IncidentCode, setIncidentCode] = useState(UrlIncidentCode)
  const [TicketCategory, setTicketCategory] = useState(url.searchParams.get('TicketCategory') || "")
  const [SiteStatusCode, setSiteStatusCode] = useState(url.searchParams.get('SiteStatus') || "")
  const [SiteStatusCodeList, setSiteStatusCodeList] = useState([])
  const [TicketCategoryList, setTicketCategoryList] = useState([])
  const [TotalTicketCount, setTotalTicketCount] = useState("")
  const [TicketsModuleAccess, setTicketsModuleAccess] = useState("");
  const [userAssignIndex, setUserAssignIndex] = useState("")
  const [TicketId, setTicketId] = useState("")
  const [AssignedUserId, setAssignedUserId] = useState(0)
  const [ClientId, setClientId] = useState('')
  const [ClientCountryId, setClientCountryId] = useState('')
  const [EditTestError, setEditTestError] = useState("")
  const [Site, setSite] = useState("");
  const [FilterTicketId, setFilterTicketId] = useState(url.searchParams.get('TicketId') || '');
  const [BaseStationId, setBaseStationId] = useState(url.searchParams.get('BasestationId') || '');
  const [SiteName, setSiteName] = useState(url.searchParams.get('SiteName') || '');
  const [EmailData, setEmailData] = useState({});
  const [EmailBodyFlag, setEmailBodyFlag] = useState(false);
  const [CreateFaultTicketId, setCreateFaultTicketId] = useState("");
  const [FaultDetailsId, setFaultDetailsId] = useState("");
  const [UpdateFaultId, setUpdateFaultId] = useState("");
  const [ClickUpdateFaultId, setClickUpdateFaultId] = useState("");
  const [IncidentCodeCategories, setIncidentCodeCategories] = useState("");
  const [IncidentCodeCategoryList, setIncidentCodeCategoryList] = useState([]);
  const [ManualTicketFlag, setManualTicketFlag] = useState(false);
  const [EnabledSiteHosts, setEnabledSiteHosts] = useState([]);
  const GeneratedByList = GENERATEDBYLISTOFTICKET;
  const [GeneratedBy, setGeneratedBy] = useState(url.searchParams.get('GeneratedBy') || "");
  const [CloseManualTicketId, setCloseManualTicketId] = useState("");
  const [DeferTicketDate, setDeferTicketDate] = useState({});
  const [DiferDetailsId, setDiferDetailsId] = useState("");
  const [UndiferredTicketId, setUndiferredTicketId] = useState("");
  const [MaintenanceList, setMaintenanceList] = useState([])
  const [Maintenance, setMaintenance] = useState(maintenance)
  const [EnabledMaintenance, setEnabledMaintenance] = useState([]);
  const [RegionList, setRegionList] = useState([])
  const [Region, setRegion] = useState(url.searchParams.get('Region') || "")
  const [TicketLogId, setTicketLogId] = useState({})
  var LongPressTimeout;

  useEffect(() => {
    if (TicketStatusList.length > 0 && TicketStatus.length === 0) {
      var StatusArr = [];
      for (var i = 0; i < TicketStatusList.length; i++) {
        if (TicketStatusList[i].value === TICKET_STATUS_OPEN) {
          var obj = TicketStatusList[i]
          StatusArr.push(obj);
        }
      }
      setTicketStatus(StatusArr);
    }
  }, [TicketStatus, TicketStatusList])

  useEffect(() => {
    if (location.state) {
      if (location.state.tickete_id) {
        setFilterTicketId(location.state.tickete_id)
      }
      if (location.state.siteId) {
        setSite(location.state.siteId)
      }
      if (location.state.BaseStationId) {
        setBaseStationId(location.state.BaseStationId)
      }
      if (location.state.OutageMngtSite) {
        setSiteName(location.state.OutageMngtSite);
      }
      setTicketStatus(OPENGROUPTICKETSTATUS)
      setSuccess(location.state);
      navigate("/tickets-list/" + CountryID, {
        state: null,
      });
    }
  }, [location, CountryID, navigate]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    setServiceCall(true);
  }, [limit, page, search]);

  useEffect(() => {
    if (CountryID) {
      setLoader(true);
      axios.get("masters?ClientCountryId=" + CountryID)
        .then((response) => {
          const TempTicketStatus = response.data.data.TicketStatus;
          const TicketStatusArr = TempTicketStatus.map(status => ({ value: status, label: status }));
          TicketStatusArr.unshift({ value: "Select All", label: "Select All" });
          setTicketStatusList(TicketStatusArr);

          setSiteStatusCodeList(response.data.data.SiteStatus)
          setIncidentCodeCategoryList(response.data.data.IncidentCodeCategories)
          setEnabledSiteHosts(response.data.data.EnabledSiteHosts);
          setEnabledMaintenance(response.data.data.EnabledMaintenance)
          const TestTypes = response.data.data.TestTypes;
          const TestTypesArr = TestTypes.map(item => ({
            ...item,
            value: item.Id,
            label: item.IncidentCode
          }));
          setIncidentCodeList(TestTypesArr);
          setTicketCategoryList(response.data.data.TicketCategory)
          setMaintenanceList(response.data.data.aSiteMaintenanceNames)
          setRegionList(response.data.data.SigfoxSiteRegions)
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        })
    }
  }, [IncidentCodeCategories, CountryID])

  useEffect(() => {
    if (ServiceCall && page && TicketStatus.length > 0) {
      setModuleData([]);
      setLoader(true);
      var Arr = []
      for (var i = 0; i < IncidentCode.length; i++) {
        Arr.push(IncidentCode[i].Id);
      }

      var StatusArr = []
      for (var j = 0; j < TicketStatus.length; j++) {
        if (TicketStatus[j].value === "Select All") {
          for (var n = 0; n < TicketStatusList.length; n++) {
            if (TicketStatusList[n].value !== "Select All") {
              StatusArr.push(TicketStatusList[n].value);
            }
          }
        } else {
          StatusArr.push(TicketStatus[j].value);
        }
      }

      axios
        .get("get_tickets?PageNo=" + page + "&Limit=" + limit + "&ClientCountryId=" + CountryID + "&Search=" + search + "&Status=" + StatusArr + "&IncidentCode=" + Arr + "&SiteStatus=" + SiteStatusCode + "&TicketCategory=" + TicketCategory + "&ShowAssignedTickets=" + (AssignTickets ? 1 : 0) + "&SiteId=" + Site + "&TicketId=" + FilterTicketId + "&BasestationId=" + BaseStationId + "&SiteName=" + SiteName + "&GeneratedBy=" + GeneratedBy + "&SiteMaintenanceName=" + Maintenance + "&Region=" + Region)
        .then((response) => {
          var Arr = [];
          var Data = response.data.data.Tickets

          for (var i = 0; i < Data.length; i++) {
            var Obj = Data[i];
            var CreateFaultFlag = false;

            if (Data[i].TicketStatus === TICKET_STATUS_OPEN) {
              if ((EnabledSiteHosts.includes(Data[i].SiteHost)) || (Data[i].SiteName.startsWith("KNP_"))) {
                if ((Data[i].IncidentCode !== "BS-NO-COMMS") && (EnabledMaintenance.includes(Data[i].SiteMaintenanceName))) {
                  CreateFaultFlag = true
                }
              } else if (EnabledMaintenance.includes(Data[i].SiteMaintenanceName)) {
                CreateFaultFlag = true
              }
            } else if ((Data[i].TicketStatus === TICKET_STATUS_INCIDENT_DISPATCHED) && ((EnabledSiteHosts.includes(Data[i].SiteHost)) || (Data[i].SiteName.startsWith("KNP_"))) && (EnabledMaintenance.includes(Data[i].SiteMaintenanceName))) {
              CreateFaultFlag = true
            } else if (Data[i].TicketStatus === TICKET_STATUS_POWER_CONFIRMATION) {
              CreateFaultFlag = true
            }

            Obj['CreateFaultFlag'] = CreateFaultFlag;
            Arr.push(Obj);
          }

          setModuleData(Arr);
          setTotalTicketCount(response.data.data.TotalTicketCount);
          setTicketsModuleAccess(response.data.data.TicketsModuleAccess);
          setNoOfRecords(response.data.NoOfRecords);
          setServiceCall(false);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [ServiceCall, page, limit, search, CountryID, IncidentCode, SiteStatusCode, TicketStatus, TicketCategory, AssignTickets, Site, BaseStationId, FilterTicketId, SiteName, ManualTicketFlag, GeneratedBy, Maintenance, EnabledMaintenance, EnabledSiteHosts, TicketStatusList, Region]);

  const clearSearchHandler = () => {
    setSiteName("");
    setBaseStationId("")
    setServiceCall(true)
    setSearched(false)
    setSearchInput("")
    setSite("")
    setTicketStatus([])
    setIncidentCode([])
    setSiteStatusCode("")
    setTicketCategory("");
    setFilterTicketId("")
    setAssignTickets(false);
    setIncidentCodeCategories("")
    setGeneratedBy("");
    setMaintenance("")
    setRegion("")
    navigate("?page=1&Search=&Status=&IncidentCode=&SiteStatus=&TicketCategory=&ShowAssignedTickets=&Maintenance=")
  }

  useEffect(() => {
    if (searched) {
      var Str = []
      for (var i = 0; i < IncidentCode.length; i++) {
        Str += IncidentCode[i].Id
        if (i !== IncidentCode.length - 1) {
          Str += "-";
        }
      }

      var Status = []
      for (var j = 0; j < TicketStatus.length; j++) {
        Status += TicketStatus[j].value
        if (j !== TicketStatus.length - 1) {
          Status += "-";
        }
      }

      navigate("?page=" + page + "&Limit=" + limit + "&Search=" + searchInput + "&Status=" + Status + "&IncidentCode=" + Str + "&SiteStatus=" + SiteStatusCode + "&TicketCategory=" + TicketCategory + "&ShowAssignedTickets=" + (AssignTickets ? 1 : 0) + "&TicketId=" + FilterTicketId + "&BasestationId=" + BaseStationId + "&SiteName=" + SiteName + "&GeneratedBy=" + GeneratedBy + "&Maintenance=" + Maintenance + "&Region=" + Region)
      setServiceCall(true);
      setSearched(false)
    }
  }, [navigate, searched, page, limit, searchInput, TicketStatus, IncidentCode, SiteStatusCode, TicketCategory, AssignTickets, BaseStationId, FilterTicketId, SiteName, GeneratedBy, Maintenance, Region])

  const handleExportTicket = () => {
    setLoader(true)
    var Str = []
    for (var i = 0; i < IncidentCode.length; i++) {
      Str += IncidentCode[i].Id
      if (i !== IncidentCode.length - 1) {
        Str += ",";
      }
    }

    var StatusArr = []
    for (var j = 0; j < TicketStatus.length; j++) {
      if (TicketStatus[j].value === "Select All") {
        for (var n = 0; n < TicketStatusList.length; n++) {
          if (TicketStatusList[n].value !== "Select All") {
            StatusArr.push(TicketStatusList[n].value);
          }
        }
      } else {
        StatusArr.push(TicketStatus[j].value);
      }
    }
    const TimeZone = localStorage.getItem("TimeZone") ? JSON.parse(localStorage.getItem("TimeZone")) : Intl.DateTimeFormat().resolvedOptions().timeZone
    const TimeZoneParm = (typeof TimeZone === 'string') ? TimeZone : TimeZone.value

    axios.get("export_tickets?ClientCountryId=" + CountryID + "&Search=" + search + "&Status=" + StatusArr + "&IncidentCode=" + Str + "&SiteStatus=" + SiteStatusCode + "&TicketCategory=" + TicketCategory + "&ShowAssignedTickets=" + (AssignTickets ? 1 : 0) + "&Timezone=" + TimeZoneParm + "&SiteMaintenanceName=" + Maintenance + "&GeneratedBy=" + GeneratedBy + "&Region=" + Region)
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false)
        setSuccess(response.data.status.message)
      })
      .catch((error) => {
        setLoader(false)
      })
  }

  const handleAssignUser = (getIndex, getTicketId, getAssignedUserId, ClientId, ClientCountryId) => {
    setUserAssignIndex(getIndex)
    setTicketId(getTicketId)
    setAssignedUserId(getAssignedUserId)
    setClientId(ClientId)
    setClientCountryId(ClientCountryId)
  }

  const handleValidation = () => {
    setEditTestError("")
    var flag = true;
    if (AssignedUserId === 0) {
      flag = false;
      setEditTestError("Please Select User")
    }
    return flag;
  };

  const updateAssignUserId = (type) => {
    const flag = handleValidation();
    if (flag) {
      setLoader(true);
      const param = {
        ClientCountryId: userAssignIndex,
        TicketId: TicketId,
        AssignedUserId: AssignedUserId
      }
      axios.put("assign_ticket", param)
        .then((response) => {
          setSuccess(response.data.status.message)
          setUserAssignIndex("")
          setTicketId("")
          setAssignedUserId("")
          setServiceCall(true);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        })
    }
  }

  useEffect(() => {
    if (TicketSelected !== "" && CountryID > 0) {
      setLoader(true);
      axios
        .get(
          "get_email_body?ClientCountryId=" + CountryID + "&TicketId=" + TicketSelected
        )
        .then((response) => {
          setEmailData(response.data.data)
          setEmailBodyFlag(true);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [TicketSelected, CountryID])

  const HandleFaultDetails = (response) => {
    if (response && CreateFaultTicketId !== "" && CountryID > 0) {
      setLoader(true);
      axios
        .post(
          "create_fault_bs_ticket?ClientCountryId=" + CountryID + "&TicketId=" + CreateFaultTicketId
        )
        .then((response) => {
          if (parseInt(response.status) === 200) {
            toast.success(response.data.status)
            setCreateFaultTicketId("");
            setServiceCall(true);
          } else {
            toast.error(response.data.status)
            setCreateFaultTicketId("");
            setServiceCall(true);
          }
          setLoader(false);
        })
        .catch((response) => {
          setLoader(false);
          toast.error(response.response.data.status)
          setCreateFaultTicketId("");
          setServiceCall(true);
        });

    } else {
      setCreateFaultTicketId("");
    }
  }

  const OnChangeCategory = (Value) => {
    var IncidentCodeIds = "";
    for (var i = 0; i < IncidentCodeCategoryList.length; i++) {
      if (IncidentCodeCategoryList[i].Id === parseInt(Value)) {
        IncidentCodeIds = IncidentCodeCategoryList[i].IncidentCodeIds;
      }
    }
    IncidentCodeIds = IncidentCodeIds.replace('[', '');
    IncidentCodeIds = IncidentCodeIds.replace(']', '');

    IncidentCodeIds = IncidentCodeIds.split(',');
    var Arr = [];
    for (var j = 0; j < IncidentCodeList.length; j++) {
      if (IncidentCodeIds.includes(IncidentCodeList[j].Id.toString())) {
        Arr.push(IncidentCodeList[j]);
      }
    }
    setIncidentCode(Arr);
    setIncidentCodeCategories(Value);
    setSearched(true);
  }

  const HandleCloseManualTicket = (response) => {
    if (response && CloseManualTicketId !== "" && CountryID > 0) {
      setLoader(true);
      axios
        .post(
          "close_manual_ticket?ClientCountryId=" + CountryID + "&TicketId=" + CloseManualTicketId
        )
        .then((response) => {
          if (parseInt(response.status) === 200) {
            toast.success(response.data.status)
            setCloseManualTicketId("");
            setServiceCall(true);
          } else {
            toast.error(response.data.status)
            setCloseManualTicketId("");
            setServiceCall(true);
          }
          setLoader(false);
        })
        .catch((response) => {
          setLoader(false);
          toast.error(response.response.data.status)
          setCloseManualTicketId("");
          setServiceCall(true);
        });

    } else {
      setCloseManualTicketId("");
    }
  }

  const HandleUndiferred = (response) => {
    if (response && UndiferredTicketId !== "" && CountryID > 0) {
      setLoader(true);
      const Payload = {
        "ClientCountryId": CountryID,
        "TicketId": UndiferredTicketId
      }
      axios
        .post(
          "undeferred_bs_ticket", Payload
        )
        .then((response) => {
          if (parseInt(response.data.status.code) === 200) {
            toast.success(response.data.status.message)
            setServiceCall(true);
          } else {
            toast.error(response.data.status.message)
          }
        })
        .catch((response) => {
          toast.error(response.response.data.status)
          setServiceCall(true);
        });
      setLoader(false);
      setUndiferredTicketId("");

    } else {
      setUndiferredTicketId("");
    }
  }

  const handleMouseDown = (index) => {
    LongPressTimeout = window.setTimeout(function () {
      var ModuleDataTemp = [...ModuleData];
      ModuleDataTemp[index]["CreateFaultFlag"] = true;
      setModuleData(ModuleDataTemp);
    }, 7000)//Change time according to need but most commonly used time is 7000 milliseconds.
  };

  const handleMouseUp = (index) => {
    clearTimeout(LongPressTimeout)
  };

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}

      {
        JSON.stringify(TicketLogId) !== "{}" ? <Log TicketLogId={TicketLogId} setTicketLogId={setTicketLogId} CountryID={CountryID} /> : null
      }

      {JSON.stringify(DeferTicketDate) !== "{}" ? <AddDiferDetails DeferTicketDate={DeferTicketDate} setDeferTicketDate={setDeferTicketDate} CountryID={CountryID} setServiceCall={setServiceCall} /> : null}

      {DiferDetailsId !== "" ? <DiferDetails DiferDetailsId={DiferDetailsId} setDiferDetailsId={setDiferDetailsId} CountryID={CountryID} /> : null}

      {
        UndiferredTicketId !== "" ?
          <Alert
            message="Are you sure, you want to undefer ticket?"
            AlertResponse={HandleUndiferred}
          /> : null
      }

      {FaultDetailsId !== "" ? <FaultDetails CountryID={CountryID} FaultDetailsId={FaultDetailsId} setFaultDetailsId={setFaultDetailsId} /> : null}

      {ManualTicketFlag ? <ManualTicketGenaration setServiceCall={setServiceCall} CountryID={CountryID} setManualTicketFlag={setManualTicketFlag} /> : null}

      {CreateFaultTicketId !== "" ? (
        <Alert
          message="Are you sure, you want to create fault?"
          AlertResponse={HandleFaultDetails}
        />
      ) :
        null
      }
      {CloseManualTicketId !== "" ? <Alert
        message="Are you sure, you want to close ticket?"
        AlertResponse={HandleCloseManualTicket}
      /> : null}

      {UpdateFaultId !== "" ?
        <UpdateFault CountryID={CountryID} ClickUpdateFaultId={ClickUpdateFaultId} setClickUpdateFaultId={setClickUpdateFaultId} UpdateFaultId={UpdateFaultId} setUpdateFaultId={setUpdateFaultId} />
        : null
      }

      {EmailBodyFlag ? <EmailBodyView TicketsModuleAccess={TicketsModuleAccess} TicketSelected={TicketSelected} setTicketSelected={setTicketSelected} CountryID={CountryID} EmailData={EmailData} setEmailBodyFlag={setEmailBodyFlag} /> : null}

      {userAssignIndex !== "" && TicketId !== "" ?
        <UserToAssignTickets
          setTicketId={setTicketId}
          setUserAssignIndex={setUserAssignIndex}
          userAssignIndex={userAssignIndex}
          setAssignedUserId={setAssignedUserId}
          updateAssignUserId={updateAssignUserId}
          setEditTestError={setEditTestError}
          AssignedUserId={AssignedUserId}
          EditTestError={EditTestError}
          TicketId={TicketId}
          ClientId={ClientId}
          ClientCountryId={ClientCountryId}
          TicketsModuleAccess={TicketsModuleAccess}
        /> : null}

      <div className="row">
        <div className="col-md-6">
          <h6 className="mt-3">Tickets</h6>
        </div>
        <div className="col-md-4 text-right">
          <input
            type="text"
            className="form-control mb-2"
            id="search"
            name="search"
            title="Search Ticket Id, Basestation Id, Site Host, Site Name, Fresh Service Ticket Id, Fault Reference Number, Maintenance"
            placeholder="Search Ticket Id ,  Basestation Id ,  Site Host,  Site Name ,  Fresh Service Ticket Id ,  Fault Reference Number, Maintenance "
            value={searchInput}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setSearched(true)
              }
            }}
            onChange={(e) => { setSearchInput(e.target.value); setSite(""); setFilterTicketId(""); setBaseStationId(""); setSiteName("") }}
          />
        </div>
        <div className="col-sm-2">
          <select
            className="form-select"
            onChange={(e) => {
              setGeneratedBy(e.target.value)
              setSearched(true)
            }}
            value={GeneratedBy}
          >
            <option value="">Select Generated By</option>
            {
              GeneratedByList.map((item, index) => (
                <option key={index} value={item}> {item} </option>
              ))
            }
          </select>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-2">
          <select
            className="form-select"
            onChange={(e) => {
              setTicketCategory(e.target.value)
              setSearched(true)
              setSite("");
              setFilterTicketId("");
              setBaseStationId("");
              setSiteName("")
            }}
            value={TicketCategory}
          >
            <option value="">Select Ticket Category</option>
            {
              TicketCategoryList.map((item, index) => (
                <option key={index} value={item.key}> {item.val} </option>
              ))
            }
          </select>
        </div>
        <div className="col-sm-2">
          <ReactSelect
            options={TicketStatusList}
            isMulti
            placeholder='Select Ticket Status'
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            // defaultMenuIsOpen
            components={{
              Option
            }}
            onChange={(selected) => {
              if (selected !== null) {
                if (selected.length === 0) {
                  const selected_arr = TicketStatusList.filter(item => item.value === 'Select All');
                  setTicketStatus(selected_arr);
                } else {
                  const lastSelectedValue = selected[selected.length - 1].value;
                  if (lastSelectedValue === 'Select All') {
                    const selected_arr2 = selected.filter(item => item.value === 'Select All');
                    setTicketStatus(selected_arr2);
                  } else {
                    const filteredSelected = selected.filter(item => item.value !== 'Select All');
                    setTicketStatus(filteredSelected);
                  }
                }
              }

              setSite("");
              setFilterTicketId("");
              setSiteName("")
              setSearched(true)
            }}

            allowSelectAll={true}
            value={TicketStatus ? TicketStatus : null}
          />
        </div>
        <div className="col-sm-2">
          <select
            className="form-select"
            onChange={(e) => {
              OnChangeCategory(e.target.value);
            }}
            value={IncidentCodeCategories}
          >
            <option value="">Select Incident Code Category</option>
            {
              IncidentCodeCategoryList.map((item, index) => (
                <option key={index} value={item.Id}> {item.CategoryName} </option>
              ))
            }
          </select>
        </div>

        <div className="col-sm-2">
          <ReactSelect
            options={IncidentCodeList}
            isMulti
            placeholder='Select Incident Code'
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            // defaultMenuIsOpen
            components={{
              Option
            }}
            onChange={(selected) => {
              setIncidentCodeCategories("");
              setIncidentCode(selected);
              setSearched(true)
              setSite("");
              setFilterTicketId("");
              setBaseStationId("");
              setSiteName("")
            }}
            allowSelectAll={true}
            value={IncidentCode ? IncidentCode : null}
          />
        </div>
        <div className="col-sm-4 row">
          <div className="col-sm-4">
            <select
              className="form-select"
              onChange={(e) => {
                setSiteStatusCode(e.target.value)
                setSearched(true)
                setSite("");
                setFilterTicketId("");
                setBaseStationId("");
                setSiteName("")
              }}
              value={SiteStatusCode}
            >
              <option value="">Select Site Status</option>
              {
                SiteStatusCodeList.map((item, index) => (
                  <option key={index} value={item.SiteStatusCode}> {item.SiteStatusValue} </option>
                ))
              }
            </select>
          </div>
          <div className="col-sm-4">
            <select
              className="form-select"
              onChange={(e) => {
                setMaintenance(e.target.value)
                setSearched(true)
              }}
              value={Maintenance}
            >
              <option value=""> Select Maintenance</option>
              {
                MaintenanceList.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))
              }
            </select>
          </div>
          <div className="col-sm-4">
            <select
              className="form-select"
              onChange={(e) => {
                setRegion(e.target.value)
                setSearched(true)
              }}
              value={Region}
            >
              <option value=""> Select Region</option>
              {
                RegionList.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))
              }
            </select>
          </div>
        </div>
      </div >
      <div className="row mt-2 mb-3">
        <div className="col-sm-7 d-flex align-items-center">
          Count : <b>{NoOfRecords} / {TotalTicketCount}</b>
        </div>
        <div className="col-sm-5 text-right d-flex justify-content-end align-items-center">
          <Switch
            className="login-switch m-0"
            onColor={"#136dfa"}
            height={25}
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() => {
              setAssignTickets(!AssignTickets)
              setSearched(true);
              setFilterTicketId("")
              setSite("");
              setBaseStationId("");
              setSiteName("")
            }}
            checked={AssignTickets}
          />&nbsp;
          <p className="m-0">Show Assigned</p>

          &nbsp;&nbsp;
          {(searched || TicketStatus || IncidentCode || SiteStatusCode || TicketCategory || searchInput || Site || Region) ?
            <button
              type="button"
              className="btn btn-primary"
              title="Reset Filters"
              onClick={clearSearchHandler}
            >
              <i className="bx bx-eraser search-icon"></i>
            </button>
            : null}

          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-primary"
            title="Export"
            onClick={handleExportTicket}
            style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px', float: 'right' }}
          >
            Export &nbsp;
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-primary"
            title="Create Manual Ticket"
            onClick={() => {
              setManualTicketFlag(true)
            }} style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px', float: 'right' }}
          >
            Create Manual Ticket &nbsp;
          </button>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div style={{ maxHeight: '55vh' }} className=" horizontal-scroll">
                <table className="table mb-0 table-striped ticket-table table-hover">
                  <thead>
                    <tr>
                      <th style={{ width: "70px" }}>#</th>
                      <th >Action</th>
                      <th>Created From</th>
                      <th>Fault Id</th>
                      <th>Ticket Category</th>
                      <th>Dynamic SLA</th>
                      <th>Ticket Id</th>
                      <th>Fresh Service Ticket Id</th>
                      <th>Basestation Id</th>
                      <th>Site Lessor Id</th>
                      <th>Incident Code</th>
                      <th>Ticket Status</th>
                      <th>Site Name</th>
                      <th>Site Status</th>
                      <th>Site Host</th>
                      <th>Maintenance</th>
                      <th>Region</th>
                      <th>Occurred Time</th>
                      <th>Generated Time</th>
                      <th>Power Confirmation Time</th>
                      <th>Incident Dispatched Time</th>
                      <th>Deferred Time</th>
                      <th>Resolved Time</th>
                      <th>Closed Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ModuleData.length > 0 ?
                      ModuleData.map((item, index) => {
                        return (
                          <tr key={index} onMouseDown={() => {
                            handleMouseDown(index)
                          }}
                            onMouseUp={() => {
                              handleMouseUp(index)
                            }}
                          >
                            <td>{((page - 1) * limit) + index + 1}</td>
                            <td className="action-buttons">
                              <>
                                <i style={{ color: (item.IsForwarded === 1) ? "#17A00E" : "#0d6efd" }} className="bx bx-mail-send" title="View Mail" onClick={() => setTicketSelected(item.TicketId)}></i>
                                {
                                  UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3 ? (
                                    <span> &nbsp; | &nbsp;</span>
                                  ) : (null)}

                                {UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3 ? (
                                  <span style={{ color: (item.AssignedUserId === 0) ? "#0d6efd" : "#17A00E", cursor: "pointer" }} onClick={() => handleAssignUser(CountryID, item.Id, item.AssignedUserId, item.ClientId, item.ClientCountryId)}>
                                    <i className="bx bx-user-plus" title={(item.AssignedUserId === 0) ? "Assign User" : "Change User"} ></i>
                                  </span>
                                ) : (null)}

                                {item.CreatedFrom === "MANUAL" && item.TicketStatus !== TICKET_STATUS_CLOSED ? <>
                                  <span> &nbsp; | &nbsp;</span>
                                  <i
                                    className="bx bx-x-circle text-danger"
                                    title="Close Manual Ticket" style={{ color: "red" }}
                                    onClick={() => {
                                      setCloseManualTicketId(item.TicketId)
                                    }}
                                  ></i>
                                </> : null}

                                {/*Defferred section  */}
                                {SIGFOX_DEFERRED_CONDITION.includes(item.TicketStatus) ?
                                  <>
                                    <span> &nbsp; | &nbsp;</span>
                                    <span onClick={() => {
                                      setDeferTicketDate(item);
                                    }} title="Defer">
                                      <svg className="svg-icon difer-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 39.384615C252.061538 39.384615 39.384615 252.061538 39.384615 512s212.676923 472.615385 472.615385 472.615385 472.615385-212.676923 472.615385-472.615385S771.938462 39.384615 512 39.384615z m0 827.076923c-194.953846 0-354.461538-159.507692-354.461538-354.461538S317.046154 157.538462 512 157.538462s354.461538 159.507692 354.461538 354.461538-159.507692 354.461538-354.461538 354.461538z m66.953846-350.523076c-5.907692-5.907692-7.876923-13.784615-7.876923-21.661539v-189.046154c0-15.753846-13.784615-29.538462-29.538461-29.538461h-59.076924c-15.753846 0-29.538462 13.784615-29.538461 29.538461v238.276923c0 7.876923 3.938462 15.753846 7.876923 21.661539l145.723077 145.723077c11.815385 11.815385 29.538462 11.815385 41.353846 0L689.230769 669.538462c11.815385-11.815385 11.815385-29.538462 0-41.353847l-110.276923-112.246153z" /></svg>
                                    </span>
                                  </>
                                  : ""}

                                <span> &nbsp; | &nbsp;</span>
                                <i className="bx bx-info-circle" type='solid' style={{ color: "blue" }} title="Deferred Details" onClick={() => {
                                  setDiferDetailsId(item.TicketId)
                                }}></i>

                                {SIGFOX_UNDEFERRED_CONDITION.includes(item.TicketStatus) ?
                                  <>
                                    <span> &nbsp; | &nbsp;</span>
                                    <i className="bx bx-revision" type='solid' style={{ color: "blue" }} title="Undefer" onClick={() => {
                                      setUndiferredTicketId(item.TicketId)
                                    }} ></i>
                                  </>
                                  : null
                                }

                                <span> &nbsp; | &nbsp;</span>
                                <i className="bx bx-notepad cursor-pointer" style={{ color: "blue" }} title="Ticket Logs" onClick={() => {
                                  setTicketLogId(item)
                                }} ></i>
                              </>
                            </td>

                            <td style={{ textAlign: "center", verticalAlign: "center" }}>{item.CreatedFrom ? item.CreatedFrom : "-"}</td>
                            <td className="action-buttons" style={{ textAlign: "center", verticalAlign: "center" }}>
                              {
                                //  When fault is not created
                                parseInt(item.FaultStatus) === 0 ?
                                  <>
                                    {item.CreateFaultFlag ?
                                      <button title="Create Fault" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 10px' }}
                                        onClick={() => {
                                          setCreateFaultTicketId(item.TicketId)
                                        }}
                                      >Create Fault</button>
                                      :
                                      "-"
                                    }
                                  </>
                                  :
                                  //  When fault is created
                                  <div className="d-flex align-items-center text-success" >
                                    {item.FaultId}
                                    <i className="bx bx-show" style={{ color: "blue" }} title="Fault Details" onClick={() => {
                                      setFaultDetailsId(item.FaultId)
                                    }}></i>
                                    {
                                      item.TicketStatus !== TICKET_STATUS_CLOSED ?
                                        <i
                                          className=" bx bx-note"
                                          style={{ color: "blue" }}
                                          title="Update Fault"
                                          onClick={() => {
                                            setUpdateFaultId(item.TicketId)
                                            setClickUpdateFaultId(item.FaultId)
                                          }}
                                        ></i> : ""
                                    }
                                  </div>
                              }
                            </td>
                            <td>{item.TicketCategory}</td>
                            <td>{item.DynamicSla}</td>
                            <td>{item.TicketId}</td>
                            <td>{item.FreshServiceTicketId}</td>
                            <td>{item.BasestationId}</td>
                            <td>{item.BasestationLessorId}</td>
                            <td>{item.IncidentCode}</td>
                            <td>{item.TicketStatus}</td>
                            <td>{item.SiteName}</td>
                            <td>{item.SiteStatus}</td>
                            <td>{item.SiteHost}</td>
                            <td >{item.SiteMaintenanceName}</td>
                            <td >{item.Region}</td>
                            <td>{(item.TicketOccuredTime > 0) ? <TimeZone timestamp={item.TicketOccuredTime} /> : null}</td>
                            <td>{(item.CreatedTimestamp > 0) ? <TimeZone timestamp={item.CreatedTimestamp} /> : null}</td>
                            <td>{(item.PowerConfirmationTimestamp > 0) ? <TimeZone timestamp={item.PowerConfirmationTimestamp} /> : null}</td>
                            <td>{(item.IncidentDispatchedTimestamp > 0) ? <TimeZone timestamp={item.IncidentDispatchedTimestamp} /> : null}</td>
                            <td>{(item.DiferredTimestamp > 0) ? <TimeZone timestamp={item.DiferredTimestamp} /> : null}</td>
                            <td>{(item.ResolvedTimestamp > 0) ? <TimeZone timestamp={item.ResolvedTimestamp} /> : null}</td>
                            <td>{(item.ClosedTimestamp > 0) ? <TimeZone timestamp={item.ClosedTimestamp} /> : null}</td>
                          </tr>
                        );
                      }) :
                      <tr>
                        <td
                          colSpan={24}
                          style={{ textAlign: "center" }}>
                          <b>No Record Found</b>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {
            NoOfRecords > 0 ?
              <Pagination
                records={ModuleData.length}
                link={"tickets-list/" + CountryID}
                SelectedPage={page}
                search={"&Search=" + searchInput + "&Status=" + TicketStatus + "&IncidentCode=" + IncidentCode + "&SiteStatus=" + SiteStatusCode + "&TicketCategory=" + TicketCategory + "&Maintenance=" + Maintenance + "&Region=" + Region}
                NoOfRecords={NoOfRecords}
                limit={limit}
              /> : null
          }
        </div>
      </div>
    </>
  );
}

export default TicketsList;