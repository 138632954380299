import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import Alert from "../../Components/Alert";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const ViewDynamicSLAConfig = () => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    var ClientId = JSON.parse(localStorage.getItem("ClientId"));

    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [Clients, setClients] = useState([]);
    const [ClientID, setClientID] = useState(ClientId || "-1");
    const [ModuleData, setModuleData] = useState([])
    const [FromError, setFromError] = useState([])
    const [ToError, setToError] = useState([])
    const [ApplyAllAlert, setApplyAllAlert] = useState("");

    const getClientValue = () => {
        if (ClientID) {
            for (var i = 0; i < Clients.length; i++) {
                if (Clients[i].Id === parseInt(ClientID)) {
                    return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
                }
            }
        }
    }
    const OnchangeClient = (selected) => {
        setClientID(selected.Id)
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess("");
        }
        return () => { };
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2) {
            setLoader(true);
            axios
                .get("clients")
                .then((response) => {
                    var TempData = response.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        if (i === 0 && (UserGroup.Id === 1 || UserGroup.Id === 2)) {
                            var default_obj = {};
                            default_obj["Id"] = -1;
                            default_obj["Name"] = "Default Setting";
                            default_obj["label"] = "Default Setting";
                            default_obj["value"] = -1;
                            default_obj["image"] = DefaultLogo;
                            arr.push(default_obj)
                        }

                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClients(arr)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);

    useEffect(() => {
        if (ClientID) {
            setLoader(true)
            axios.get("list_dynamic_sla?ClientId=" + ClientID)
                .then((response) => {
                    setModuleData(response.data.data);
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }, [ClientID])

    const handleRangeFrom = (index, e) => {
        var input = e.target.value;
        var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
        ModuleDataTemp[index].RangeFrom = input;
        setModuleData(ModuleDataTemp);
    }

    const handleRangeTo = (index, e) => {
        var input = e.target.value;
        var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
        ModuleDataTemp[index].RangeTo = input;
        setModuleData(ModuleDataTemp);
    }

    const validate = () => {
        var flag = true;
        var FromErrorTemp = []
        var ToErrorTemp = []

        for (var i = 0; i < ModuleData.length; i++) {
            ModuleData[i].RangeFrom = (ModuleData[i].RangeFrom !== null && ModuleData[i].RangeFrom !== '') ? parseInt(ModuleData[i].RangeFrom) : ''
            ModuleData[i].RangeTo = (ModuleData[i].RangeTo !== null && ModuleData[i].RangeTo !== '') ? parseInt(ModuleData[i].RangeTo) : ''

            if (ModuleData[i].RangeFrom === "") {
                flag = false;
                FromErrorTemp[i] = "This field is required"
            }

            if (ModuleData[i].RangeTo === "" && ModuleData.length - 1 !== i) {
                flag = false;
                ToErrorTemp[i] = "This field is required"
            }

            if (ModuleData[i].RangeFrom !== "" && ModuleData[i].RangeTo !== "" && (ModuleData[i].RangeFrom > ModuleData[i].RangeTo)) {
                flag = false;
                ToErrorTemp[i] = "This field should be greater than from range"
            }

            if (i !== 0 && ModuleData[i].RangeFrom !== "" && ModuleData[i - 1].RangeTo !== "") {
                if (ModuleData[i].RangeFrom <= parseInt(ModuleData[i - 1].RangeTo)) {
                    flag = false;
                    FromErrorTemp[i] = "This field should not be falls between range of " + ModuleData[i - 1].Name
                }
            }
        }
        setFromError(FromErrorTemp)
        setToError(ToErrorTemp)

        return flag;
    }

    const saveData = (type) => {
        const flag = validate();
        if (flag) {
            setLoader(true)
            const params = {
                ClientId: ClientID,
                SubmitType: type,
                DynamicSLAConfig: ModuleData
            }
            axios.put("update_dynamic_sla", params)
                .then((response) => {
                    setLoader(false);
                    setSuccess(response.data.status.message)
                    setModuleData(response.data.data)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }

    const AlertResponse = (Result) => {
        if (Result) {
            saveData("SaveToAll")
        }
        setApplyAllAlert('')
    }

    return (
        <div>
            {ApplyAllAlert ? <Alert message={ApplyAllAlert} AlertResponse={AlertResponse} /> : null}
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0">Dynamic SLA Config</h6>
                </div>
            </div>
            <hr />

            <form>
                <div className="card">
                    <div className="card-body horizontal-scroll">
                        <div className="row">
                            <div className="col-md-6">
                                {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                                    <>
                                        {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                                    </>
                                ) : null}
                            </div>

                            <div className="col-md-6">
                                <button
                                    type="button"
                                    className="btn btn-primary px-5"
                                    style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                                    onClick={() => saveData("Save")}
                                >
                                    Save
                                </button>

                                {((UserGroup.Id === 1 || UserGroup.Id === 2) && ClientID === '-1') ?
                                    <button
                                        type="button"
                                        className="btn btn-primary px-5"
                                        style={{ marginTop: "20px", float: "right" }}
                                        onClick={() => setApplyAllAlert('Are you sure you want to apply all?')}
                                    >
                                        Apply to all
                                    </button>
                                    : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <table
                                    className="table mb-0 table-striped text-center"
                                    style={{ verticalAlign: "middle" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th style={{ textAlign: "left" }}>Name</th>
                                            <th style={{ textAlign: "left" }}>Range From</th>
                                            <th style={{ textAlign: "left" }}>Range To</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ModuleData.length > 0 ?
                                            ModuleData.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td style={{ textAlign: "left" }}>
                                                            {item.Name}
                                                        </td>
                                                        <td style={{ textAlign: "left" }}>
                                                            <input type="number" className="form-control"
                                                                value={item.RangeFrom !== null ? item.RangeFrom : ""}
                                                                min="0"
                                                                onChange={(e) => handleRangeFrom(index, e)}
                                                            />
                                                            <span className="text-danger" style={{ color: "red" }}>{FromError[index] ? FromError[index] : null}</span>
                                                        </td>
                                                        <td style={{ textAlign: "left" }}>
                                                            <input type="number" className="form-control"
                                                                value={item.RangeTo !== null ? item.RangeTo : ""}
                                                                min="0"
                                                                onChange={(e) => handleRangeTo(index, e)}
                                                            />
                                                            <span className="text-danger" style={{ color: "red" }}>{ToError[index] ? ToError[index] : null}</span>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    style={{ textAlign: "center" }}>
                                                    <b>No Record Found</b>
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <button
                                    type="button"
                                    className="btn btn-primary px-5"
                                    style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                                    onClick={() => saveData("Save")}
                                >
                                    Save
                                </button>

                                {((UserGroup.Id === 1 || UserGroup.Id === 2) && ClientID === '-1') ?
                                    <button
                                        type="button"
                                        className="btn btn-primary px-5"
                                        style={{ marginTop: "20px", float: "right" }}
                                        onClick={() => saveData("SaveToAll")}
                                    >
                                        Apply to all
                                    </button>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ViewDynamicSLAConfig