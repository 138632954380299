import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import TokenReducer from "./features/TokenSlice";
import TimeZoneReducer from './features/TimeZoneSlice';

const store = configureStore({
  reducer: {
    token: TokenReducer,
    TimeZone: TimeZoneReducer
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    {/* live server OR local */}
    <BrowserRouter basename="/">
      {/* swtprime */}
      {/* <BrowserRouter basename="/foxilogic">   */}
      <App />
    </BrowserRouter>
  </Provider>
);
