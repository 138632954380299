import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div className="custom-select-option">
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                &nbsp;
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const AddEdit = () => {
    const { ClientId } = useParams();
    const { CountryId } = useParams();
    const { Id } = useParams();
    const navigate = useNavigate();


    const [loader, setLoader] = useState(false);

    const [BasestationList, setBasestationList] = useState([]);
    const [BasestationId, setBasestationId] = useState([]);
    const [BasestationIdError, setBasestationIdError] = useState([]);

    const [EmailTxt, setEmailTxt] = useState([]);
    const [Email, setEmail] = useState([]);
    const [EmailError, setEmailError] = useState("");


    useEffect(() => {
        if (ClientId && CountryId) {
            setLoader(true);
            axios.get("get_all_countrywise_basestation?CountryId=" + CountryId)
                .then((response) => {
                    setBasestationList(response.data.data);
                    setLoader(false);

                })
                .catch((error) => {
                    setLoader(false);

                })

        }

    }, [ClientId, CountryId])

    useEffect(() => {
        if (Id && ClientId && CountryId && BasestationList.length > 0) {
            setLoader(true);
            axios.get("get_bs_specific_config?Id=" + Id + "&ClientId=" + ClientId + "&CountryId=" + CountryId)
                .then((response) => {
                    setEmail(response.data.data[0].Email)
                    let BasestationIdTemp = response.data.data[0].BasestationId;
                    var TempArr = [];

                    for (let i = 0; i < BasestationIdTemp.length; i++) {
                        for (let index = 0; index < BasestationList.length; index++) {
                            if (BasestationList[index].value === BasestationIdTemp[i]) {
                                TempArr.push(BasestationList[index]);
                            }
                        }
                    }

                    setBasestationId(TempArr);
                    setLoader(false);

                })
                .catch((error) => {
                    setLoader(false);

                })
        }

    }, [Id, ClientId, BasestationList, CountryId])
    
    const handleValidation = () => {
        setBasestationIdError("");
        setEmailError("")
        var flag = true;

        if (BasestationId.length === 0) {
            setBasestationIdError("Please select any one base station");
            flag = false;
        }

        if (Email.length === 0) {
            setEmailError("Email field is required");
            flag = false;
        }

        return flag;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag && ClientId && CountryId) {

            var BasestationIdArr = [];
            for (let index = 0; index < BasestationId.length; index++) {
                BasestationIdArr.push(BasestationId[index].value)
            }

            const payload = {
                "ClientId": ClientId,
                "CountryId": CountryId,
                "BasestationId": BasestationIdArr.toString(),
                "Email": Email.toString()
            };

            if (!Id) {
                axios
                    .post("add_bs_specific_config", payload)
                    .then((response) => {
                        navigate("/basestation_specific_config?ClientId=" + ClientId + "&CountryId=" + CountryId, {
                            state: response.data.status.message

                        });
                        setLoader(false);
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            } else {
                axios
                    .put("update_bs_specific_config/" + Id, payload)
                    .then((response) => {
                        navigate("/basestation_specific_config?ClientId=" + ClientId + "&CountryId=" + CountryId, {
                            state: response.data.status.message
                        });
                        setLoader(false);
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            }
        }
    };



    const HandleEmail = () => {
        if (EmailTxt === "") {
            setEmailError("Please enter email")
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(EmailTxt)) {
                setEmailError("Please enter valid email address")
            } else {
                var TempEmail = [...Email];
                TempEmail.push(EmailTxt);
                setEmail(TempEmail);
                setEmailTxt("");
            }
        }

    }

    const HandleRemoveEmail = (index) => {
        var TempEmail = [...Email];
        TempEmail.splice(index, 1);
        setEmail(TempEmail);
    }

    return (
        <>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-xl-10 mx-auto">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="card-title">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="mb-0  text-primary">
                                            {Id ? "Edit Base Station Specific" : "Add Base Station Specific"}

                                        </h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                navigate("/basestation_specific_config?ClientId=" + ClientId + "&CountryId=" + CountryId);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={onSubmit}>
                                <div className="col-md-6">
                                    <label className="form-label">Base Stations</label>
                                    <ReactSelect
                                        options={BasestationList}
                                        isMulti
                                        placeholder='Select Base Stations'
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        // defaultMenuIsOpen
                                        components={{
                                            Option
                                        }}
                                        onChange={(selected) => {
                                            setBasestationId(selected);
                                            // setIncidentCodeCategories("");
                                            // setIncidentCode(selected);
                                            // setSearched(true)
                                            // setSite("");
                                            // setFilterTicketId("");
                                            // setBaseStationId("");
                                            // setSiteName("")
                                        }}
                                        allowSelectAll={true}
                                        value={BasestationId ? BasestationId : null}
                                    />

                                    <span className="text-danger">{BasestationIdError}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label ">Emails</label>
                                    <div className="row">
                                        <div className="col-sm-11">
                                            <input
                                                type="text"
                                                className="form-control mb-2 col-6"
                                                name="threshold"
                                                placeholder="Please Enter Emails"
                                                value={EmailTxt}
                                                onChange={(e) => {
                                                    setEmailTxt(e.target.value)
                                                    setEmailError("")
                                                }}
                                            />
                                        </div>

                                        <div className="col-sm-1" onClick={() => {
                                            HandleEmail();

                                        }}>
                                            <i className=" bx bx-plus cursor-pointer" style={{
                                                fontSize: "25px",
                                                color: "blue",
                                            }}></i>
                                        </div>
                                    </div>

                                    <span className="text-danger">{EmailError}</span>
                                    <div>
                                        <span> Added Emails : </span>

                                        {
                                            Email.length > 0 ?
                                                Email.map((item, i) => {
                                                    return (

                                                        <span style={{ marginRight: "12px" }}>{item}<i
                                                            className="bx bx-x-circle text-danger mx-1"
                                                            title="Remove Email" style={{ color: "red", fontSize: "15px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                HandleRemoveEmail(i);
                                                            }}
                                                        ></i>
                                                            {(i !== (Email.length - 1)) ? "," : ""}
                                                        </span>
                                                    );
                                                })
                                                : null
                                        }
                                    </div>

                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary px-5">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddEdit;
