import React, { useState } from "react";

import axios from "../axios";
import BGImage from "../Images/login3.jpg";
import ForgotImage from "../Images/forgot-2.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


const ForgetPassword = () => {
    const [Email, setEmail] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [LoginLoader, setLoginLoader] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setEmailError("");
        var flag = true;

        if (Email === "") {
            setEmailError("Please enter email")
            flag = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(Email)) {
                setEmailError("Please enter valid email address")
                flag = false;
            }
        }

        if (flag) {
            setLoginLoader(true)
            axios
                .post("send_reset_password_link", {
                    email: Email
                })
                .then((response) => {
                    toast.success(response.data.status.message)
                    setEmail("")
                    setLoginLoader(false)
                    navigate("/opt_varification", {
                        state: {
                            Email: Email,
                            Identity:"ForgetPasswordOtp"
                        }
                    });
                })
                .catch((error) => {
                    setLoginLoader(false)

                });
        }
    }

    return (
        <>
            <ToastContainer theme="colored" />
            <div
                className="bg-lock-screen"
                style={{ height: "100vh", backgroundImage: "url(" + BGImage + ")" }}
            >
                <div className="wrapper">
                    <div className="authentication-lock-screen d-flex align-items-center justify-content-center">
                        <div
                            className="card"
                            style={{
                                backgroundColor: "rgb(0,0,0,0.6)",
                                borderRadius: "1.25rem",
                            }}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className="card-body p-md-5 ">
                                    {/* <div className="text-center">
                                    <img src={ForgotImage} width="120" alt="" />
                                </div> */}
                                    <div className="text-center">
                                        <img src={ForgotImage} className="" width="120" alt="" />
                                    </div>
                                    <h4 className="text-white m-3 text-center">Forgot Password?</h4>
                                    <p className="text-white m-0 text-center">Enter your registered email ID to reset the password</p>
                                    <div className="my-4">
                                        <label className="text-white m-1 form-label ">Email id</label>
                                        <input
                                            style={{
                                                backgroundColor: "transparent",
                                                color: "#ffffff",
                                                borderRadius: "1.25rem",
                                            }}
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            value={Email}
                                        />
                                        <p className="text-danger mt-1">{EmailError}</p>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary btn-md">{LoginLoader ? <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span> : null}Send</button>

                                        <Link to={"/login"} className="btn btn-light btn-md">
                                            <i className='bx bx-arrow-back me-1'></i>Back to Login
                                        </Link>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword