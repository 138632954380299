import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

const Pagination = (props) => {
    const navigate = useNavigate()
    const [limit, setLimit] = useState(props.limit)
    const [Pages, setPages] = useState([])
    const [Call, setCall] = useState(false)

    useEffect(() => {
        var temp = []
        var i = 0;
        do {
            temp.push(++i)
        } while (i < (props.NoOfRecords / limit));
        setPages(temp)
        if (Call) {
            navigate('/' + props.link + '?PageNo=1&Limit=' + limit + '&' + props.search)
        }
    }, [limit, props.link, props.search, props.NoOfRecords, Call, navigate])

    return (
        <>
            {

                <div className="row mt-2 align-items-center">
                    <div className="col-md-1">
                        <select className="form-select"
                            value={limit}
                            onChange={(e) => {
                                setLimit(e.target.value)
                                setCall(true)
                            }}>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            {/* <option value="100">100</option> */}
                        </select>
                    </div>

                    {
                        Pages.length > 1 ?
                            <div className="col-md-11">
                                <div>
                                    <ul className='paginate-ul'>
                                        {
                                            props.SelectedPage && parseInt(props.SelectedPage) !== 1 ? <li>
                                                <Link
                                                    className='paginate-li btn btn-primary'
                                                    to={{ pathname: '/' + props.link, search: 'PageNo=1&Limit=' + limit + '&' + props.search }}>«
                                                </Link>

                                                <Link
                                                    className='paginate-li btn btn-primary'
                                                    to={{ pathname: '/' + props.link, search: 'PageNo=' + (parseInt(props.SelectedPage) - 1) + '&Limit=' + limit + '&' + props.search }}>&#60;</Link>
                                            </li> : null
                                        }

                                        <span
                                            className='paginate-number btn btn-primary'
                                            // to={{ pathname: '/' + props.link, search: 'PageNo=' + (parseInt(props.SelectedPage) + 1) + '&Limit=' + limit + '&' + props.search }}

                                            style={{
                                                background: 'blue',
                                                color: '#ffffff',
                                                border: 'none'
                                            }}>{parseInt(props.SelectedPage) + " / " + Pages.length}</span>

                                        {
                                            props.SelectedPage && parseInt(props.SelectedPage) !== (Pages[Pages.length - 1]) ? <li>
                                                <Link
                                                    className='paginate-li btn btn-primary'
                                                    to={{ pathname: '/' + props.link, search: 'PageNo=' + (parseInt(props.SelectedPage) + 1) + '&Limit=' + limit + '&' + props.search }}>&#62;</Link>
                                                <Link
                                                    className='paginate-li btn btn-primary'
                                                    to={{ pathname: '/' + props.link, search: 'PageNo=' + (Pages[Pages.length - 1]) + '&Limit=' + limit + '&' + props.search }} >»</Link>
                                            </li> : null
                                        }
                                    </ul>
                                </div>
                            </div> : null
                    }

                </div>
            }
        </>
    )
}

export default Pagination