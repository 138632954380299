
import { components } from 'react-select';
import ReactSelect from 'react-select'

// RESTRICT SITE HOSTS
export const RESTRICTSITEHOST = ["Electra", "Iclix", "Tech5", "Jenny", "Herotel", "LCOM", "TrueCom", "Bluedog"];
export const RESTRICTSITENAMEPREFIX = "KNP_";

// OUTAGE MANAGEMENT

export const MINDATE = "2023-11-09";

var today = new Date();
var day = today.getDate();
var month = today.getMonth() + 1;
var year = today.getFullYear();

// This arrangement can be altered based on how we want the date's format to appear.
if (day < 10) {
  day = "0" + day;
}

export const MAXDATE = `${year}-${month}-${day}`;

export const REPORTTYPE = ["Open Report", "Closed Report", "OverAll Report"];

// All ticket status
export const TICKET_STATUS_OPEN = 'Open';
export const TICKET_STATUS_POWER_CONFIRMATION = 'Power Confirmation';
export const TICKET_STATUS_INCIDENT_DISPATCHED = 'Incident Dispatched';
export const TICKET_STATUS_DEFERRED = 'Deferred';
export const TICKET_STATUS_RESOLVED = 'Resolved';
export const TICKET_STATUS_CLOSED = 'Closed';

export const OPENGROUPTICKETSTATUS = [{
  "value": TICKET_STATUS_OPEN,
  "label": TICKET_STATUS_OPEN
}, {
  "value": TICKET_STATUS_POWER_CONFIRMATION,
  "label": TICKET_STATUS_POWER_CONFIRMATION
}, {
  "value": TICKET_STATUS_INCIDENT_DISPATCHED,
  "label": TICKET_STATUS_INCIDENT_DISPATCHED
}, {
  "value": TICKET_STATUS_DEFERRED,
  "label": TICKET_STATUS_DEFERRED
}];

export const GENERATEDBYLISTOFTICKET = ["SYSTEM", "MANUAL"];
export const OPENGROUPTICKETSTATUSARR = [TICKET_STATUS_OPEN, TICKET_STATUS_POWER_CONFIRMATION, TICKET_STATUS_INCIDENT_DISPATCHED, TICKET_STATUS_DEFERRED];
export const CLOSEDGROUPTICKETSTATUSARR = [TICKET_STATUS_RESOLVED, TICKET_STATUS_CLOSED];

//Conditions for able to do something on ticket status


export const SIGFOX_DEFERRED_CONDITION = [TICKET_STATUS_OPEN, TICKET_STATUS_POWER_CONFIRMATION, TICKET_STATUS_INCIDENT_DISPATCHED];
export const SIGFOX_UNDEFERRED_CONDITION = [TICKET_STATUS_DEFERRED];

export const CABISENCE_INCIDENT_DISPATCHED_CONDITION = [TICKET_STATUS_OPEN, TICKET_STATUS_INCIDENT_DISPATCHED];
export const CABISENCE_DEFERRED_CONDITION = [TICKET_STATUS_OPEN, TICKET_STATUS_INCIDENT_DISPATCHED];
export const CABISENCE_UNDEFERRED_CONDITION = [TICKET_STATUS_DEFERRED];

export const MERAKI_INCIDENT_DISPATCHED_CONDITION = [TICKET_STATUS_OPEN, TICKET_STATUS_INCIDENT_DISPATCHED];
export const MERAKI_DEFERRED_CONDITION = [TICKET_STATUS_OPEN, TICKET_STATUS_INCIDENT_DISPATCHED];
export const MERAKI_UNDEFERRED_CONDITION = [TICKET_STATUS_DEFERRED];

export const DATEFORMAT = (Date) => {
  const year = Date.getFullYear();
  const month = ('0' + (Date.getMonth() + 1)).slice(-2);
  const day = ('0' + Date.getDate()).slice(-2);
  const hours = ('0' + Date.getHours()).slice(-2);
  const minutes = ('0' + Date.getMinutes()).slice(-2);
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

// Custom client dropdown for showing image with lable

export const CustomStyles = {
  option: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
};

//Client dropdown 
export const IconOption = (props) => (
  <components.Option {...props}>
    <img src={props.data.image} style={{ height: '25px', width: '25px', borderRadius: '50%', marginRight: '7px' }} alt='Client Logo' />
    {props.data.label}
  </components.Option>
);

export const IconSingleValue = (props) => (
  <components.SingleValue {...props}>
    <img src={props.data.image} alt='Client Logo' style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }} />
    {props.data.label}
  </components.SingleValue>
);


export const CUSTOM_CLIENT_SELECT = ({ ClientListOptions, Value, OnchangeClient }) => {
  return (
    <ReactSelect
      styles={CustomStyles}
      // defaultMenuIsOpen
      placeholder='Select client'
      components={{
        SingleValue: IconSingleValue,
        Option: IconOption
      }}
      options={ClientListOptions}
      onChange={(selected) => {
        OnchangeClient(selected)

      }}
      value={Value}
    />
  );
};


export const AlPHA_NUMERIC_KEY = () => {
  const length = 30; // Length of the random string
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};


