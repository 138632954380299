import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Loader from "../../Components/Loader";
import axios from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";

const ViewDynamicSLA = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [ModuleData, setModuleData] = useState([])
  const [search, setSearch] = useState("")
  const [ServiceCall, setServiceCall] = useState(true)

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => { };
  }, [error]);

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      navigate("/manage_dynamic_sla", {
        state: null,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (ServiceCall) {
      setLoader(true)
      axios.get("list_master_dynamic_sla?Search=" + search)
        .then((response) => {
          setModuleData(response.data.data)
          setLoader(false)
          setServiceCall(false)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }, [ServiceCall, search])

  const handleSubmit = () => {
    setServiceCall(true)
  }

  const handleClearSearch = () => {
    setSearch("")
    setServiceCall(true)
  }


  return (
    <div>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0 "> Dynamic List</h6>
        </div>
        <div className="col-md-4 text-right">
          <Link to="/add_dynamic" className="btn btn-primary">
            <i className="bx bx-plus me-1 font-22"></i>Add Dynamic SLA
          </Link>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control mb-3"
            id="search"
            name="search"
            placeholder="Search Here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <button
            type="submit"
            className="btn btn-primary mb-3"
            title="Search"
            onClick={handleSubmit}
          >
            <i className="bx bx-search search-icon"></i>
          </button>
          &nbsp;
          {search ?
            <button
              type="button"
              className="btn btn-primary mb-3"
              title="Clear"
              onClick={handleClearSearch}
            >
              <i className="bx bx-eraser search-icon"></i>
            </button>
            : null}
        </div>
      </div>

      <div className="card">
        <div className="card-body horizontal-scroll">
          <div className="row g-3" style={{ marginBottom: "12px" }}>
          </div>

          <table className="table mb-0 table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                {/* <th>Range From</th>
                <th>Range To</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {ModuleData.length > 0 ?
                ModuleData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.Name}</td>
                      {/* <td>{item.RangeFrom}</td>
                    <td>{item.RangeTo}</td> */}

                      <td className="action-buttons">
                        <Link to={"/add_dynamic/" + item.Id}>
                          <i className="bx bx-pencil" title="Edit"></i>
                        </Link>
                      </td>
                    </tr>
                  );
                }) :
                <tr>
                  <td
                    colSpan={3}
                    style={{ textAlign: "center" }}>
                    <b>No Record Found</b>
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}

export default ViewDynamicSLA