import React, { useState, useEffect, useCallback } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
// import EmailBodyView from "./EmailBodyView";
import { DefaultEditor } from 'react-simple-wysiwyg';
import { useSelector } from 'react-redux'

function SendEmail() {
  // const [data, setData] = useState([]);
  const { id } = useParams();  //Ticket Id 
  const [loader, setLoader] = useState(false);
  const [To, setTo] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [ClientId, setClientId] = useState("");
  const [CountryID, setCountryID] = useState("");

  const [ToError, setToError] = useState("");
  const [SubjectError, setSubjectError] = useState("");
  const [MessageError, setMessageError] = useState("");

  const navigate = useNavigate();

  const handleValidation = () => {
    setToError("");
    setSubjectError("");
    setMessageError("");

    var flag = true;

    var result = To.replace(/\s/g, "").split(/,|;/);
    for (var i = 0; i < result.length; i++) {
      if (To === "") {
        setToError("To Email is required");
        flag = false;
      } else if (!(result[i].match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ))) {
        setToError("To Email is invalid");
        flag = false;
      }
    }

    if (Subject === "") {
      setSubjectError("Subject is required");
      flag = false;
    }


    if (Message === "") {
      setMessageError("Message is required");
      flag = false;
    }

    return flag;
  };

  const getTimeZoneRedux = useSelector((state) => state.TimeZone.value);

  const changeTimeZone = useCallback((getTimeStamp) => {
    const obj = { dateStyle: "medium", timeStyle: "medium" }

    if (localStorage.getItem("TimeZone") !== null) {
      obj['timeZone'] = JSON.parse(localStorage.getItem("TimeZone")).value
        || JSON.parse(getTimeZoneRedux.timezone).value
    }
    return new Date(getTimeStamp * 1000).toLocaleString("en-US", obj)
  }, [getTimeZoneRedux.timezone])

  const convert_time_to_timeStamp = useCallback(  
    (ebody) => {
      if (ebody.trim() !== '') {
        const i = ebody.indexOf("##")
        var timestamp = ebody.slice(i, i + 12)
        var timestampcopy = timestamp
        timestamp = timestamp.replace('##', '')
        timestamp = changeTimeZone(timestamp)
        ebody = ebody.replace(timestampcopy, timestamp)
        return ebody.indexOf("##") > -1 ? convert_time_to_timeStamp(ebody) : ebody
      } else {
        return ''
      }
    },
    [changeTimeZone],
  )

  useEffect(() => {
    setLoader(true);
    axios
      .get("get_cabisense_ticket_details?TicketId=" + id)
      .then((response) => {
        if (response.data.data[0]['EmailSubject'] !== null) {
          setSubject(response.data.data[0]['EmailSubject'])
        }
        if (response.data.data[0]['EmailBody'] !== null) {
          setMessage(convert_time_to_timeStamp(response.data.data[0]['EmailBody']))
        }
        setClientId(response.data.data[0]['ClientId'])
        setCountryID(response.data.data[0]['ClientCountryId'])

        if (response.data.data[0]['EmailSubject'] !== null) {
          document.getElementById("input1").disabled = true
        } else {
          document.getElementById("input1").disabled = false
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, [navigate, id, convert_time_to_timeStamp]);

  const formSubmit = (e) => {
    e.preventDefault();

    const flag = handleValidation();
    if (flag) {
      const payload = {
        ClientId: ClientId,
        to: To,
        Subject: Subject,
        EmailBody: Message,
        TicketId: id
      };
      setLoader(true);
      axios
        .post("send_cabisense_ticket_email", payload)
        .then((response) => {
          navigate("/cabisense-tickets-list/" + CountryID, {
            state: response.data.status.message,
          });
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }

  return (
    <>
      {/* <ToastContainer theme="colored" /> */}
      {loader ? <Loader /> : null}
      <div>
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="card">
              <div className="card-body p-5">
                <div className="card-title">
                  <div className="row">
                    <div className="col-md-8">
                      <h5 className="mb-0 text-primary">
                        <i className="bx bx-user-circle me-1 font-22"></i>Send Email <span>(Ticket Id : {id})</span>
                      </h5>
                    </div>
                    <div className="col-md-4 text-right">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
                <form className="row g-3" onSubmit={formSubmit}>

                  <div className="col-md-12">
                    <div className="row">

                      <div className="col-md-2">
                        <label htmlFor="inputEmailTo" className="form-label">To</label>
                      </div>
                      <div className="col-md-6">

                        <input type="hidden" className="form-control" onChange={(e) => setClientId(e.target.value)} value={ClientId} />

                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setTo(e.target.value)}
                          value={To}
                        />
                        <span className="text-danger">{ToError}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">

                      <div className="col-md-2">
                        <label htmlFor="inputEmailSubject" className="form-label">
                          Subject
                        </label>

                      </div>
                      <div className="col-md-6">

                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setSubject(e.target.value)}
                          value={Subject}
                          id="input1" />
                        <span className="text-danger">{SubjectError}</span>
                      </div>

                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">

                      <div className="col-md-2">
                        <label htmlFor="inputEmailMessage" className="form-label">
                          Message
                        </label>
                      </div>
                      <div className="col-md-6" style={{ maxHeight: '500px', overflow: 'auto' }}>

                        {/* <textarea className="form-control" onChange={(e) => setMessage(e.target.value)}
                        value={Message} style = {{height: 150}}/> */}
                        <DefaultEditor onChange={(e) => setMessage(e.target.value)} value={Message} />

                        <span className="text-danger">{MessageError}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-2">
                      </div>
                      <div className="col-md-6">
                        <button type="submit" className="btn btn-primary px-5">
                          Send
                        </button>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default SendEmail;