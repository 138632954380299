import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import axios from "../axios";
import BGImage from "../Images/login3.jpg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha'


const SignUp = () => {
    // const { SingleValue, Option } = components;
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const RecaptchaRef = useRef()

    const [Name, setName] = useState("");
    const [Surname, setSurname] = useState("");
    const [Username, setUsername] = useState("");
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [NameError, setNameError] = useState("");
    const [SurnameError, setSurnameError] = useState("");
    const [UsernameError, setUsernameError] = useState("");
    const [PasswordError, setPasswordError] = useState("");
    const [ConfirmPasswordError, setConfirmPasswordError] = useState("");
    const [Email, setEmail] = useState("")
    const [EmailError, setEmailError] = useState("");
    const [ClientCode, setClientCode] = useState("")
    const [ClientCodeError, setClientCodeError] = useState("");

    const [Logo, setLogo] = useState("")
    // const [LogoImage, setLogoImage] = useState("")
    const [LogoError, setLogoError] = useState("");
    const [CaptchaError, setCaptchaError] = useState("");

    const onchangeLogo = (event) => {
        setLogo("")
        setLogoError("")
        // setLogoImage("")
        var file_name_arr = event.target.files[0].name.split(".");
        var img_height;
        var img_width;

        var _URL = window.URL || window.webkitURL;

        var file, img;
        file = event.target.files[0]
        img = new Image();
        img.onload = function () {
            img_width = this.width;
            img_height = this.height;

            if (img_width !== img_height) {
                setLogoError("Please select square image.")
                setLogo("");
                return false;
            } else {
                if (event.target.files[0].size / 1024 > 500) {
                    setLogoError("File size must not be greater than to 500Kb.")
                    return
                } else if (!(file_name_arr[file_name_arr.length - 1] === "png" || file_name_arr[file_name_arr.length - 1] === "jpg" || file_name_arr[file_name_arr.length - 1] === "jpeg")) {
                    setLogoError("Please select png or jpg image.")
                } else {
                    setLogo(event.target.files[0]);
                    // setLogoImage(URL.createObjectURL(event.target.files[0]))
                }
            }
        };

        img.src = _URL.createObjectURL(file);

    }
    const handleValidation = () => {
        setNameError("");
        setSurnameError("");
        setUsernameError("");
        setPasswordError("");
        setConfirmPasswordError("");
        setLogoError("")
        setEmailError("")
        setClientCodeError("")
        setCaptchaError("")

        var flag = true;
        if (Name === "") {
            setNameError("Name is required");
            flag = false;
        }
        if (Surname === "") {
            setSurnameError("Surname is required");
            flag = false;
        }
        if (Username === "") {
            setUsernameError("Username is required");
            flag = false;
        }
        if (ClientCode === "") {
            setClientCodeError("Country code is requird");
            flag = false;
        }

        if (Email === "") { 
            setEmailError("Please enter email")
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(Email)) {
                setEmailError("Please enter valid email address")
            }
        }

        if (Password === "") {
            setPasswordError("Password is required");
            flag = false;
        }

        if (ConfirmPassword === "") {
            setConfirmPasswordError("Confirm password is required");
            flag = false;
        }

        if (ConfirmPassword !== Password) {
            setConfirmPasswordError(
                "Your password and confirmation password do not match."
            );
            flag = false;
        }

        const CaptchaValue = RecaptchaRef.current.getValue()
        if (!CaptchaValue) {
            setCaptchaError('Please verify the CAPTCHA!')
        }

        return flag;
    };

    const formSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            const payload = new FormData();
            payload.append("Name", Name);
            payload.append("Surname", Surname);
            payload.append("Username", Username);
            payload.append("Email", Email);
            payload.append("Password", Password);
            payload.append("ConfirmPassword", ConfirmPassword);
            payload.append("ClientCode", ClientCode);

            if (typeof Logo !== "string") {
                payload.append("ProfileImage", Logo);
            }

            setLoader(true);
            axios
                .post("signup", payload)
                .then((response) => {
                    toast.success(response.data.status.message);
                    navigate("/opt_varification", {
                        state: {
                            Email: Email,
                            Identity: "SignupOtp"
                        }
                    });
                    setLoader(false);
                })
                .catch((response) => {
                    setLoader(false);
                });
        }
    };

    return (
        <>
            <ToastContainer theme="colored" />
            <div
                className="bg-lock-screen"
                style={{ height: "100vh", backgroundImage: "url(" + BGImage + ")" }}
            >
                <div className="wrapper">
                    <div className="authentication-lock-screen d-flex align-items-center justify-content-center">
                        <div
                            className="card signup-card"
                        >

                            <div className="card-body p-md-5 ">
                                <form className="row justify-content-center g-3" onSubmit={formSubmit}>

                                    <div className="text-center text-white ">
                                        <h3 className="text-white ">Sign Up</h3>
                                        <p style={{ fontSize: "17px" }}>Already have an account? <Link to={"/login"}> sign in here..</Link>
                                        </p>
                                    </div>

                                    <div className="row justify-content-center mb-2">
                                        <div className="col-sm-6 my-2">
                                            <p className="text-white m-0">
                                                Name
                                            </p>
                                            <input
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: "#ffffff",
                                                    borderRadius: "1.25rem",
                                                }}
                                                type="text"
                                                className="form-control"
                                                name="username"
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                                value={Name}
                                                autoComplete="off"
                                            />
                                            <p className="text-danger text-left mb-0">{NameError}</p>
                                        </div>
                                        <div className="col-sm-6 my-2">
                                            <p className="text-white m-0" >
                                                Surname
                                            </p>
                                            <input
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: "#ffffff",
                                                    borderRadius: "1.25rem",
                                                }}
                                                type="text"
                                                className="form-control"
                                                name="username"
                                                onChange={(e) => {
                                                    setSurname(e.target.value);
                                                }}
                                                value={Surname}
                                                autoComplete="off"

                                            />
                                            <p className="text-danger text-left mb-0">{SurnameError}</p>

                                        </div>

                                        <div className="col-sm-6 my-2">
                                            <p className="text-white m-0" >
                                                Username
                                            </p>
                                            <input
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: "#ffffff",
                                                    borderRadius: "1.25rem",
                                                }}
                                                type="text"
                                                className="form-control"
                                                name="username"
                                                onChange={(e) => {
                                                    setUsername(e.target.value);
                                                }}
                                                value={Username}
                                                autoComplete="off"

                                            />
                                            <p className="text-danger text-left mb-0">{UsernameError}</p>

                                        </div>
                                        <div className="col-sm-6 my-2">
                                            <p className="text-white m-0" >
                                                Email
                                            </p>
                                            <input
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: "#ffffff",
                                                    borderRadius: "1.25rem",
                                                }}
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                value={Email}
                                                autoComplete="off"

                                            />
                                            <p className="text-danger text-left mb-0">{EmailError}</p>

                                        </div>

                                        <div className="col-sm-6 my-2">
                                            <p className="text-white m-0" >
                                                Password
                                            </p>
                                            <input
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: "#ffffff",
                                                    borderRadius: "1.25rem",
                                                }}
                                                type="password"
                                                className="form-control"
                                                name="username"
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                }}
                                                value={Password}
                                                autoComplete="off"

                                            />
                                            <p className="text-danger text-left mb-0">{PasswordError}</p>

                                        </div>

                                        <div className="col-sm-6 my-2">
                                            <p className="text-white m-0" >
                                                Confirm password
                                            </p>
                                            <input
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: "#ffffff",
                                                    borderRadius: "1.25rem",
                                                }}
                                                type="password"
                                                className="form-control"
                                                name="username"
                                                onChange={(e) => {
                                                    setConfirmPassword(e.target.value);
                                                }}
                                                value={ConfirmPassword}
                                                autoComplete="off"

                                            />
                                            <p className="text-danger text-left mb-0">{ConfirmPasswordError}</p>

                                        </div>
                                        <div className="col-sm-6 my-2">
                                            <p className="text-white m-0" >
                                                Prifile image
                                            </p>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="user_logo"
                                                accept=
                                                "image/jpeg, image/png"
                                                onChange={(event) => {

                                                    if (event.target.files && event.target.files[0]) {
                                                        onchangeLogo(event);
                                                    }

                                                }}
                                            />
                                            <p className="text-danger text-left mb-0">{LogoError}</p>
                                        </div>

                                        <div className="col-sm-6 my-2">
                                            <p className="text-white m-0" >
                                                Client Code
                                            </p>
                                            <input
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: "#ffffff",
                                                    borderRadius: "1.25rem",
                                                }}
                                                type="text"
                                                className="form-control"
                                                name="country_code"
                                                onChange={(e) => {
                                                    setClientCode(e.target.value);
                                                }}
                                                value={ClientCode}
                                                autoComplete="off"

                                            />
                                            <p className="text-danger text-left mb-0">{ClientCodeError}</p>

                                        </div>
                                        <div className="col-sm-3 ">
                                            </div>

                                        <div className="col-sm-6 my-2 d-grid row">
                                            <ReCAPTCHA ref={RecaptchaRef} sitekey={"6Lc0CaEpAAAAADLoPpwWaSqdJjWO3bKM-ycwBah4"} />

                                            <p className="text-danger text-left mb-0 my-1">{CaptchaError}</p>

                                        </div>
                                        <div className="col-sm-3 ">
                                            </div>
                                    </div>

                                    <div className="col-sm-6 d-grid m-0">
                                        <div className="d-grid">
                                            {!loader ? (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    style={{ borderRadius: "1.25rem" }}
                                                >
                                                    Sign Up
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    style={{ borderRadius: "1.25rem" }}
                                                >
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    Sign Up
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default SignUp