import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailBodyView from "./EmailBodyView";
import Pagination from "../../Components/Pagination";
import TimeZone from "../../TimeZone";
import Select from 'react-select'
import { components } from 'react-select';
import DefaultLogo from "../../Images/foxilogic-disable.png"

function EmailList(props) {
    const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    const navigate = useNavigate();

    var url = new URL(window.location.href);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 100
    const search = url.searchParams.get('Search') || ''
    const fromdate = url.searchParams.get('fromdate') || ''
    const todate = url.searchParams.get('todate') || ''
    const clientiid = url.searchParams.get('ClientId') || '';
    const ClientId = localStorage.getItem("ClientId") > 0 ? localStorage.getItem("ClientId") : clientiid;

    const [NoOfRecords, setNoOfRecords] = useState(0);
    const [ModuleData, setModuleData] = useState([]);
    const [ServiceCall, setServiceCall] = useState(true);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [TicketSelected, setTicketSelected] = useState("");
    const [ClientList, setClientList] = useState([])
    const [ClientID, setClientID] = useState(ClientId || "")
    const [searchInput, setSearchInput] = useState(search)
    const [FromDate, setFromDate] = useState(fromdate)
    const [ToDate, setToDate] = useState(todate)
    const [searched, setSearched] = useState(false)

    const { SingleValue, Option } = components;

    //Client dropdown 
    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }),
    }

    const IconOption = (props) => (
        <Option {...props}>
            <img src={props.data.image}
                style={{
                    height: '25px', width: '25px', borderRadius: '50%',
                    marginRight: '7px'
                }}
                alt='client logo'
            />
            {props.data.label}
        </Option>
    );

    const IconSingleValue = (props) => (
        <SingleValue {...props}>
            <img src={props.data.image}
                style={{
                    height: '30px', width:
                        '30px', borderRadius: '50%', marginRight: '10px'
                }}
                alt='Client Logo'
            />
            {props.data.label}
        </SingleValue>
    );

    const getValue = () => {
        if (ClientID) {
            for (var i = 0; i < ClientList.length; i++) {
                if (ClientList[i].Id === parseInt(ClientID)) {
                    return [{ value: ClientList[i].Id, label: ClientList[i].Name, image: ClientList[i].image }];
                }
            }
        }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess("");
        }
        return () => { };
    }, [success]);

    useEffect(() => {
        setServiceCall(true);
    }, [limit, page, search]);

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2) {
            // setLoader(true)
            axios.get("clients")
                .then((response) => {
                    var TempData = response.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClientList(arr)
                })
                .catch((error) => {
                    // setLoader(false);
                })
        }
    }, [UserGroup.Id])

    useEffect(() => {
        if (ServiceCall && page) {
            setLoader(true);
            axios
                .get("get_sent_emails?PageNo=" + page + "&Limit=" + limit + "&Search=" + search + "&StartDate=" + FromDate + "&EndDate=" + ToDate + "&ClientId=" + ClientID)
                .then((response) => {
                    setModuleData(response.data.data);
                    setNoOfRecords(response.data.NoOfRecords);
                    setServiceCall(false);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                })
        }
    }, [ServiceCall, page, limit, search, FromDate, ToDate, ClientID]);

    const clearSearchHandler = () => {
        setServiceCall(true)
        setSearched(false)
        setSearchInput("")
        setFromDate('')
        setToDate('')
        setClientID('')
        navigate("?page=1&Search=")
    }

    useEffect(() => {
        if (searched) {
            navigate("?page=" + page + "&Limit=" + limit + "&Search=" + searchInput + "&StartDate=" + FromDate + "&EndDate=" + ToDate + "&ClientId=" + ClientID)
            setServiceCall(true);
            setSearched(false)
        }
    }, [navigate, searched, page, limit, searchInput, FromDate, ToDate, ClientID])

    return (
        <>
            {TicketSelected !== '' ? <EmailBodyView TicketSelected={ModuleData[TicketSelected]} setTicketSelected={setTicketSelected} /> : null}
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}

            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0">Email Sent List</h6>
                </div>
                <div className="col-md-4 text-right">

                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col-sm-2">
                    <label></label>
                    <input
                        type="text"
                        className="form-control mb-3"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                        value={searchInput}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                setSearched(true)
                            }
                        }}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>
                <div className="col-sm-2">
                    <label>From Date</label>
                    <input
                        type="date"
                        className="form-control mb-3"
                        placeholder="Select Date"
                        value={FromDate}
                        onChange={(e) => {
                            setFromDate(e.target.value)
                            setSearched(true)
                        }}
                    />
                </div>
                <div className="col-sm-2">
                    <label>To Date</label>
                    <input
                        type="date"
                        className="form-control mb-3"
                        placeholder="Select Date"
                        value={ToDate}
                        onChange={(e) => {
                            setToDate(e.target.value)
                            setSearched(true)
                        }}
                    />
                </div>
                {((UserGroup.Id === 1 || (UserGroup.Id === 2))) ?
                    <div className="col-sm-2">
                        <label></label>
                        {/* <select
                            className="form-select mb-3"
                            onChange={(e) => {
                                setClientID(e.target.value)
                                setSearched(true)
                            }}
                            value={ClientID}
                        >
                            <option value="">Select Client</option>
                            {
                                ClientList.map((item, index) => (
                                    <option key={index} value={item.Id}> {item.Name} </option>
                                ))
                            }
                        </select> */}
                        <Select
                            styles={customStyles}
                            hideSelectedOptions={false}
                            placeholder='Select client'
                            // defaultMenuIsOpen
                            components={{
                                SingleValue: IconSingleValue,
                                Option: IconOption
                            }}
                            options={ClientList}
                            onChange={(selected) => {
                                setClientID(selected.Id)
                                setSearched(true)
                            }}
                            value={getValue()}
                        />
                    </div>
                    : null}
                <div className="col-sm-2">
                    {(searched || searchInput || FromDate || ToDate || ClientID) ?
                        <>
                            <label>&nbsp;</label>
                            <button
                                style={{ marginTop: '18px' }}
                                type="button"
                                className="btn btn-primary"
                                title="Reset Filters"
                                onClick={clearSearchHandler}
                            >
                                <i className="bx bx-eraser search-icon"></i>
                            </button>
                        </>
                        : null}

                </div>
            </div>
            {/* </form> */}

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ maxHeight: '64vh' }} className=" horizontal-scroll">
                                <table className="table mb-0 table-striped ticket-table">
                                    {/* <table className="table mb-0 table-striped dashboard-stations"> */}
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70px" }}>#</th>
                                            <th>Ticket Id</th>
                                            <th>To Emails</th>
                                            <th>Subject</th>
                                            {/* <th>EmailBody</th> */}
                                            <th>Created By</th>
                                            <th>Date Time</th>
                                            <th >View Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ModuleData.length > 0 ?
                                            ModuleData.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{((page - 1) * limit) + index + 1}</td>
                                                        <td>{item.TicketId}</td>
                                                        <td>{item.ToEmails}</td>
                                                        <td>{item.Subject}</td>
                                                        {/* <td>{item.EmailBody.length < 100 ? item.EmailBody : item.EmailBody.slice(0, 97) + '...'}</td> */}
                                                        <td>{item.CreatedByName}</td>
                                                        <td>{(item.CreatedTimestamp > 0) ? <TimeZone timestamp={item.CreatedTimestamp} /> : null}</td>
                                                        <td className="action-buttons">
                                                            <i style={{ color: '#0d6efd' }} className="bx bx-mail-send" title="View Mail" onClick={() => setTicketSelected(index)}></i>
                                                        </td>
                                                    </tr>
                                                );
                                            }) :
                                            <tr>
                                                <td
                                                    colSpan={7}
                                                    style={{ textAlign: "center" }}>
                                                    <b>No Record Found</b>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {
                        NoOfRecords > 0 ?
                            <Pagination
                                records={ModuleData.length}
                                link={"sent_emails/"}
                                SelectedPage={page}
                                search={"&Search=" + searchInput}
                                NoOfRecords={NoOfRecords}
                            /> : null
                    }

                </div>
            </div>

        </>
    );
}

export default EmailList;