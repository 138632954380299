import React from "react";

const Loader = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        top: -60,
        left: 0,
        height: (props.height !== undefined ? props.height : 'calc(100% + 60px)'),
        width: "100%",
        backgroundColor: "rgb(0, 0, 0,0.5)",
        zIndex: "999999999",
        display: "flex",

      }}
    >
      <div className="spinner-border text-primary" role="status" style={{ margin: "auto" }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
