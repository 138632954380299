import React from 'react'
import Pagination from "../../Components/Pagination";
import TimeZone from "../../TimeZone";

const Page1 = (props) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row mt-2">
                            {/* <div className="col-sm-2">
                            <input
                                type="text"
                                className="form-control mb-3"
                                id="search"
                                name="search"
                                placeholder="Search Here"
                                value={props.searchInput}
                                onChange={(e) => props.setSearchInput(e.target.value)}
                            />
                        </div> */}
                            <div className="col-sm-2">
                                {/* <button
                                type="button"
                                className="btn btn-primary mb-3"
                                title="Search"
                                onClick={() => props.setServiceCall(true)}
                            >
                                <i className="bx bx-search search-icon"></i>
                            </button>
                            &nbsp;&nbsp; */}
                                {
                                    props.searchInput ? <button
                                        type="button"
                                        className="btn btn-primary mb-3"
                                        title="Clear"
                                        onClick={props.clearSearchHandler}
                                    >
                                        <i className="bx bx-eraser search-icon"></i>
                                    </button> : null
                                }
                            </div>
                        </div>
                        <div style={{ maxHeight: '64vh' }} className="horizontal-scroll">
                            <table className="table mb-0 table-striped ticket-table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "70px" }}>#</th>
                                        <th>Channel partner</th>
                                        <th>Com</th>
                                        <th>Device Id</th>
                                        <th>Device Name</th>
                                        <th>Device Type</th>
                                        <th>Device Type Group</th>
                                        <th>Group Name</th>
                                        <th>Region</th>
                                        <th>Site</th>
                                        <th>Site Status</th>
                                        <th>Basestation Id</th>
                                        <th>Com State Text</th>
                                        <th>Is Single Covered</th>
                                        <th>Last Seen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.OutageData.length > 0 ?
                                        props.OutageData.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{((props.page - 1) * props.limit) + index + 1}</td>
                                                    <td>{item.ChannelPartner}</td>
                                                    <td>{item.Com}</td>
                                                    <td>{item.DeviceId}</td>
                                                    <td>{item.DeviceName}</td>
                                                    <td>{item.DeviceType}</td>
                                                    <td>{item.DeviceTypeGroup}</td>
                                                    <td>{item.GroupName}</td>
                                                    <td>{item.Region}</td>
                                                    <td>{item.Site}</td>
                                                    <td>{item.SiteStatus}</td>
                                                    <td>{item.basestation_Id}</td>
                                                    <td>{item.comStateText}</td>
                                                    <td>{item.isSingleCovered === 1 ? 'True' : 'False'}</td>
                                                    <td>{(item.LastCommunicationTime > 0) ? <TimeZone timestamp={item.LastCommunicationTime} /> : "-"}</td>
                                                </tr>
                                            )
                                        }) : <tr>
                                            <td
                                                colSpan={15}
                                                style={{ textAlign: "center" }}>
                                                <b>No Record Found</b>
                                            </td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {
                    props.NoOfRecords > 0 ?
                        <Pagination
                            records={props.OutageData.length}
                            link={"outage_management"}
                            SelectedPage={props.page}
                            search={"&Search=" + props.searchInput}
                            NoOfRecords={props.NoOfRecords}
                            OutageData ={props.OutageData}
                            limit={props.limit}
                        /> : null
                }
            </div>
            {/* <div className="col-md-2">
                <div className="card">
                    <div className="card-body text-center">
                        <h6>Total Impacted Basestations</h6>
                        <h2>{props.TotalImpactedBasestations}</h2>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body text-center">
                        <h6>Total Impacted Devices</h6>
                        <h2 style={{ color: '#d44753' }}>{props.TotalImpactedDevices}</h2>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body text-center">
                        <h6>Regions</h6>
                        <ul className="filters">
                            {
                                props.Regions.map((item, index) => {
                                    return item ? (
                                        <li key={index}>
                                            <div>
                                                <input type="checkbox" checked={(props.RegionsSelected.includes(item) ? true : false)} onChange={() => props.HandleRigion(item)} /><span className="m-0">{item}</span>
                                            </div>
                                        </li>
                                    ) : null
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body text-center">
                        <h6>Device Types</h6>
                        {
                            JSON.stringify(props.DeviceTypes) !== '{}' ?
                                <ul className="filters">
                                    <li>
                                        <div>
                                            <input type="checkbox" checked={(props.DeviceTypesSelected.includes(props.DeviceTypes.Other) ? true : false)} onChange={() => props.HandleDeviceTypes(props.DeviceTypes.Other)} /><span className="m-0">{props.DeviceTypes.Other}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <input type="checkbox" checked={(props.DeviceTypesSelected.includes('StationaryDevices') ? true : false)} onChange={() => props.HandleDeviceTypes('StationaryDevices')} /><span className="m-0">Stationary Devices</span>
                                        </div>
                                        <ul>
                                            {
                                                props.DeviceTypes.StationaryDevices.map((item, index) => {
                                                    return item ? (
                                                        <li key={index}>
                                                            <div>
                                                                <input type="checkbox" checked={(props.DeviceTypesSelected.includes(item) ? true : false)} onChange={() => props.HandleDeviceTypes(item)} /><span className="m-0">{item}</span>
                                                            </div>
                                                        </li>
                                                    ) : null
                                                })
                                            }
                                        </ul>
                                    </li>
                                </ul> : null}
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Page1