import React from 'react'
import { Link } from "react-router-dom";
import BitSunburstChart from './BitSunburstChart';
import CommSunburstChart from './CommSunburstChart ';
import TicketCharts from './TicketCharts';
import CabisenseTicketCharts from './CabisenseTicketCharts';
import ReactCountryFlag from "react-country-flag"
import DefaultLogo from "../../Images/foxilogic-disable.png"
import MerakiTicketCharts from './MerakiTicketCharts';

const CountryDetails = (props) => {
    const LoginClientId = localStorage.getItem("ClientId");
    const LoginClientCountryId = localStorage.getItem("ClientCountryId");

    return (
        <>
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-6">
                            {/* <h5>Countries</h5> */}
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Countries</div>
                                {
                                    props.UserGroup.Id === 1 || props.UserGroup.Id === 2 || props.UserGroup.Id === 4 || props.UserGroup.Id === 3 ?
                                        <div className="ps-3">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb mb-0 p-0">
                                                    <li className="breadcrumb-item">
                                                        {
                                                            props.UserGroup.Id === 1 || props.UserGroup.Id === 2 ?
                                                                <Link to="">
                                                                    <i
                                                                        className="bx bx-home-alt"
                                                                        onClick={() => {
                                                                            props.setCountrywiseBaseStationData([]);
                                                                            props.setClientId("");
                                                                        }}
                                                                    ></i>
                                                                </Link> :
                                                                <i className="bx bx-home-alt"></i>
                                                        }

                                                    </li>
                                                    <li
                                                        className="breadcrumb-item active"
                                                        aria-current="page"
                                                    >

                                                        <img
                                                            src={props.ClientLogo ? props.ClientLogo : DefaultLogo}
                                                            className="user-img mx-1"
                                                            alt="Client Logo"
                                                            style={{
                                                                width: "26px",
                                                                height: "26px",
                                                            }}
                                                        />
                                                        {props.ClientName}
                                                    </li>
                                                </ol>
                                            </nav>
                                        </div> : null
                                }
                            </div>
                        </div>
                        {/* <div className="col-md-2 text-right">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search country here..."
                                value={props.CountrySearch}
                                onChange={(e) => props.setCountrySearch(e.target.value)}
                            />
                        </div> */}

                        <div className="col-md-6 d-flex justify-content-end gap-3">
                            <div className="d-flex align-items-center">
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    placeholder="Search country here..."
                                    value={props.CountrySearch}
                                    onChange={(e) => props.setCountrySearch(e.target.value)}
                                />
                            </div>
                            {(LoginClientId > 0 && parseInt(LoginClientCountryId) === 0) ? <div>
                                <div className="position-badge text-center" style={{ display: "flex" }} onClick={() => {
                                    props.setBsDownAlertFlag(!props.BsDownAlertFlag);
                                }}>
                                    {props.AlertBadgeCount ? <div className="badge green">{props.AlertBadgeCount}</div>
                                        : null}

                                    <i className="bx bx-bell icon" style={{ color: "blue", fontSize: "33px" }} ></i>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                    <div className="row dashboard-client-card-box" >
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-12 text-center">
                            <div className="row">
                                {props.CountrywiseBaseStationData.map((item, index) => {
                                    item.CountryName = item.CountryName
                                        ? item.CountryName
                                        : "-";
                                    return item.CountryName.toLowerCase().includes(
                                        props.CountrySearch.trim().toLowerCase()
                                    ) ? (
                                        <div key={index} className="col-sm-12">
                                            <div
                                                className="row dashboard-client-card"
                                                // onClick={() => {
                                                //   setClientCountryId(item.ClientCountryId);
                                                //   setCountryName(item.CountryName);
                                                // }}
                                                style={
                                                    props.ClientCountryId === item.ClientCountryId
                                                        ? { border: "1px solid gray", padding: "1%" }
                                                        : { padding: "1%" }
                                                }
                                            >
                                                <div className="col-sm-11 d-flex mb-2" style={{
                                                    justifyContent: "center"

                                                }}>

                                                    {item.CountryCode ? <ReactCountryFlag
                                                        className="dashboard_country_flag mx-2"
                                                        countryCode={item.CountryCode}
                                                        aria-label={item.CountryCode}
                                                    /> : <i
                                                        className="bx bx-flag mx-1 p-0"
                                                        title="Manage country"
                                                        style={{ fontSize: "28px", cursor: "pointer" }}
                                                    ></i>}
                                                    <span className='mx-1'><h4 className='mt-1'>{item.CountryName}</h4></span>
                                                </div>
                                                <div className='col-sm-1'>
                                                    {props.UserGroup.Id < 3 ?
                                                        <i
                                                            className="bx bx-cog"
                                                            style={{ fontSize: "25px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                props.setCronStatusClientId(item.ClientId);
                                                                props.setCronStatusClientCountryId(item.ClientCountryId);
                                                                props.setSelectedCronStatusPage("country")
                                                            }}
                                                        ></i> : null}
                                                </div>
                                                <div className='col-sm-6 row mt-1'>
                                                    <div className="col-sm-6" style={{
                                                        verticalAlign: "middle",
                                                    }}>
                                                        <span style={{ cursor: "pointer", fontWeight: "bold" }} onClick={() => {
                                                            props.setClientCountryId(item.ClientCountryId);
                                                            props.setCountryName(item.CountryName);
                                                            props.setBitComSelectedState("BitState");
                                                        }}><b>BIT State</b></span>
                                                        <BitSunburstChart setCountryCode={props.setCountryCode} item={item} Selected="country" setClientCountryId={props.setClientCountryId} setCountryName={props.setCountryName} setBitComSelectedState={props.setBitComSelectedState} ClientCountryId={item.ClientCountryId} CountryName={item.CountryName} />
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <span style={{ cursor: "pointer", fontWeight: "bold" }} onClick={() => {
                                                            props.setClientCountryId(item.ClientCountryId);
                                                            props.setCountryName(item.CountryName);
                                                            props.setBitComSelectedState("ComState");
                                                        }}><b>COMM State</b></span>
                                                        <CommSunburstChart setCountryCode={props.setCountryCode} item={item} Selected="country" setClientCountryId={props.setClientCountryId} setCountryName={props.setCountryName} setBitComSelectedState={props.setBitComSelectedState} ClientCountryId={item.ClientCountryId} CountryName={item.CountryName} />
                                                    </div>
                                                    <div>

                                                        <span className='mx-4' style={{
                                                            fontWeight: "bold"
                                                        }}>
                                                            <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: '#00b500' }}></span>
                                                            OK
                                                        </span>

                                                        <span className='mx-4' style={{
                                                            fontWeight: "bold"
                                                        }}>
                                                            <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: '#fd6666' }}></span>
                                                            KO
                                                        </span>

                                                        <span className='mx-4' style={{
                                                            fontWeight: "bold"
                                                        }}>
                                                            <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: 'red' }}></span>
                                                            NO
                                                        </span>

                                                        <span className='mx-4' style={{
                                                            fontWeight: "bold"
                                                        }}>
                                                            <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: '#df8c29' }}></span>
                                                            WARN
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Sigfox Tickets */}
                                                {props.CountryTicketFlag ?
                                                    <>
                                                        <div className='col-sm-2 mt-1'>
                                                            <div className="col-sm-12" >
                                                                <span style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px"
                                                                }}><b>Sigfox Tickets</b></span>
                                                            </div>
                                                            <Link to={"/tickets-list/" + item.ClientCountryId}>
                                                                <div className="col-sm-12">
                                                                    <div className="row dashboard-ticket-card mx-1">
                                                                        <TicketCharts item={item} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        {/* Cabisense Tickets */}
                                                        <div className='col-sm-2 mt-1'>

                                                            <div className="col-sm-12">
                                                                <span style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px"
                                                                }}><b>Cabisense Tickets</b></span>
                                                            </div>

                                                            <Link to={"/cabisense-tickets-list/" + item.ClientCountryId} >
                                                                <div className="col-sm-12">
                                                                    <div className="row dashboard-ticket-card mx-1">
                                                                        <CabisenseTicketCharts item={item} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        {/* Meraki Tickets */}
                                                        <div className='col-sm-2 mt-1'>

                                                            <div className="col-sm-12">
                                                                <span style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px"
                                                                }}><b>Meraki Tickets</b></span>
                                                            </div>

                                                            <Link to={"/meraki-tickets-list/" + item.ClientCountryId} >
                                                                <div className="col-sm-12">
                                                                    <div className="row dashboard-ticket-card mx-1">
                                                                        <MerakiTicketCharts item={item} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                    </>
                                                    : null}
                                                {/* tickets */}
                                            </div>

                                        </div>
                                    ) : null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CountryDetails