import React from 'react'
import DevicesPop from './DevicesPop'
import Map from './Map';

const Page2 = (props) => {

  return (
    <>
      <div className="row">
        <div className="col-md-5" style={{ minHeight: '70vh', maxHeight: '70vh' }}>
          <Map Filter={props.Filter} OutageData={props.MapData} SiteSelected={props.SiteSelected} TicketsModuleAccess={props.TicketsModuleAccess} setBaseStationIdNavigation={props.setBaseStationIdNavigation}/>
        </div>
        <div className="col-md-7">
          {props.DevicesPopData.length > 0 ? <div className="row">
            <div className="col-md-12">
              <DevicesPop SiteSelected={props.SiteSelected} setSiteSelected={props.setSiteSelected} Filter={props.Filter} setFilter={props.setFilter} DevicesPopData={props.DevicesPopData} setDevicesPopData={props.setDevicesPopData} ExportInternalDevices={props.ExportInternalDevices} ExportInternalDevicesDt={props.ExportInternalDevicesDt} />
            </div>
          </div> :
            <div className="row">
              <div className="col-md-12">
                <div style={{ maxHeight: '70vh' }} className="horizontal-scroll">
                  <table className="table mb-0 table-striped ticket-table">
                    <thead className="text-center">
                      <tr className="thead-sticky">
                        <th className="text-left" style={{ width: "70px" }}>#</th>
                        <th className="text-left">Site</th>
                        <th>Total</th>
                        <th style={{ minWidth: '180px' }}>Other</th>
                        <th>Stationary Devices</th>
                        {props.TicketsModuleAccess ? <th>Tickets</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {props.SiteWiseCounts.length > 0 ?
                        <>
                          {props.SiteWiseCounts.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.Site}</td>
                                <th className="text-right" style={{ cursor: 'pointer' }} onClick={() => props.FetchDevicesPopData(item.Site)}>{item.AllCount}</th>
                                <td className="text-right" style={{ position: 'relative' }}>
                                  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span style={{ height: '25px', width: (item.OtherCount / item.AllCount * 100) + '%', background: '#d44753' }}></span>
                                  </div>
                                  <span onClick={() => item.OtherCount > 0 ? props.FetchDevicesPopData(item.Site, 'Other') : null} style={{ position: 'absolute', right: '10px', top: '26%', cursor: 'pointer' }}>{item.OtherCount}</span>
                                </td>
                                <td className="text-right">
                                  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span style={{ height: '25px', width: (item.StationaryCount / item.AllCount * 100) + '%', background: '#d44753' }}></span>
                                    <span onClick={() => item.StationaryCount > 0 ? props.FetchDevicesPopData(item.Site, 'Stationary') : null} style={{ cursor: 'pointer' }}>{item.StationaryCount}</span>
                                  </div>
                                </td>

                                {props.TicketsModuleAccess ? <td> <button title="Site Tickets" className="btn btn-primary text-right" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 16px', cursor: 'pointer' }}
                                  onClick={(e) => { props.setSiteName(item.Site) }}
                                >
                                  View
                                </button></td> : null}

                              </tr>
                            )
                          })}
                          <tr className="tfoot-sticky">
                            <th colSpan={2}>Total</th>
                            <th className="text-right">{props.TotalCount}</th>
                            <th className="text-right">{props.TotalOtherCount}</th>
                            <th className="text-right">{props.TotalStationaryCount}</th>
                            <th className="text-right"></th>
                          </tr>
                        </> : <tr>
                          <td
                            colSpan={6}
                            style={{ textAlign: "center" }}>
                            <b>No Record Found</b>
                          </td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          }
        </div>
        {/* <div className="col-md-2">
        <div className="card">
          <div className="card-body text-center">
            <h6>Total Impacted Basestations</h6>
            <h2>{props.TotalImpactedBasestationsPage2}</h2>
          </div>
        </div>
        <div className="card">
          <div className="card-body text-center">
            <h6>Total Impacted Devices</h6>
            <h2 style={{ color: '#d44753' }}>{props.TotalImpactedDevicesPage2}</h2>
          </div>
        </div>
        <div className="card">
          <div className="card-body text-center">
            <h6>Regions</h6>
            <ul className="filters">
              {
                props.Regions.map((item, index) => {
                  return item ? (
                    <li key={index}>
                      <div>
                        <input type="checkbox" checked={(props.RegionsSelected.includes(item) ? true : false)} onChange={() => props.HandleRigion(item)} /><span className="m-0">{item}</span>
                      </div>
                    </li>
                  ) : null
                })
              }
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-body text-center">
            <h6>Device Types</h6>
            {
              JSON.stringify(props.DeviceTypes) !== '{}' ?
                <ul className="filters">
                  <li>
                    <div>
                      <input type="checkbox" checked={(props.DeviceTypesSelected.includes(props.DeviceTypes.Other) ? true : false)} onChange={() => props.HandleDeviceTypes(props.DeviceTypes.Other)} /><span className="m-0">{props.DeviceTypes.Other}</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <input type="checkbox" checked={(props.DeviceTypesSelected.includes('StationaryDevices') ? true : false)} onChange={() => props.HandleDeviceTypes('StationaryDevices')} /><span className="m-0">Stationary Devices</span>
                    </div>
                    <ul>
                      {
                        props.DeviceTypes.StationaryDevices.map((item, index) => {
                          return item ? (
                            <li key={index}>
                              <div>
                                <input type="checkbox" checked={(props.DeviceTypesSelected.includes(item) ? true : false)} onChange={() => props.HandleDeviceTypes(item)} /><span className="m-0">{item}</span>
                              </div>
                            </li>
                          ) : null
                        })
                      }
                    </ul>
                  </li>
                </ul> : null}
          </div>
        </div>
      </div> */}
      </div>
    </>
  )
}

export default Page2