import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../Components/Loader";
import axios from "../../axios";
import Switch from "react-switch";

const MaintenanceManagement = () => {
    const [loader, setLoader] = useState("");
    const [Search, setSearch] = useState("");
    const [ServiceCall, setServiceCall] = useState(true);
    const [MaintenanceList, setMaintenanceList] = useState([])

    const handleConfig = (Id, DisableCreateFault) => {
        const Payload = {
            "MaintenanceId": Id,
            "DisableCreateFault": parseInt(DisableCreateFault) === 0 ? 1 : 0
        }
        axios.post("disable_maintenance", Payload)
            .then((response) => {
                setMaintenanceList(response.data.data)
                setServiceCall(true)
            })
            .catch((error) => {
            })
    }

    useEffect(() => {
        if (ServiceCall) {
            setLoader(true);
            axios.get("get_all_maintenance?Search=" + Search)
                .then((response) => {
                    setMaintenanceList(response.data.data)
                    setServiceCall(false)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }

    }, [ServiceCall, Search])


    const handleClearSearch = () => {
        setSearch("");
        setServiceCall(true);
    }

    const handleSubmit = () => {
        setServiceCall(true);
    }

    return (
        <div>

            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0 "> Maintenance SP IMS Enabled</h6>
                </div>
                <div className="col-md-4 text-right">

                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-3"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                        value={Search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="col-sm-3">
                    <button
                        type="submit"
                        className="btn btn-primary mb-3 mx-1"
                        title="Search"
                        onClick={handleSubmit}
                    >
                        <i className="bx bx-search search-icon"></i>
                    </button>
                    &nbsp;
                    {Search ?
                        <button
                            type="button"
                            className="btn btn-primary mb-3"
                            title="Clear"
                            onClick={handleClearSearch}
                        >
                            <i className="bx bx-eraser search-icon"></i>
                        </button>
                        : null}
                </div>
            </div>

            <div className="card">
                <div className="card-body horizontal-scroll">
                    <div className="row g-3" style={{ marginBottom: "12px" }}>
                    </div>

                    <table className="table mb-0 table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Maintenance</th>
                                <th>Is Maintenance SP IMS Enabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {MaintenanceList.length > 0 ?
                                MaintenanceList.map((item, index) => {
                                    return (
                                        <tr key={item.Id}>
                                            <td>{index + 1}</td>
                                            <td>{item.Maintenance}</td>
                                            <td>
                                                <Switch
                                                    className="login-switch"
                                                    onColor={"#136dfa"}
                                                    height={25}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    checked={parseInt(item.DisableCreateFault) === 1 ? false : true}
                                                    onChange={() => handleConfig(item.Id, item.DisableCreateFault)}
                                                />
                                            </td>

                                        </tr>
                                    );
                                }) :
                                <tr>
                                    <td
                                        colSpan={3}
                                        style={{ textAlign: "center" }}>
                                        <b>No Record Found</b>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default MaintenanceManagement