import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSunburst from "highcharts/modules/sunburst";
import CustomEvents from "highcharts-custom-events";
import { TICKET_STATUS_OPEN, TICKET_STATUS_POWER_CONFIRMATION, TICKET_STATUS_INCIDENT_DISPATCHED, TICKET_STATUS_DEFERRED, TICKET_STATUS_RESOLVED, TICKET_STATUS_CLOSED } from "../../Custom";
HighchartsSunburst(Highcharts);
CustomEvents(Highcharts);

const TicketCharts = (props) => {
    const theme = localStorage.getItem('theme');
    useEffect(() => {
        const chart = refChart.current?.chart;
        if (chart) chart.reflow(false);
    }, [theme]);

    const refChart = useRef();
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: '',
        },
        subtitle: {
            text:
                '',
        },
        xAxis: {
            categories: [''],
            crosshair: true,
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            },
            labels: {
                style: {
                }
            }

        },
        tooltip: {
            valueSuffix: ''
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true
                }
            },

            series: {
                dataLabels: {
                    enabled: true,
                    borderRadius: 5,
                    borderWidth: 1,
                    y: -6,
                }
            }
        },
        series: [
            {
                name: 'Total',
                data: [props.item.TotalTickets],
                color: '#3c3cff'
            },
            {
                name: TICKET_STATUS_RESOLVED,
                data: [props.item.ResolvedTickets],
                color: '#df8c29'
            },
            {
                name: TICKET_STATUS_OPEN,
                data: [props.item.OpenTickets],
                color: 'red'
            }, {
                name: TICKET_STATUS_CLOSED,
                data: [props.item.ClosedTickets],
                color: '#00b500'
            }, {
                name:TICKET_STATUS_INCIDENT_DISPATCHED,
                data: [props.item.IncidentDispatchedTickets],
                color: '#b7b700'
            },
            {
                name: TICKET_STATUS_DEFERRED,
                data: [props.item.DeferredTickets],
                color: '#b037a0'
            },
            {
                name: TICKET_STATUS_POWER_CONFIRMATION,
                data: [props.item.PowerConfirmationTickets],
                color: '#37b0a8'
            }
        ],
        credits: {
            enabled: false
        },
        legend: {
            enabled: true,
            symbolRadius: 0,
        },
        exporting: {
            enabled: false,
            buttons: {
                contextButton: {
                    className: "highcharts-contextbutton",
                    enabled: true,
                    symbol: 'download',
                    symbolStroke: "#ffffff",
                    theme: {
                        fill: '#1c2427',
                    },
                    titleKey: ""
                }
            }
        }
    }

    return (
        <div>
            <HighchartsReact ref={refChart} highcharts={Highcharts} options={options} />
        </div>
    );
}

export default TicketCharts