import React, { useState, useEffect } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import Select from 'react-select'
// import { components } from 'react-select';
import ReactCountryFlag from "react-country-flag"
import DefaultLogo from "../../Images/foxilogic-disable.png"

const MerakiViewTickets = () => {
    var url = new URL(window.location.href);
    const clientiid = url.searchParams.get('ClientId') ? url.searchParams.get('ClientId') : "";

    const navigate = useNavigate();
    const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    const ClientId = localStorage.getItem("ClientId") > 0 ? localStorage.getItem("ClientId") : clientiid;
    const getClientCountryId = localStorage.getItem("ClientCountryId") > 0 ?
        localStorage.getItem("ClientCountryId") : "";

    const [CountryList, setCountryList] = useState([])
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");

    const RoleId = localStorage.getItem("RoleId");
    const ClientCountryId = localStorage.getItem("ClientCountryId");

    useEffect(() => {
        if (RoleId === 18) {
            navigate(`/meraki-tickets-list/${ClientCountryId}`);
        }
    }, [RoleId, ClientCountryId, navigate])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess(""); 
        }
        return () => { }; 
    }, [success]);

    useEffect(() => {
        if (UserGroup.Id > 3) {
            navigate('/meraki-tickets-list/' + getClientCountryId)
        }
    }, [UserGroup.Id, getClientCountryId, navigate]) 


    useEffect(() => {
        // if (ClientID) {
        setLoader(true)
        axios.get("get_countrywise_meraki_tickets_count?ClientId=" + ClientId)
            .then((response) => {
                setCountryList(response.data.data)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
            })
        // }
    }, [ClientId])


    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <>
                <div className="row">
                    <div className="col-md-8">
                        <h6 className="mb-0">Meraki Tickets</h6>
                    </div>
                    <div className="col-md-4 text-right">
                    </div>
                </div>
                <hr />

                <div className="row">
                    {/* <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-3"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                    />
                </div> */}

                    {/* {((UserGroup.Id === 1 || (UserGroup.Id === 2))) ?
                        <div className="col-sm-3">
                            <Select
                                styles={customStyles}
                                hideSelectedOptions={false}
                                // defaultMenuIsOpen
                                components={{
                                    SingleValue: IconSingleValue,
                                    Option: IconOption
                                }}
                                options={ClientList}
                                onChange={(selected) => {
                                    setClientID(selected.Id)
                                }}
                                value={getValue()}
                            />
                        </div>
                        : null} */} 

                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">

                                <div style={{ maxHeight: '64vh' }} className=" horizontal-scroll">
                                    <table className="table mb-0 table-striped ticket-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Client Name</th>
                                                <th>Country Name</th>
                                                <th>Ticket Count</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {CountryList.length > 0 ?
                                                CountryList.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td><img
                                                                src={item.ClientLogo ? item.ClientLogo : DefaultLogo}
                                                                className="user-img"
                                                                alt="Client Logo"
                                                            />&nbsp;&nbsp;&nbsp;{item.ClientName}</td>
                                                            <td> <ReactCountryFlag
                                                                className="add_country_flag"
                                                                countryCode={item.CountryCode ? item.CountryCode : ""}
                                                                aria-label={item.CountryCode}
                                                                style={{
                                                                    marginRight: "13px"
                                                                }}
                                                            />{item.CountryName}</td>
                                                            <td>{item.Count}</td>
                                                            <td>
                                                                <Link to={"/meraki-tickets-list/" + item.ClientCountryId}>
                                                                    <i className='bx bx-show eye-icon' title="Show Tickets"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : <tr>
                                                    <td
                                                        colSpan={5}
                                                        style={{ textAlign: "center" }}>
                                                        <b>No Record Found</b>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        </div>


    )
}

export default MerakiViewTickets