import React, { useRef } from 'react'
import { DefaultEditor } from 'react-simple-wysiwyg';

const EditRecommendedFix = (props) => {
    const DivRef = useRef(null);
    const onChange = (e) => {
        props.setEditText(e.target.value)
    }

    const handleClose = () => {
        props.setIncidentType("");
        props.setRecommendIndex("");
        props.setEditText("")
    }

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            handleClose();
        }
    }

    return (
        <div
            style={{
                position: "absolute",
                height: "100vh",
                width: "100%",
                top: -60,
                left: 0,
                display: "flex",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: "999999"
            }}
            onClick={(e) => {
                handleCloseClick(e)
            }}
        >
            <div
                className="card"
                style={{
                    maxWidth: "50%",
                    maxHeight: "90vh",
                    margin: "auto",
                    textAlign: "center",
                    boxShadow: "0px 0px 5px 0px #5c5c5c",
                }}
                ref={DivRef}
            >

                <div className="card-body horizontal-scroll">
                    <div className="row">
                        <div className="col-md-10 text-left mb-3">
                            <h5>Edit {props.ModuleData[props.recommentIndex].IncidentCode}
                                &nbsp;{props.incidentType} Recommended Fix Config</h5>
                        </div>
                        <div className="col-md-2 text-right">
                            <button className="btn btn-secondary"
                                onClick={() => handleClose()}>x</button>
                        </div>
                        <div className="col-md-12 mb-3">
                            <DefaultEditor style={{ textAlign: "left" }} value={props.EditText} onChange={onChange} />
                        </div>
                        <div className='col-md-12 mb-3'>
                            {/* <span className="text-danger">{props.EditTestError}</span> */}
                        </div>

                        <div className='col-sm-12'>
                            <button
                                type="submit"
                                className="btn btn-primary px-5"
                                style={{ float: "right", marginLeft: "10px" }}
                                onClick={() => props.updateRecommendFix("Save")}
                            >
                                Save
                            </button>

                            {
                                ((props.UserGroup.Id === 1 || props.UserGroup.Id === 2) && props.ClientID === "-1") ? <button
                                    type="submit"
                                    className="btn btn-primary px-5"
                                    style={{ float: "right" }}
                                    onClick={() => props.updateRecommendFix("SaveToAll")}
                                >
                                    Apply to all
                                </button> : null
                            }

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditRecommendedFix