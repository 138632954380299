import React from 'react';
import Pagination from "../../Components/Pagination";
import TimeZone from "../../TimeZone";


const MerakiPage2 = (props) => {

    function SecondsToDhms(seconds) {
        seconds = Number(seconds)
        var d = Math.floor(seconds / (3600 * 24))
        var h = Math.floor((seconds % (3600 * 24)) / 3600)
        var m = Math.floor((seconds % 3600) / 60)
        var s = Math.floor(seconds % 60)
        var dDisplay = d > 0 ? d + (parseInt(d) === 1 ? " day, " : " days, ") : ""
        var hDisplay = h > 0 ? h + (parseInt(h) === 1 ? " hour, " : " hours, ") : ""
        var mDisplay = m > 0 ? m + (parseInt(m) === 1 ? " min" : " mins") : ""
        var sDisplay = s > 0 ? s + (parseInt(s) === 1 ? " sec" : " sec") : ""
        if (dDisplay || hDisplay || mDisplay || sDisplay) {
            if (seconds > 60) {
                return dDisplay + hDisplay + mDisplay
            } else {
                return dDisplay + hDisplay + mDisplay + sDisplay
            }
        } else {
            return 0
        }
    }

    return (
        <>

            <div className="table-responsive" style={{ overflow: "auto", height: "58vh" }}>
                <table
                    className="table mb-0 text-center table-striped ticket-table table-hover"
                    style={{ verticalAlign: "middle" }}
                >
                    <thead className="sticky-top">
                        <tr>
                            <th>#</th>
                            <th style={{ textAlign: "left" }}>Ticket Id</th>
                            <th style={{ textAlign: "left" }}>Fresh Service Ticket Id</th>
                            <th>Fault Reference Number</th>
                            <th style={{ textAlign: "left" }}>BaseStation Id</th>
                            <th>Lessor Id</th>
                            <th>Site Name</th>
                            <th>Site Host</th>
                            <th>Maintenance</th>
                            <th>Incident Code</th>
                            <th>Ticket Status</th>
                            <th>Generated Time</th>
                            <th>Open State</th>
                            <th>TTD Time</th>
                            <th>Incident Dispatch State</th>
                            <th>Deferred State</th>
                            <th>Ageing (SLA)</th>
                            <th>Ageing (Actual)</th>
                            <th>TTR Time </th>
                            <th>Resolved Time</th>
                            <th>Resolved State</th>
                            {/* <th>Closed State</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {props.ModuleData.length > 0 ? props.ModuleData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{((props.page - 1) * props.limit) + index + 1}</td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.TicketId}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.FreshServiceTicketId}
                                    </td>
                                    <td>{item.FaultId ? item.FaultId : "-"}</td>
                                    <td style={{ textAlign: "center" }}>{item.BasestationId}</td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.BasestationLessorId}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                        {item.SiteName}
                                    </td>
                                    <td>
                                        {item.HostName}
                                    </td>
                                    <td style={{ textAlign: "center" }}>{item.SiteMaintenanceName}</td>
                                    <td style={{ textAlign: "left" }}>
                                        {item.IncidentCode}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.TicketStatus}
                                    </td>
                                    <td style={{ textAlign: "center" }}>{(item.CreatedTimestamp > 0) ? <TimeZone timestamp={item.CreatedTimestamp} /> : null}</td>
                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.OpenDifference)}</td>
                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.TtdDifference)}</td>
                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.IncidentDispatchedDifference)}</td>
                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.TotalDifferedTime)}</td>
                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.AgingSlaDifference)}</td>

                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.AgingActualDifference)}</td>

                                    <td style={{ textAlign: "center" }}>
                                        {SecondsToDhms(item.TtrDifference)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>{(item.ResolvedTimestamp > 0) ? <TimeZone timestamp={item.ResolvedTimestamp} /> : null}</td>
                                    <td style={{ textAlign: "center" }}> {SecondsToDhms(item.ResolvedDifference)}</td>
                                    {/* <td> {item.ClosedTimestamp > 0 ? "Yes" : "No"}</td> */}
                                </tr>
                            );
                        }) :
                            <tr>

                                <td colSpan={20}>
                                    No record found
                                </td>
                            </tr>
                        }

                        <tr className="custom-tfooter">
                            <td style={{ textAlign: "left", fontWeight: "bold" }} colSpan={12}>Total Average</td>
                            <td style={{ textAlign: "center", fontWeight: "bold" }} >{SecondsToDhms(props.OpenAvg)}</td>

                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.TTDAvg)}</td>

                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.IncidentDispatchedAvg)}</td>
                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.DiferredAvg)}</td>

                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.AgeingSlaAvg)}</td>

                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.AgeingActualAvg)}</td>

                            <td style={{ textAlign: "center", fontWeight: "bold" }}>{SecondsToDhms(props.MTTRAvg)}</td>
                            <td></td>

                            <td style={{ textAlign: "center", fontWeight: "bold" }}> {SecondsToDhms(props.ResolvedAvg)}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            {
                props.NoOfRecords > 0 ?
                    <Pagination
                        records={props.ModuleData.length}
                        link={"report/meraki_ticket_time_report"}
                        SelectedPage={props.page}
                        search={"&Search=" + props.searchInput + "&ClientId=" + props.Client + "&ClientCountryId=" + props.CountryID + "&StartDate=" + props.SelectedDate + "&EndDate=" + props.SelectedEndDate + "&IncidentDispatchedTime=" + props.IncidentDispatchedFlag + "&Status=" + props.TicketStatus + "&DateRange=" + props.DateRange + "&Maintenance=" + props.Maintenance + "&ReportType=" + props.ReportType + "&Region=" + props.Region}
                        NoOfRecords={props.NoOfRecords}
                        limit={props.limit}
                    /> : null
            }
        </>
    )
}

export default MerakiPage2