import React, { useEffect, useState } from "react";

import axios from "../axios";
import BGImage from "../Images/login3.jpg";
import ForgotImage from "../Images/forgot-2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


const ResetPassword = () => {
    // const { token } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    // const [Email, setEmail] = useState("");
    const [Otp, setOtp] = useState("");

    const [Password, setPassword] = useState("");
    const [PasswordError, setPasswordError] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [ConfirmPasswordError, setConfirmPasswordError] = useState("");
    const [LoginLoader, setLoginLoader] = useState(false);

    useEffect(() => {
        if (location.state) {
            if (location.state.Email) {
                // setEmail(location.state.Email);
            }
            if (location.state.Otp) {
                setOtp(location.state.Otp);
            }
        }

    }, [location])


    const handleSubmit = (e) => {
        e.preventDefault();
        setPasswordError("");
        setConfirmPasswordError("");

        var flag = true;

        if (Password === "") {
            setPasswordError("Password is required");
            flag = false;
        }

        if (ConfirmPassword === "") {
            setConfirmPasswordError("Confirm password is required");
            flag = false;
        }

        if (ConfirmPassword !== Password) {
            setConfirmPasswordError(
                "Your password and confirmation password do not match."
            );
            flag = false;
        }

        if (flag && Otp !=="") {
            setLoginLoader(true);
            axios
                .post("reset_password", {
                    new_password: Password,
                    reset_password_token: Otp
                })
                .then((response) => {
                    toast.success(response.data.status.message)
                    setConfirmPassword("")
                    setPassword("")
                    setLoginLoader(false);
                    navigate('/login')
                })
                .catch((error) => {
                    setLoginLoader(false);
                });
        }
    }

    return (
        <>
            <ToastContainer theme="colored" />
            <div
                className="bg-lock-screen"
                style={{ height: "100vh", backgroundImage: "url(" + BGImage + ")" }}
            >
                <div className="wrapper">
                    <div className="authentication-lock-screen d-flex align-items-center justify-content-center">
                        <div
                            className="card"
                            style={{
                                backgroundColor: "rgb(0,0,0,0.6)",
                                borderRadius: "1.25rem",
                            }}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className="card-body p-md-5 ">
                                    {/* <div className="text-center">
                                    <img src={ForgotImage} width="120" alt="" />
                                </div> */}
                                    <div className="text-center">
                                        <img src={ForgotImage} className="" width="120" alt="" />
                                    </div>
                                    <h4 className="text-white m-3 text-center">Reset Password
                                    </h4>
                                    {/* <p className="text-white m-0 text-center">Enter your password for reset registered email </p> */}
                                    <div className="my-0">
                                        <label className="text-white m-1 form-label ">Password</label>
                                        <input
                                            style={{
                                                backgroundColor: "transparent",
                                                color: "#ffffff",
                                                borderRadius: "1.25rem",
                                            }}
                                            type="password"
                                            className="form-control"
                                            name="username"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            value={Password}
                                        />
                                        <p className="text-danger mt-1">{PasswordError}</p>
                                    </div>
                                    <div className="my-0">
                                        <label className="text-white m-1 form-label ">Confirm Password</label>
                                        <input
                                            style={{
                                                backgroundColor: "transparent",
                                                color: "#ffffff",
                                                borderRadius: "1.25rem",
                                            }}
                                            type="password"
                                            className="form-control"
                                            name="username"
                                            onChange={(e) =>
                                                setConfirmPassword(e.target.value)
                                            }
                                            value={ConfirmPassword}
                                        />
                                        <p className="text-danger mt-1">{ConfirmPasswordError}</p>
                                    </div>

                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary btn-md"> {LoginLoader ? <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span> : null}Send</button>

                                        <Link to={"/login"} className="btn btn-light btn-md">
                                            <i className='bx bx-arrow-back me-1'></i>Back to Login
                                        </Link>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword