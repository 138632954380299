import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { token } from "./features/TokenSlice";


const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("Token");
    localStorage.removeItem("Name");
    localStorage.removeItem("Modules");
    localStorage.removeItem("Id");
    dispatch(token(""));
    navigate("/login");
  })
  return (
    <div></div>
  );
};

export default Logout;